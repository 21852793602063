import React, { Component , Fragment } from 'react';
import {
  Button,
  Container,
  Header,
  Image,
  Segment,
  // Label,
  Divider,
  List,
  Grid,
  Icon
  // Dimmer,
  // Loader
} from 'semantic-ui-react';
import { withRouter, Link } from "react-router-dom";

class HelpPage extends React.PureComponent{
  render(){

    return(
      <Container fluid>
      <Segment raised className="help-section">
      <Grid>
      <Grid.Column width={16} className="help-column">
       <Header as="h1" textAlign="center">Contact Us & Frequently Asked Questions</Header>
       <br/>
       <Header as="h2">Contact Us</Header>
       <p><a href="mailto:support@thefr.com">Give us a shout</a> anytime. We welcome your questions and feedback. </p>

         <Header as="h2">FAQs</Header>
         <Header as="h3"><Icon name="chevron right" style={{fontSize: '1rem',marginTop: '-11px'}}/>General Information</Header>
         <div className="topic-details">
         <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>Fintech and financial services is broad. What industries does Event Analyzer cover?</b>
         <div className="desc">
         <p>Event Analyzer contains data on 250+ fintech and financial services conferences within the following industries:</p>
         <List bulleted>
          <List.Item>Artificial intelligence</List.Item>
          <List.Item>Alternative data</List.Item>
          <List.Item>Banking</List.Item>
          <List.Item>Blockchain/DeFi</List.Item>
          <List.Item>Capital markets</List.Item>
          <List.Item>Cryptocurrency</List.Item>
          <List.Item>Finance/economics</List.Item>
          <List.Item>Fintech</List.Item>
          <List.Item>Insurance</List.Item>
          <List.Item>Lending</List.Item>
          <List.Item>Payments</List.Item>
          <List.Item>Real estate</List.Item>
          <List.Item>Regulation and compliance</List.Item>
          <List.Item>Technology</List.Item>
          <List.Item>Wealth management</List.Item>
          
        </List>
        </div>
        </div>

        <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>How do you decide which events go into Event Analyzer?</b>
          <div className="desc">
         <p>We review events to ensure we include the predominant industry trade shows and conferences within our industries of focus. 
        </p>
        </div>
        </div>
        
        <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>Event Analyzer is chock-full of valuable intelligence for my company. How do you collect and ensure the accuracy of data?</b>
          <div className="desc">
         <p>We leverage technology and online research within the public domain. We update this information on a frequent, and ongoing basis.<br/><br/>
            Data quality is at the forefront of our mind. Yet, despite our best efforts, there are times when we may not be able to gather every piece of relevant insight for a particular event.<br/><br/>
            Please <a href="mailto:support@thefr.com">reach out by email</a> if you have any questions about the data you're seeing, or if you would like to add a conference to our database.<br/>
        </p>
        </div>
        </div>

        <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>I understand you're committed to inputting accurate data, but I think I noticed a mistake. Will you fix it?</b>
          <div className="desc">
         <p>Yes, we absolutely want to hear from you! Please <a href="mailto:support@thefr.com">contact us by email</a> to share what you’ve noticed, and we will look into it promptly.
        </p>
        </div>
        </div>

         <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>How do you know so much about what my company — and my competitors — are doing?</b>
          <div className="desc">
         <p>It’s magic, and you should pay no attention to that man behind the curtain.
         <br/><br/>
          Nah, no secrets here. Our data is publicly sourced using technology, online research, and real people.
        </p>
        </div>
        </div>

        <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>Can I request that you remove information about my company from Event Analyzer?</b>
          <div className="desc">
         <p>Since data in our platform is publicly sourced, our policy is that we don’t remove information. <br/><br/>
         However, we’re committed to ensuring our data is accurate. <a href="mailto:support@thefr.com">Send us a note</a> if you think a correction is needed and we’ll update as appropriate
        </p>
        </div>
        </div>

        <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>Can I upload my own event information in Event Analyzer?</b>
          <div className="desc">
         <p>
         We love go-getters! But, Event Analyzer currently isn’t set up for information uploads.
<br/><br/>
That said, we’d love for you to <a href="mailto:support@thefr.com">contact us</a> so we can discuss how you think Event Analyzer could be updated or improved with your data.
        </p>
        </div>
        </div>
        </div>

        <br/>
         <Header as="h3"><Icon name="chevron right" style={{fontSize: '1rem',marginTop: '-11px'}}/>Billing</Header>
         <div className="topic-details">
         <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>Is there a cost associated with Event Analyzer?</b>
         <div className="desc">
         <p>
         The current (as of 8/1/21) plan is to provide complimentary access to the platform for the remainder of 2021, and possibly beyond. Event Analyzer and The Financial Revolutionist reserve the right to alter this plan at any time and will notify users in the event it does so.
        </p>
         
        </div>
        </div>
        
        {/* <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>How will you use my personal information, and is it secure?</b>
          <div className="desc">
         <p>Please see our <a target="_blank" href="https://thefr.com/privacy-policy">Privacy Policy</a> and <a target="_blank" href="https://thefr.com/terms">Terms of Service</a> for details.
        </p>
        </div>
        </div> */}

        </div>
        <br/>
         <Header as="h3"><Icon name="chevron right" style={{fontSize: '1rem',marginTop: '-11px'}}/>Using Event Analyzer</Header>
         <div className="topic-details">
         <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>The ability to screen for my peers and competitors sounds like valuable intelligence. How do I access this functionality?</b>
         <div className="desc">
         <p>
        The <Link to="/compare-peers">“Compare Peers”</Link> section allows you to select companies relevant to your business and view which conferences they are sponsoring or exhibiting at. You gain strategic intelligence by screening for companies relevant to your business, comparing side-by-side where your peers are investing their time and budget, and identifying where you'll have opportunities to stand out because others are absent.
        </p>
         
        </div>
        </div>

        <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>How can I save an event I’m interested in so I can quickly reference it again next time I log in?</b>
         <div className="desc">
         <p>
          Facebook has its iconic thumbs up. Twitter mainstreamed the visual for a retweet. And Event Analyzer has stars!<br/>
          Simply click the star associated with the event of interest to you, the icon turns blue. You can customize your list at <Link to="/my-events">“My Events.”</Link>
         </p>     
        </div>
        </div>

        <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>Some of my colleagues would benefit from this data. How can I download reports to share?</b>
         <div className="desc">
         <p>
          Users can export a variety of reports, such as: </p>   
          <List bulleted>
          <List.Item>Peer participation comparisons</List.Item>
          <List.Item>Upcoming event lists</List.Item>
          <List.Item>Conferences saved to "My Events"</List.Item>
          </List>  
          <p>Look for the download icon beneath the “Account” link near the top-right of the Event Analyzer interface.<br/><br/>
          Of course, another option is for you to encourage your colleagues to create their own Event Analyzer accounts. (For what it’s worth, we think sharing information about Event Analyzer is almost guaranteed to make you look super-smart among your teammates.)
          </p>
        </div>
        </div>

         <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>My competitors aren’t in Event Analyzer. What gives?</b>
         <div className="desc">
         <p>
         Looks like you’ve found some white space for your company!<br/><br/>
         Our research efforts capture data about any company that’s sponsoring or exhibiting any event covered in Event Analyzer. This means if your competitor isn’t participating in a particular conference, they won’t show up within that event. And if they aren’t participating in any of the events in our platform, you likely won’t find them in Event Analyzer at all.
         </p>
        </div>
        </div>

        </div>

             <br/>
         <Header as="h3"><Icon name="chevron right" style={{fontSize: '1rem',marginTop: '-11px'}}/>Account Maintenance</Header>
         
        <div className="topic-details">
        <div className="desc">
         <p>
         You can update your information by visiting <Link to="/account">My Profile</Link> and selecting the "Profile" tab.</p>
         
        </div>
         {/* <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>When will my credit card be charged?</b>
         <div className="desc">
         <p>
         <b>For All Access monthly users, your card will be charged automatically each month from the date of purchase, and annual plan users will be charged each year from the date of purchase.</b>
        </p>
         
        </div>
        </div>

      <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>How do I update my email address or password?</b>
         <div className="desc">
         <p>
         You can update your information by visiting <Link to="/account">My Profile</Link> and selecting the "Profile" tab.</p>
         
        </div>
        </div> */}

         <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>I need to cancel my account. How do I do it?</b>
         <div className="desc">
         <p>
          We hate goodbyes.<br/><br/> If there’s anything we can do to make Event Analyzer more useful to you, please  <a href="mailto:support@thefr.com">give us a shout</a>.<br/>
           We’d love to hear from you.</p>
          {/* <p>Otherwise, if you're sure you're ready to break our heart and cancel, either:</p>
          <List bulleted>
          <List.Item>Cancel the auto-renew on your license by visiting <Link to="/account">My Profile</Link></List.Item>
          <List.Item>Submit your request  <a href="mailto:support@thefr.com">by email</a></List.Item>
          </List>        */}
        </div>
        </div>

        </div>

            <br/>
         <Header as="h3"><Icon name="chevron right" style={{fontSize: '1rem',marginTop: '-11px'}}/>Other Questions & Feedback</Header>
         <div className="topic-details">
         <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>Do you offer partnership or advertising opportunities within Event Analyzer?</b>
         <div className="desc">
         <p>
          We’d love to discuss it with you. Please <a href="mailto:sales@thefr.com">contact us</a> to get the ball rolling.
        </p>
         
        </div>
        </div>

        <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>I have ideas for how you could improve Event Analyzer. Do you want my feedback?</b>
         <div className="desc">
         <p>
          Absolutely! We truly want to continuously improve Event Analyzer so that you derive value from our platform. Please send us your feedback <a href="mailto:support@thefr.com">by email</a>.</p>        
        </div>
        </div>

      <div className="topic-desc">
         <Icon name="caret right" style={{fontSize: '1rem'}}/><b>My question wasn’t answered here. How can I get assistance?</b>
         <div className="desc">
         <p>
          Please <a href="mailto:support@thefr.com">drop us a note</a>. We’ll get back to you as soon as possible</p>
        </div>
        </div>


        </div>

      </Grid.Column>
      </Grid>
      </Segment>
      </Container>
    )
  }
}

export default withRouter(HelpPage);