import React, { Component, Fragment } from "react";
import {
  Button,
  Container,
  Header,
  Image,
  Segment,
  Form,
  Checkbox,
  Grid,
  Divider,
  Rating,
  Icon,
  Message,
  Statistic,
  Sidebar,
  Label,
  Dropdown,
  Placeholder,
  Popup,
  Tab,
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import conf from "./../../assets/img/conf.jpg";
import ReadMoreAndLess from "react-read-more-less";
//import brandLogo from './../../assets/img/brandlogo.png';
import "./../../assets/css/main.css";
import company from "./../../assets/img/companies.png";
import meeting from "./../../assets/img/meetings.png";
import speakerimg from "./../../assets/img/speaker.png";
import FilterHeader from "./../components/FilterHeader.jsx";
import { Link } from "react-router-dom";
import axios from "axios";
import TimeMe from "timeme.js";
var DEFAULTPLAN = "Annual";

const nf = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});
const heightStyle = {
  maxHeight: "300px",
  overflow: "hidden",
  overflowY: "auto",
  marginBottom: "0px",
  padding: "10px",
};

class SingleConferencePage extends Component {
  state = {
    fetchingdata: true,
    conference: {},
    hidetext: true,
    seemoresponsors: false,
    noconference: false,
    curr_year: null,
    year_list: [],
  };
  componentDidUpdate(prevProps) {
    //console.log(this.props.data,prevProps,"D")
    //console.log(JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data),"check")
    // Typical usage (don't forget to compare props):
    if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
      this.setState({ fetchingdata: true });
      this.getConfdata(this.props.data);
    }
  }
  componentWillMount() {
    if (this.props.haveData) {
      this.setState({ conference: this.props.data.data, fetchingdata: false });
    } else {
      this.getConfdata(this.props.data);
    }
    // this.getConfdata(this.props.data)
  }

  getConfdata = (obj) => {
    if (obj) {
      this.setState({ fetchingdata: true, noconference: false });
      axios
        .get("get_conference_detail/" + obj.id)
        .then((data) => {
          this.setState({ conference: data.data, fetchingdata: false });
        })
        .catch((err) => {
          console.log(err);
        });
      this.setState({ curr_year: obj.year });
      axios
        .post("get_linked_conferences", { id: obj.id, year: obj.year })
        .then((years) => {
          this.setState({ year_list: years.data.list });
        })
        .catch((err) => {
          console.log(err);
        });
      if (obj.downloading) {
        this.setState({ seemoresponsors: true, hidetext: false });
      }
    } else {
      this.setState({ fetchingdata: false, noconference: true });
    }
  };

  changeYear = (obj) => (event) => {
    if (obj) {
      this.getConfdata({ id: obj.conference_id, year: obj.year });
    }
  };
  componentDidMount = () => {
    TimeMe.startTimer("expanded-conf");
  };

  calculatePlan = () => {
    var plan_name = [{ name: DEFAULTPLAN }];
    var user = localStorage.getItem("user_details");
    // if(user.plan=='Free'){
    //                     var resources = localStorage.getItem('resources');
    //                     resources = JSON.parse(resources);
    //                     var free_id = process.env.REACT_APP_FREE_PLAN;
    //                      plan_name = resources.filter(x => {
    //                       if(x.rid==free_id){
    //                         return x;
    //                       }
    //                     })

    //                   }
    //                   else if(user.plan=='Month'){
    //                     var resources = localStorage.getItem('resources');
    //                     resources = JSON.parse(resources);
    //                     var mtm_id = process.env.REACT_APP_MTM_PLAN;
    //                     plan_name = resources.filter(x => {
    //                       if(x.rid==mtm_id){
    //                         return x;
    //                       }
    //                     })
    //                   }
    //                   else{
    //                     var resources = localStorage.getItem('resources');
    //                     resources = JSON.parse(resources);
    //                     var ann_id = process.env.REACT_APP_ANN_PLAN;
    //                     var etp_id = process.env.REACT_APP_ETP_PLAN;
    //                     plan_name = resources.filter(x => {
    //                       if(x.rid==ann_id || x.rid==etp_id){
    //                         return x;
    //                       }
    //                     })
    //                   }
    // return plan_name;
    return DEFAULTPLAN;
  };

  handleAnalytics = () => {
    var type = "Popup - My Events";
    if (!this.props.events) {
      type = "Search-Events";
    }

    var plan_name = this.calculatePlan();

    axios
      .post("record", {
        identity: 4,
        conference_id: this.props.data.id,
        website: this.state.conference.url,
        via: type,
        plan_type: plan_name[0].name,
      })
      .then((done) => {})
      .catch((err) => {
        console.log(err, "analytics error");
      });
  };
  getPageName = () => {
    var currentUrl = this.props.location.pathname;
    switch (currentUrl) {
      case "/search-events":
        return "Search Events";
      case "/compare-peers":
        return "Compare Peers";
      case "/my-events":
        return "My Events";
      default:
        return "Unknown Page";
    }
  };
  sendAgendaData = (conference_id, link) => {
    var body = {
      conference_id,
      plan_type: this.calculatePlan()[0].name,
      identity: 17,
      via: this.getPageName(),
    };
    axios.post("record", body);
  };
  componentWillUnmount() {
    if (TimeMe.getTimeOnPageInSeconds("expanded-conf") > 0) {
      var timespent = TimeMe.getTimeOnPageInSeconds("expanded-conf");
      var plan_name = [{ name: DEFAULTPLAN }];
      // if(DEFAULTPLAN=='Free'){
      //   var resources = localStorage.getItem('resources');
      //   resources = JSON.parse(resources);
      //   var free_id = process.env.REACT_APP_FREE_PLAN;
      //    plan_name = resources.filter(x => {
      //     if(x.rid==free_id){
      //       return x;
      //     }
      //   })

      // }
      // else if(DEFAULTPLAN=='Free1'){
      //   var resources = localStorage.getItem('resources');
      //   resources = JSON.parse(resources);
      //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
      //    plan_name = resources.filter(x => {
      //     if(x.rid==free_id1){
      //       return x;
      //     }
      //   })

      // }
      // else if(DEFAULTPLAN=='Month'){
      //   var resources = localStorage.getItem('resources');
      //   resources = JSON.parse(resources);
      //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
      //   plan_name = resources.filter(x => {
      //     if(x.rid==mtm_id){
      //       return x;
      //     }
      //   })
      // }
      // else{
      //   var resources = localStorage.getItem('resources');
      //   resources = JSON.parse(resources);
      //   var ann_id = process.env.REACT_APP_ANN_PLAN;
      //   var etp_id = process.env.REACT_APP_ETP_PLAN;
      //   plan_name = resources.filter(x => {
      //     if(x.rid==ann_id || x.rid==etp_id){
      //       return x;
      //     }
      //   })
      // }
      axios
        .post("record", {
          identity: 11,
          conference_id: this.state.conference.id,
          time_spent: timespent,
          event_type: "Popup Conference Card",
          plan_type: plan_name[0].name,
        })
        .then((done) => {})
        .catch((err) => {
          console.log(err, "analytics error");
        });
      TimeMe.resetAllRecordedPageTimes();
    }
  }
  getParticipation = () => {
    if (!this.state.fetchingdata) {
      return (
        <div className="sections" style={heightStyle}>
          {!this.state.fetchingdata && (
            <Grid columns="equal">
              {Object.keys(this.state.conference.sponsors).length > 0 && (
                <Grid.Column>
                  {Object.keys(this.state.conference.sponsors).map(
                    (obj, index) => {
                      return (
                        <Fragment key={index}>
                          <Header as="h4">
                            {obj != "null" && obj != "Average" && obj != ""
                              ? obj
                              : "Sponsors"}
                            {obj.includes("sponsor") ||
                            obj.includes("Sponsor") ||
                            obj.includes("SPONSOR")
                              ? ""
                              : " Sponsors"}
                          </Header>
                          {this.state.conference.sponsors[obj].map(
                            (obj1, index) => {
                              return (
                                <Label key={index} className="company-tags">
                                  {obj1.company_name}
                                </Label>
                              );
                            }
                          )}
                        </Fragment>
                      );
                    }
                  )}
                </Grid.Column>
              )}
              {Object.keys(this.state.conference.exhibitor).length > 0 &&
                Object.keys(this.state.conference.exhibitor).map(
                  (obj, index) => {
                    return (
                      <Grid.Column key={index}>
                        <Header as="h4">
                          {obj != "null" && obj != "Average" && obj != ""
                            ? obj
                            : "Exhibitors"}
                          {obj.includes("exhibitor") ||
                          obj.includes("Exhibitor") ||
                          obj.includes("EXHIBITOR")
                            ? ""
                            : " Exhibitors"}
                        </Header>
                        {this.state.conference.exhibitor[obj].map(
                          (obj1, index) => {
                            return (
                              <Label key={index} className="company-tags">
                                {obj1.company_name}
                              </Label>
                            );
                          }
                        )}
                      </Grid.Column>
                    );
                  }
                )}
              {this.state.conference.attendee.length > 0 && (
                <Grid.Column>
                  <Header as="h4">Attendees</Header>
                  {this.state.conference.attendee.map((obj1, index) => {
                    return (
                      <Label key={index} className="company-tags">
                        {obj1.attendee}
                      </Label>
                    );
                  })}
                </Grid.Column>
              )}
            </Grid>
          )}
          {!this.state.fetchingdata &&
            Object.keys(this.state.conference.sponsors).length == 0 &&
            Object.keys(this.state.conference.exhibitor).length == 0 &&
            this.state.conference.attendee.length > 0 && (
              <Fragment>
                <Header as="h4">Attendees</Header>
                {this.state.conference.attendee.map((obj1, index) => {
                  return (
                    <Label key={index} className="company-tags">
                      {obj1.attendee}
                    </Label>
                  );
                })}
              </Fragment>
            )}
          <br />
          <br />
        </div>
      );
    } else return "";
  };
  getPricing = () => {
    if (!this.state.fetchingdata) {
      return (
        <Fragment>
          {(this.state.conference.s_pricing.length > 0 ||
            this.state.conference.e_pricing.length > 0 ||
            this.state.conference.a_pricing.length > 0) && (
            <div className="sections" style={heightStyle}>
              <Grid stackable columns="equal">
                {this.state.conference.s_pricing.length > 0 && (
                  <Grid.Column>
                    <Header as="h4">Sponsor</Header>
                    <Divider />
                    {this.state.conference.s_pricing.map((obj, index) => {
                      return (
                        <Fragment key={index}>
                          <Grid>
                            <Grid.Column width={9} floated="left">
                              {" "}
                              <span>
                                {obj.plan_type}{" "}
                                {obj.rate_type ? (
                                  <span>({obj.rate_type})</span>
                                ) : null}
                              </span>{" "}
                              {(obj.valid_till || obj.attendee_type) && (
                                <Popup
                                  trigger={
                                    <Icon name="info circle" color="blue" />
                                  }
                                  content={
                                    <span>
                                      {obj.valid_till ? (
                                        <span>
                                          <b>Valid Until</b> : {obj.valid_till}
                                        </span>
                                      ) : null}{" "}
                                      {obj.valid_till && obj.attendee_type && (
                                        <br />
                                      )}{" "}
                                      {obj.attendee_type ? (
                                        <span>
                                          <b>Sponsor Type</b>:{" "}
                                          {obj.attendee_type}
                                        </span>
                                      ) : null}
                                    </span>
                                  }
                                />
                              )}
                            </Grid.Column>
                            <Grid.Column
                              width={7}
                              textAlign="right"
                              floated="right"
                            >
                              <Header as="h4">$ {nf.format(obj.price)}</Header>
                            </Grid.Column>
                          </Grid>
                          <Divider />
                        </Fragment>
                      );
                    })}
                  </Grid.Column>
                )}
                {this.state.conference.e_pricing.length > 0 && (
                  <Grid.Column>
                    <Header as="h4">Exhibit</Header>
                    <Divider />
                    {this.state.conference.e_pricing.map((obj, index) => {
                      return (
                        <Fragment key={index}>
                          <Grid>
                            <Grid.Column width={9} floated="left">
                              {" "}
                              <span>
                                {obj.plan_type}{" "}
                                {obj.rate_type ? (
                                  <span>({obj.rate_type})</span>
                                ) : null}
                              </span>{" "}
                              {(obj.valid_till || obj.attendee_type) && (
                                <Popup
                                  trigger={
                                    <Icon name="info circle" color="blue" />
                                  }
                                  content={
                                    <span>
                                      {obj.valid_till ? (
                                        <span>
                                          <b>Valid Until</b> : {obj.valid_till}
                                        </span>
                                      ) : null}{" "}
                                      {obj.valid_till && obj.attendee_type && (
                                        <br />
                                      )}{" "}
                                      {obj.attendee_type ? (
                                        <span>
                                          <b>Exhibitor Type</b>:{" "}
                                          {obj.attendee_type}
                                        </span>
                                      ) : null}
                                    </span>
                                  }
                                />
                              )}
                            </Grid.Column>
                            <Grid.Column
                              width={7}
                              textAlign="right"
                              floated="right"
                            >
                              <Header as="h4">$ {nf.format(obj.price)}</Header>
                            </Grid.Column>
                          </Grid>
                          <Divider />
                        </Fragment>
                      );
                    })}
                  </Grid.Column>
                )}
                {this.state.conference.a_pricing.length > 0 && (
                  <Grid.Column>
                    <Header as="h4">Attend</Header>
                    <Divider />
                    {this.state.conference.a_pricing.map((obj, index) => {
                      return (
                        <Fragment key={index}>
                          <Grid>
                            <Grid.Column width={9} floated="left">
                              {" "}
                              <span>
                                {obj.plan_type}{" "}
                                {obj.rate_type ? (
                                  <span>({obj.rate_type})</span>
                                ) : null}
                              </span>{" "}
                              {(obj.valid_till || obj.attendee_type) && (
                                <Popup
                                  trigger={
                                    <Icon name="info circle" color="blue" />
                                  }
                                  content={
                                    <span>
                                      {obj.valid_till ? (
                                        <span>
                                          <b>Valid Until</b> : {obj.valid_till}
                                        </span>
                                      ) : null}{" "}
                                      {obj.valid_till && obj.attendee_type && (
                                        <br />
                                      )}{" "}
                                      {obj.attendee_type ? (
                                        <span>
                                          <b>Attendee Type</b>:{" "}
                                          {obj.attendee_type}
                                        </span>
                                      ) : null}
                                    </span>
                                  }
                                />
                              )}
                            </Grid.Column>
                            <Grid.Column
                              width={7}
                              textAlign="right"
                              floated="right"
                            >
                              <Header as="h4">$ {nf.format(obj.price)} </Header>
                            </Grid.Column>
                          </Grid>
                          <Divider />
                        </Fragment>
                      );
                    })}
                  </Grid.Column>
                )}
              </Grid>
            </div>
          )}
        </Fragment>
      );
    } else return "";
  };
  getSpeaker = () => {
    return (
      <div className="sections" style={heightStyle}>
        {this.state.conference.speakers.map((s) => (
          <div
            style={{
              padding: ".5833em 0",
              color: "rgba(0,0,0,.6)",
              fontSize: "14px",
              fontWeight: "700",
            }}
          >
            {s.speaker ? s.speaker + ", " : null}
            {s.title ? s.title + ", " : null}
            {s.company ? s.company : null}
          </div>
        ))}
      </div>
    );
  };
  getTabOptions = () => {
    return [
      {
        menuItem: "PARTICIPATION",
        render: () => (
          <Tab.Pane attached={false}>{this.getParticipation()}</Tab.Pane>
        ),
      },
      {
        menuItem: "PRICING",
        render: () => <Tab.Pane attached={false}>{this.getPricing()}</Tab.Pane>,
      },
      //   Spekers removed 9th August 2021
      //   {
      //     menuItem: 'SPEAKERS',
      //     render: () => <Tab.Pane attached={false}>{this.getSpeaker()}</Tab.Pane>,
      //   }
    ];
  };

  render() {
    if (this.props.data.starred != undefined) {
      this.state.conference.starred = this.props.data.starred;
    }
    var imageWithTimestamp = null;
    const arr = Array.from({ length: 10 }, () => Math.floor(Math.random() * 9));
    const arr1 = Array.from({ length: 3 }, () => Math.floor(Math.random() * 9));
    if (!this.state.fetchingdata) {
    }

    if (!this.state.fetchingdata && !this.state.noconference) {
      const image = this.state.conference.s3logo;
      const timestamp = new Date().getTime();
      imageWithTimestamp = image.includes("?")
        ? `${image}&v=${timestamp}`
        : `${image}?v=${timestamp}`;
    }

    return (
      <Fragment>
        {/*	{!this.state.noconference && !this.props.events &&  <Dropdown style={{float:'right'}} trigger={<Header color="blue" as="a" size="tiny" floated="right"><Icon name="angle left"/>{this.state.curr_year}</Header>}>
				<Dropdown.Menu direction="left">
				{!this.state.fetchingdata && this.state.year_list.map((obj,index) => {
					return(
						  <Dropdown.Item key={index} onClick={this.changeYear(obj)}> {obj.year}</Dropdown.Item>   
					)
				})}             
               </Dropdown.Menu>
                </Dropdown>}*/}
        <br />

        <Segment basic={this.props.events}>
          <div id="print-pdf">
            {!this.state.noconference && (
              <div>
                {this.state.fetchingdata ? (
                  <Placeholder>
                    <Placeholder.Line length="long" />
                  </Placeholder>
                ) : (
                  <Fragment>
                    {this.state.conference.industries
                      ? this.state.conference.industries.map((obj, index) => {
                          return (
                            <p
                              style={{
                                display: "inline-block",
                                fontWeight: obj.primary_ind
                                  ? "bolder"
                                  : "normal",
                              }}
                              key={index}
                            >
                              {obj.industry}
                              {this.state.conference.industries.length - 1 !=
                                index && <span>,&nbsp;</span>}
                            </p>
                          );
                        })
                      : null}
                  </Fragment>
                )}
                <Header
                  data-html2canvas-ignore="true"
                  color="blue"
                  size="tiny"
                  as="a"
                  onClick={this.handleAnalytics}
                  href={this.state.conference.url}
                  target="_blank"
                  floated="right"
                >
                  {/* <Icon  size="mini" name="external alternate"/> */}
                  <Button primary>Event Website</Button>
                </Header>
                <br />
                <br />
                <Grid stackable>
                  <Grid.Column width={10}>
                    <Header as="h1" style={{ fontSize: "2.7em" }}>
                      {this.state.fetchingdata ? (
                        <Placeholder>
                          <Placeholder.Line />
                        </Placeholder>
                      ) : (
                        <Fragment>{this.state.conference.name}</Fragment>
                      )}
                    </Header>
                    <Header as="h3" style={{ marginTop: "0px" }}>
                      {this.state.fetchingdata ? (
                        <Placeholder>
                          {" "}
                          <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                          </Placeholder.Paragraph>
                        </Placeholder>
                      ) : (
                        <Fragment>{this.state.conference.tagline}</Fragment>
                      )}
                    </Header>

                    {this.state.fetchingdata ? (
                      <Placeholder>
                        <Placeholder.Line />
                      </Placeholder>
                    ) : (
                      <Fragment>
                        <div
                          className={
                            this.state.hidetext ? "hidden-text" : "show-text"
                          }
                        >
                          {this.state.conference.about}
                        </div>{" "}
                        {this.state.conference.about && (
                          <a
                            data-html2canvas-ignore="true"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.setState({ hidetext: !this.state.hidetext })
                            }
                          >
                            {this.state.hidetext ? "Read More" : "Read Less"}
                          </a>
                        )}
                      </Fragment>
                    )}
                  </Grid.Column>
                  <Grid.Column width={6} textAlign="center">
                    {this.state.fetchingdata ? (
                      <Placeholder>
                        <Placeholder.Image rectangular />
                      </Placeholder>
                    ) : (
                      <Image
                        crossOrigin="anonymous"
                        className={
                          this.state.conference.logo_white ? "white-logo" : ""
                        }
                        centered
                        src={imageWithTimestamp}
                        size="small"
                        style={{ maxHeight: "200px", width: "auto" }}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column width={9} data-html2canvas-ignore="true">
                    {" "}
                    <Grid.Column>
                      <Header as="h3">
                        <Image src={company} />
                        <Header.Content>
                          {this.state.fetchingdata ? (
                            <Placeholder>
                              <Placeholder.Line />
                            </Placeholder>
                          ) : (
                            <Fragment>
                              {this.state.conference.companies} Companies{" "}
                              {this.state.conference.peers ? (
                                <span>
                                  | {this.state.conference.peers}{" "}
                                  {this.state.conference.peers == 1
                                    ? "Peer"
                                    : "Peers"}
                                </span>
                              ) : null}
                            </Fragment>
                          )}
                          <Header.Subheader>To Date</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    {/*<Grid.Column><Header as="h3">
			<Image src={meeting} />
			<Header.Content>{this.state.fetchingdata?(<Placeholder><Placeholder.Line/></Placeholder>):(<Fragment>{this.state.conference.att_count} Attendees</Fragment>)}
			<Header.Subheader>Estimate</Header.Subheader>
			</Header.Content>
			</Header></Grid.Column>*/}
                  </Grid.Column>
                  <Grid.Column width={7} floated="right">
                    <div className="location">
                      {this.state.conference.starred}
                      {this.state.fetchingdata ? (
                        <Placeholder>
                          <Placeholder.Line />
                        </Placeholder>
                      ) : (
                        <Header
                          as="h5"
                          floated="right"
                          style={{ marginTop: "-6px", fontSize: "0.9rem" }}
                        >
                          {this.state.conference.date},{" "}
                          {this.state.conference.city}
                        </Header>
                      )}
                      <Divider />
                      {this.state.fetchingdata ? (
                        <Placeholder>
                          <Placeholder.Line />
                        </Placeholder>
                      ) : (
                        <Header
                          as="h5"
                          floated="right"
                          style={{
                            marginTop: "-10px",
                            fontSize: "0.9rem",
                            marginLeft: "0px",
                          }}
                        >
                          {this.state.conference.venue}
                        </Header>
                      )}
                    </div>
                  </Grid.Column>
                </Grid>
                <br />
                <style>{`
					.custom-tab .ui.pointing.secondary.menu {
					    border: 1px solid rgba(34,36,38,.15);
					    background: #eaf2ff !important;
					}

					.custom-tab .ui.pointing.secondary.menu a {
					    color: #2d4a7d !important;
					    font-weight: bold;
					    font-size: 15px !important;
					}

					.custom-tab .ui.pointing.secondary.menu a.active {
					    position: relative;
					    top: 2px;
					    left:-1px;
					    border-bottom: 3px solid #77a8ff;
					    padding: 15px 22.5px;
					}
					.custom-tab .ui.secondary.pointing.menu .active.item:hover{
						border-color: #77a8ff !important;
					}
					.custom-tab >.ui.segment {
					    border: none;
					    box-shadow: none;
					}
					.custom-tab .company-tags {
					    background-color: transparent !important;
					}
					.custom-tab .ui.segment.active.tab {
					    padding-right: 0;
					}
					.ui.label {
					    padding-left: 0 !important;
					    padding-right: 1.8rem !important;
					    margin: 0 !important;
					}

				`}</style>
                <Tab
                  menu={{ secondary: true, pointing: true }}
                  panes={this.getTabOptions()}
                  className="custom-tab"
                />
                {!this.state.fetchingdata &&
                  this.state.conference.companies != 0 && (
                    <Fragment>
                      {(this.state.conference.agenda &&
                        this.state.conference.agenda.length > 0 &&
                        !this.state.conference.agenda_link && (
                          <div data-html2canvas-ignore="true">
                            <Message className="header-panel" visible>
                              AGENDA
                            </Message>
                            <br />
                            {this.state.conference.agenda.map((obj, index) => {
                              return (
                                <Fragment key={index}>
                                  <Grid columns="equal">
                                    <Grid.Column>
                                      {obj.date} • {obj.time}
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                      {obj.track}
                                    </Grid.Column>
                                  </Grid>
                                  <Header as="h3">
                                    {obj.topic}
                                    <Header.Subheader>
                                      {obj.description}
                                    </Header.Subheader>
                                  </Header>
                                  {obj.roles &&
                                    Object.keys(obj.roles).map(
                                      (role, index) => {
                                        return (
                                          <Fragment key={index}>
                                            <Header
                                              as="h4"
                                              style={{
                                                marginTop: "0px",
                                                color: "#585858",
                                              }}
                                            >
                                              {role} :
                                            </Header>
                                            <Grid stackable columns={2}>
                                              {obj.roles[role].map(
                                                (speaker, index) => {
                                                  return (
                                                    <Grid.Column key={index}>
                                                      <Grid>
                                                        <Grid.Column
                                                          width={4}
                                                          textAlign="right"
                                                        >
                                                          <Image
                                                            floated="right"
                                                            size="small"
                                                            src={speakerimg}
                                                            style={{
                                                              width: "50px",
                                                            }}
                                                          />
                                                        </Grid.Column>
                                                        <Grid.Column
                                                          width={12}
                                                          verticalAlign="middle"
                                                        >
                                                          <Header
                                                            as="h3"
                                                            style={{
                                                              fontWeight:
                                                                "normal",
                                                            }}
                                                          >
                                                            <Header.Content>
                                                              {speaker.Name}
                                                              <Header.Subheader>
                                                                {speaker.Title}
                                                                {speaker.Title && (
                                                                  <span>,</span>
                                                                )}{" "}
                                                                {
                                                                  speaker.Company
                                                                }
                                                              </Header.Subheader>
                                                            </Header.Content>
                                                          </Header>
                                                        </Grid.Column>
                                                      </Grid>
                                                    </Grid.Column>
                                                  );
                                                }
                                              )}
                                            </Grid>
                                            <br />
                                          </Fragment>
                                        );
                                      }
                                    )}

                                  <br />
                                  <Divider />
                                </Fragment>
                              );
                            })}
                          </div>
                        )) || (
                        <React.Fragment>
                          {this.state.conference.agenda_link && (
                            <React.Fragment>
                              {" "}
                              <Message className="header-panel" visible>
                                AGENDA
                              </Message>
                              <br />
                              <a
                                onClick={this.sendAgendaData.bind(
                                  this,
                                  this.state.conference.id,
                                  this.state.conference.agenda_link
                                )}
                                target="_blank"
                                style={{
                                  paddingLeft: "5px",
                                  fontSize: "1.3rem",
                                }}
                                href={this.state.conference.agenda_link}
                              >
                                {this.state.conference.agenda_link}
                              </a>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </Fragment>
                  )}
                {!this.state.fetchingdata &&
                  this.state.conference.companies == 0 && (
                    <Grid>
                      <Grid.Column
                        textAlign="center"
                        verticalAlign="middle"
                        className="no-data"
                      >
                        <br />
                        CONFERENCE DETAILS{" "}
                        {new Date(this.state.conference.start_date) > new Date()
                          ? "COMING SOON"
                          : "NOT AVAILABLE"}
                        <br />
                        <br />
                      </Grid.Column>
                    </Grid>
                  )}
              </div>
            )}
            {this.state.noconference && (
              <Grid>
                <Grid.Column
                  textAlign="center"
                  verticalAlign="middle"
                  className="no-data"
                >
                  NO RESULTS
                </Grid.Column>
              </Grid>
            )}
          </div>
        </Segment>
      </Fragment>
    );
  }
}

export default withRouter(SingleConferencePage);
