import React, { Component , Fragment } from 'react';
import {
  Button,
  Container,
  Header,
  Image,
  Segment,
  // Label,
  Divider,
  // List,
  Grid,
  Modal,
  Icon,
  Checkbox,
  Placeholder,
  Popup,
  Message
  // Dimmer,
  // Loader
} from 'semantic-ui-react';
import { withRouter, Link } from "react-router-dom";
import conf from './../../assets/img/conf.jpg';
import companies from './../../assets/img/companies.png';
import meetings from './../../assets/img/meetings.png';
import ad from './../../assets/img/ad.png';
import './../../assets/css/main.css';
import axios from 'axios';


class Dashboard extends Component{
	state={
		firstLogin:false,
		industries:[],
		updates:[0,1,2,3],
		selected_ind:[],
		conferences:[0,1,2],
		welcomescreen:false, 
		user : {},
		fetchingupdates:true,
		fetchingevents:true,
		maxind:1,
		display_ind:[]
	};

	componentDidMount(){
		if(localStorage.user_details){
			var user = JSON.parse(localStorage.getItem('user_details'));
			if(user.plan=='Free'){
				this.setState({maxind:process.env.REACT_APP_FREE_IND})
			}
			else if(user.plan=='Month'){
				this.setState({maxind:process.env.REACT_APP_MTM_IND})
			}
			else if(user.plan=='Annual'){
				this.setState({maxind:process.env.REACT_APP_ANN_IND})
			}
		}
			
			
			//console.log(process.env,user.access)
		
		  this.setState({user:JSON.parse(localStorage.getItem('user_details'))})
	      this.firstUser(JSON.parse(localStorage.getItem('user_details')))
		
		axios.get('fetch_industries').then(ind =>{
			if(ind.data.length>0){
				ind.data.map(obj => {
					obj.selected= false;
				})
				this.setState({industries:ind.data})
			}
		}).catch(err => {
			console.log(err);
		})

	}
	
	fetchEvents = () => {
		axios.get('user_upcoming_conferences').then(data => {
			// data.data.map(obj => {
			// 	  if(obj.s3logo && obj.s3logo!=''){
			//           var lastindex=obj.s3logo.lastIndexOf('.');
			//           if(obj.s3logo.substr(lastindex)!='.svg' && obj.s3logo.substr(lastindex)!='.webp'){
			//             console.log(obj.s3logo.substr(0,lastindex)+'_resized'+obj.s3logo.substr(lastindex))
			//             obj.s3logo=obj.s3logo.substr(0,lastindex)+'_resized'+obj.s3logo.substr(lastindex);
			//           }
			//         }
			// })
			this.setState({conferences:data.data,fetchingevents:false})
		}).catch(err => {
			console.log(err);
		})
	}

	fetchupdates = () => {
		axios.get('fetch_updates').then(data => {
			this.setState({updates:data.data,fetchingupdates:false})
		}).catch(err => {
			console.log(err);
		})
	}

	handleCheck=(obj) => (event,value) =>{
		//console.log(obj, event.target.checked,value,"e")
		this.setState({errors:false,errormsg:''})
		Array.prototype.remove = function() {
			    var what, a = arguments, L = a.length, ax;
			    while (L && this.length) {
			        what = a[--L];
			        while ((ax = this.indexOf(what)) !== -1) {
			            this.splice(ax, 1);
			        }
			    }
			    return this;
		};

		// console.log("VALUE",value);
		var selected_ind = [...this.state.selected_ind];
		//obj.selected = value.checked;
		if(selected_ind.length< this.state.maxind){
			//console.log('less 3',obj.selected)
			if(!obj.selected){
			selected_ind.push(value.id);
		}
		else{
			var index = selected_ind.indexOf(value.id);
			selected_ind.splice(index,1);
		}
		}
		
		else if(selected_ind.length==this.state.maxind){
			//console.log('here');

			if(!obj.selected){
				if(this.state.user.plan == 'Free'){
					var msg  = 'Error! Please select one industry to continue.'
				}
				else if(this.state.user.plan=='Month'){
					var msg  = 'Error! Please select up to three industries to continue.'
				}
				else{
					var msg  = 'Error! Please select up to five industries to continue.'
				}
				this.setState({errors:true,errormsg:msg})
				obj.selected=false
			}
			else{
				selected_ind.remove(value.id)
			}
			//console.log(selected_ind,'s');

		}
		if(selected_ind.length>this.state.maxind){
			if(this.state.user.plan == 'Free'){
					var msg  = 'Error! Please select one industry to continue.'
				}
				else if(this.state.user.plan=='Month'){
					var msg  = 'Error! Please select up to three industries to continue.'
				}
				else{
					var msg  = 'Error! Please select up to five industries to continue.'
				}
			this.setState({errors:true,errormsg:msg})
		}
		this.setState({selected_ind:selected_ind});
		var industries= [...this.state.industries];
		industries.map(ind => {
			ind.selected=false;
			selected_ind.map(obj => {
				if(ind.id == obj){
					ind.selected=true;
				}
				
			})
		})
		if(selected_ind.length==0){
			industries.map(ind => {
				
					ind.selected=false;
				
			})
		}
		var selected = industries.filter(x => x.selected==true);
		this.setState({display_ind:selected});
		this.setState({industries:industries})

		//selected_ind.push(value.value);
	};

	// Split in group of 5 items
	chunkArray=(myArray, chunk_size) =>{
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    
    for (index = 0; index < arrayLength; index += chunk_size) {
         var myChunk = myArray.slice(index, index+chunk_size);
        // Do something if you want with the group
        tempArray.push(myChunk);
    }

    	return tempArray;
	};
	// Split in group of 5 item

	// checkSession = () => {
	// 	// console.log(window.tp.pianoId.getUser())
	// 	// if(window.tp.pianoId.isUserValid()){
	// 	// 	var user = window.tp.pianoId.getUser
	// 	// 	axios.post('login',{email:})
	// 	// }
	// 	// else{
	// 	//    localStorage.removeItem('user_details')
 //  //          this.props.history.push('/')
	// 	// }
	// axios.get('session_check').then(userdata => {
	// 	//userdata.data.access = 
 //        userdata.data.access = userdata.data.plan_type;
 //        localStorage.setItem('user_details',JSON.stringify(userdata.data))

 //        if(localStorage.user_details){
 //      	  //console.log(localStorage.user_details,'uu')
	//       var user = localStorage.user_details;
	//       this.setState({user:user})
	//       this.firstUser(JSON.parse(user))
	//       //console.log(user,"u")
	//     }
	//     else{
	//       this.props.history.push('/')
	//     }
 //      }).catch(err => {
 //        if(err.response){
 //          localStorage.removeItem('user_details')
 //           this.props.history.push('/')
 //        }
 //        console.log(err);
 //      })
	// }
	
	firstUser = (user) => {
		if(!this.state.firstLogin){
			if(!user.flag){
			this.setState({firstLogin:true})
			setTimeout(() => {
				if(this.state.user.plan == 'Free'){
					var msg  = 'Oops! Please select one industry to continue'
				}
				else if(this.state.user.plan=='Month'){
					var msg  = 'Oops! Please select up to three industries to continue'
				}
				else{
					var msg  = 'Oops! Please select up to five industries to continue'
				}
				this.setState({errors:true,errormsg:msg})
			},30000)
		}
		
			this.fetchEvents();
			this.fetchupdates();
		
		}
		
	}

	saveIndustries = () => {
		if(this.state.selected_ind.length==0){
			if(this.state.user.plan == 'Free'){
					var msg  = 'Error! Please select one industry to continue.'
				}
				else if(this.state.user.plan=='Month'){
					var msg  = 'Error! Please select up to three industries to continue.'
				}
				else{
					var msg  = 'Error! Please select up to five industries to continue.'
				}
			this.setState({errors:true,errormsg:msg})

		}
		else{
			axios.post('user_industries',{industries:JSON.stringify(this.state.selected_ind)}).then(done =>{
			var user = this.state.user;
			
			user.flag=true;
			this.setState({firstLogin:false,welcomescreen:true,user:user});
			this.fetchEvents();
			this.fetchupdates();
			}).catch(err =>{
				console.log(err);
			})
			  var plan_name;
                    if(this.state.user.plan=='Free'){
                      var resources = localStorage.getItem('resources');
                      resources = JSON.parse(resources);
                      var free_id = process.env.REACT_APP_FREE_PLAN;
                       plan_name = resources.filter(x => {
                        if(x.rid==free_id){
                          return x;
                        }
                      })

                    }
                    else if(this.state.user.plan=='Month'){
                      var resources = localStorage.getItem('resources');
                      resources = JSON.parse(resources);
                      var mtm_id = process.env.REACT_APP_MTM_PLAN;
                      plan_name = resources.filter(x => {
                        if(x.rid==mtm_id){
                          return x;
                        }
                      })
                    }
                    else{
                      var resources = localStorage.getItem('resources');
                      resources = JSON.parse(resources);
                      var ann_id = process.env.REACT_APP_ANN_PLAN;
                      var etp_id = process.env.REACT_APP_ETP_PLAN;
                      plan_name = resources.filter(x => {
                        if(x.rid==ann_id || x.rid==etp_id){
                          return x;
                        }
                      })
                    }
			axios.post('record',{identity:1,industry_id:this.state.selected_ind,via:'On-boarding Popup',plan_type:plan_name[0].name}).then(done => {

			}).catch(err => {
				console.log(err,"analytics error")
			})
		}
		//console.log(this.state.selected_ind,"ind");
		
	}

	closeWelcome = () => {
		this.setState({welcomescreen:false,fetchingevents:true})
		this.fetchEvents();
		this.fetchupdates();
	}

	render(){
		if(this.state.user.plan==="Free1") {
      this.props.history.push('/search-events');
    }
		const arr = Array.from({length: 3}, () => Math.floor(Math.random() * 2));
		//const user = {}
		//let user = {};
		let user = this.state.user;
		const updateArr= Array.from({length: 4}, () => Math.floor(Math.random() * 3));
		//const industries= ['Alternative Data','Artificial Intelligence','Banking','Blockchain','Capital Markets','Cryptocurrency','Finance/Economics','Fintech','Insurance','Lending','Payments','Real Estate','Regulation and Compiance','Technology','Wealth Management'];
		//var result = this.chunkArray(this.state.industries, 5);
		//console.log(this.props.user_details!={},"u_");
		if(typeof(this.state.user)=='string'){
			 user = JSON.parse(this.state.user)
		}
	
		
		
		return(
			<Container>
					{/*<Dimmer.Dimmable as={Container} dimmed={this.state.searchLoader}>
          				<Dimmer active={this.state.searchLoader} inverted>
          				  <Loader>Loading</Loader>
          				</Dimmer>*/}
					<div>
						<br/>
						
						<a href="https://clixtrac.com/banner/click.php?banner=286255" target="_blank">
						<Image src="https://clixtrac.com/banner/286255.jpg" alt="" title="" border="0" centered /></a>
						
						<Divider hidden/>
						<Header as='h3'>Updates
						<Header.Subheader>
						<span><br/>The Updates section displays what’s recently been updated in the Event Analyzer database according to your industry selection, such as recently added sponsors or exhibitors, as well as recently researched conferences. Click the hyperlinks or go to <Link to="/search-events">Search Events</Link> to start your search
						</span></Header.Subheader>
						</Header>
						<Segment className="updates-segment">
							{/*<List>
							{updateArr.map((obj,index)=>{
								return(
								<List.Item className='update-list'>
									<List.Content floated='right'>Date</List.Content>
									<List.Content>Update</List.Content>
									<Divider/>
								</List.Item>
								)
							})}
								
								
							</List>*/}
							<Grid divided='vertically'>
								{this.state.updates.length>0 && this.state.updates.map((obj,index) =>{
									return(
									<Grid.Row column={2} className="update-list" key={index}>
										<Grid.Column width={13}>{this.state.fetchingupdates?(<Placeholder fluid> <Placeholder.Line length="very long"  />  </Placeholder>):(<Fragment>{obj.summary} {obj.industry?(<a href={"/search-events?filter="+obj.industry}>{'Search '+obj.industry}</a>):(<Link to="/compare-peers">Find your peers</Link>)}</Fragment>)}</Grid.Column>
										<Grid.Column width={3} className="update-date"  textAlign='center'>{this.state.fetchingupdates?(<Placeholder fluid > <Placeholder.Line  />  </Placeholder>):(<Fragment>{obj.date}</Fragment>)}</Grid.Column>
									</Grid.Row>
									)
								})}
								{
									this.state.updates.length==0 && <Grid.Column verticalAlign="middle" textAlign="center" className="no-data">NO UPDATES</Grid.Column>
								}
							</Grid>
						</Segment>
					</div>
						<Divider hidden/><Divider hidden/><Divider hidden/>
					<div>
						{/*<Button floated='right'>Compare Peers</Button><Button floated='right' as={Link} to="/search-events">Search Events</Button>*/}
						<Header as='h3'>Upcoming Events
						<Header.Subheader><br/>View a preview of upcoming events according to your industry selection. Each event displays the date, city, venue and the total number of companies participating. Company participation is defined as sponsor, exhibitor, attendee and speaker. Go to <Link to="/search-events">Search Events</Link> to view more detail on each event.</Header.Subheader>
						</Header>
							 <Grid stackable columns='3'>
							 	{this.state.conferences.map((obj,index) => {
							 		return(
      								<Grid.Column key={index}>
      									<Segment className="conferences">
      									{this.state.fetchingevents?(<Placeholder style={{float:'right',width:'80px'}}><Placeholder.Image rectangular/></Placeholder>):(<Image className={obj.logo_white?'white-logo':''}   src={obj.s3logo} size='tiny' floated='right'/>)}<br/><br/>
      									<Header as='h2' style={{minHeight: '90px',fontSize: '22px'}}>{this.state.fetchingevents?(<Placeholder><Placeholder.Line/></Placeholder>):(<span>{obj.name}</span>)}</Header><br/>
      										<Segment textAlign='center' className="venue-segment">
      										<Header as='h5'>{this.state.fetchingevents?(<Placeholder><Placeholder.Line style={{backgroundColor: '#eaf2fe'}}/></Placeholder>):(<Fragment>{obj.date}, {obj.city}</Fragment>)}</Header><Divider/>
      										<Header as='h5'>{this.state.fetchingevents?(<Placeholder><Placeholder.Line style={{backgroundColor: '#eaf2fe'}}/></Placeholder>):(<Fragment>{obj.venue}</Fragment>)}</Header></Segment>
      										<br/><br/>
      										{/*<Grid container>
      										<Grid.Row><Header as="h3">
															<Image src={meetings} size='small'/>
															<Header.Content>{this.state.fetchingevents?(<Placeholder><Placeholder.Line/></Placeholder>):(<Fragment> {obj.est_att} Attendees</Fragment>)}
															<Header.Subheader>Estimated</Header.Subheader>
															</Header.Content>
															</Header></Grid.Row>
      										</Grid>*/}
      										{/*<br/><br/>*/}
      										<Grid container>
      										<Grid.Row><Header as="h3">
															<Image src={companies} size='small'/>
															<Header.Content>{this.state.fetchingevents?(<Placeholder><Placeholder.Line/></Placeholder>):(<Fragment>{obj.att_count+obj.spo_count+obj.exh_count+obj.spe_count} Companides</Fragment>)}
															<Header.Subheader>To Date</Header.Subheader>
															</Header.Content>
															</Header></Grid.Row>
      										</Grid>
      										<br/><br/>
      										<Grid container className="participation" columns='equal'>
      											<Grid.Row>
      												<Grid.Column className='part-value' textAlign='left'>{this.state.fetchingevents?(<Placeholder><Placeholder.Line/></Placeholder>):(<Fragment>{obj.spo_count || 0}</Fragment>)}</Grid.Column>
      												<Grid.Column textAlign='right'>Sponsors</Grid.Column>
      											</Grid.Row><Divider/>
      											<Grid.Row>
      												<Grid.Column className='part-value' textAlign='left'>{this.state.fetchingevents?(<Placeholder><Placeholder.Line/></Placeholder>):(<Fragment>{obj.exh_count || 0}</Fragment>)}</Grid.Column>
      												<Grid.Column textAlign='right'>Exhibitors</Grid.Column>
      											</Grid.Row><Divider/>
      											{/*<Grid.Row>
      												<Grid.Column className='part-value' textAlign='left'>{this.state.fetchingevents?(<Placeholder><Placeholder.Line/></Placeholder>):(<Fragment>{obj.est_att || 0}</Fragment>)}</Grid.Column>
      												<Grid.Column textAlign='right'>Attendees</Grid.Column>
      											</Grid.Row><Divider/>*/}
      											<Grid.Row>
      												<Grid.Column className='part-value' textAlign='left'>{this.state.fetchingevents?(<Placeholder><Placeholder.Line/></Placeholder>):(<Fragment>{obj.spe_count || 0}</Fragment>)}</Grid.Column>
												  	{/* Spekers removed 9th August 2021 */}
													{/* <Grid.Column textAlign='right'>Speakers</Grid.Column> */}
      											</Grid.Row><Divider/>
      										</Grid>
      									{/*<Header as='h5' textAlign='justified'>47 Sponsors</Header><Divider clearing/>
      									<Header as='h5' textAlign='justified'>97 Exhibitors</Header><Divider fitted/>
      									<Header as='h5' textAlign='justified'>122 Attendees</Header><Divider fitted/>
      									<Header as='h5' textAlign='justified'>32 Speakers</Header><Divider fitted/>*/}
      									{/*<Grid centered container>
      									      										<Grid.Row style={{padding:'0px'}}><Header as='h4' textAlign='justified'>47 Sponsors</Header></Grid.Row>
      									      										<Divider fitted/>
      									      										<Grid.Row style={{padding:'0px'}}><Header as='h4'>97</Header><span>Exhibitors</span></Grid.Row>
      									      										<Divider fitted/>
      									      										<Grid.Row style={{padding:'0px'}}><Header as='h4'>122</Header><span>Attendees</span></Grid.Row>
      									      										<Divider fitted/>
      									      										<Grid.Row style={{padding:'0px'}}><Header as='h4'>32</Header><span>Speakers</span></Grid.Row>
      									      									</Grid>*/}
      										 </Segment>
      								</Grid.Column>
      								)
      							})}
      						</Grid>
					</div>
					{/*</Dimmer.Dimmable>*/}
					
					<Modal open={this.state.welcomescreen} className="welcome-screen" size="small">
						<Icon name="close" onClick={this.closeWelcome} floated="right"/>
						<Modal.Content>
							<Header as="h1">You're all set!</Header>
							<p style={{textAlign:'justify'}}>To get started, use the top navigation to:<br/><br/>

							<b>Search Events</b> -- Search and evaluate conferences of interest<br/>

							{this.state.user.plan!='Free' && <Fragment><b>Compare Peers </b> -- See what companies are participating in events<br/></Fragment>}

							<b>My Events</b> -- Quickly access events you’ve saved<br/>

							{this.state.user.plan!='Free' && <Fragment><b>Dashboard</b> -- Click the logo to return to your Updates<br/></Fragment>}

							{this.state.user.plan!='Free' && <Fragment><b>Help</b> -- View the FAQ & contact us<br/></Fragment>}

							<b>Account</b> -- Upgrade your {this.state.user.plan=='Free'?'license':'plan'}</p>
							
							<Divider hidden/>
							<br/>
							<Button color='blue' onClick={this.closeWelcome} size='large'>
    					    GOT IT
    					  </Button>
						</Modal.Content>
						{/*<Modal.Actions >
						    					  <Button color='blue' onClick={this.Welcome}>
						    					    Done
						    					  </Button>
						    					</Modal.Actions>*/}
					</Modal>
			</Container>
		)
	}
}
export default withRouter(Dashboard);