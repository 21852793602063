import React, { Component, Fragment } from 'react';
import {
  Button,
  Container,
  Header,
  Image,
  Segment,
  Form,
  Checkbox,
  Grid,
  Divider,
  Rating,
  Icon,
  Message,
  Statistic, 
  Sidebar,
  Label,
  Dropdown,
  Placeholder,
  Dimmer,
  Loader,
  Table,
  Modal,
  Accordion
} from 'semantic-ui-react';
import { withRouter, Link } from "react-router-dom";
import SingleConferencePage from './SingleConferencePage.jsx'
import conf from './../../assets/img/conf.jpg'
import ReadMoreAndLess from 'react-read-more-less';
//import brandLogo from './../../assets/img/brandlogo.png';
import axios from 'axios'
import speakerimg from './../../assets/img/speaker.png';
import './../../assets/css/main.css';
 import html2canvas from 'html2canvas';
import PeerHeader from './../components/PeerHeader.jsx';
import TimeMe from  'timeme.js';
var backup_conf;
var TimeOutSave;
var TimeOutRecord;
var common;
var timeDuration = 5000;
var currentTab = "particpation";
var DEFAULTPLAN='Annual';

class ComparePeers extends Component{
  year=new Date().getFullYear()
 state = {activeCol:"",undo:false,compare:false,peers:[],list:[],total:{},showparticipation:true,showspeakers:false,speakers:{},fetchingdata:true,year:this.year,plan:null,years:[this.year-2,this.year-1,this.year]}
 savePeersData = (peers,list) => {
  if(peers && peers.length>0){
    peers.map(x => {
      x.activeIndex = 0;
    })
  }
 //console.log(peers,list,'here');
  this.setState({fetchingdata:true})
  if(peers.length!=0){
    this.fetchSpeakers();
    this.setState({compare:true})
  }
  if(list.data){
    this.setState({peers:peers,list:list.data,total:list.total,compare:true,activeIndex:[peers[0].value]})
  }
  if(peers.length==0 && list.length==0){
    this.setState({peers:[],list:[],compare:true})
  }
  if(peers.length>0 && list.length==0){
    this.setState({list:[],compare:true})
  }
  var that = this;
  this.setState({fetchingdata:false})
  if(!TimeMe.getTimeOnPageInSeconds("participation-tab") && !TimeMe.getTimeOnPageInSeconds("speakers-tab")){
    TimeMe.startTimer('participation-tab')
  }
 }

 resetPeers = () => {
  this.setState({speakers:{},peers:[],compare:false,list:[],total:{},fetchingdata:false})
 }
 componentDidUpdate(prevProps,prevState){
 // console.log("updated");
  // if(prevState.fetchingdata != this.state.fetchingdata || this.state.showspeakers!=prevState.showspeakers || this.state.showparticipation!=prevState.showparticipation){
  //   //console.log(prevState,this.state,"st")
  //   var tp = window.tp;
  //      //console.log(tp,"tp")
  //      tp.experience.execute();
  // }
 }

 componentDidMount(){
  //console.log('here')
  if(localStorage.user_details){
    var user = JSON.parse(localStorage.user_details);
    this.setState({plan:user.plan})
    // var tp = window.tp;
    // setTimeout(() => {
    //   tp.experience.execute();
    // },200)
    // if(user.plan == 'Free'){
    //   this.props.history.push('/')
    // }
  }
  //this.fetchSpeakers();
 }
 fetchSpeakers = () => {
  var notSet=true;
  axios.post('peer_speakers/'+this.state.year,{plan:DEFAULTPLAN}).then(data =>{
    this.setState({speakers:data.data})
    if(Object.keys(data.data).length>0){
      this.setState({compare:true})
      Object.keys(data.data).map(x => {
        this.state.peers.map(y => {
          if(y.text==x && notSet){
            this.setState({activeIndex:[y.value]})
            notSet=false;
          }
        })
      })
    }
  }).catch(err => {
    console.log(err);
  })
 }
 fetchParticipation=()=>{
  axios.post('peer_participation/'+this.state.year,{plan:DEFAULTPLAN}).then(list => {
              this.setState({list:list.data.data})
              
            }).catch(err => {
              console.log(err);
            })
 }

 startLoader = () => {
  this.setState({fetchingdata:true})
 }

 downloadCsv = (peers) => {
 // this.startLoader();
 this.setState({downloading:true},()=>{
  var { year } = this.state;
  var comp = this.state.peers.map(x => x.value);
            
              axios({method:'POST',url:'download_compare_peers_list',data:{peers,date:{start:`Jan ${year}`,end:`Dec ${year}`},year},responseType:'blob'}).then(data => {
        const url = window.URL.createObjectURL(new Blob([data.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'EA_Compare-peers_list.xlsx'); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.setState({downloading:false})
              

      }).catch(err => {
        console.log(err);
      })
                
                   //Analytics for download //
                  var comp = this.state.peers.map(x => x.value);
                  var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                   axios.post('record',{identity:5,file_type:'Peers',file_format:'xlsx',company_id:comp,via:'Compare-Peers',plan_type:plan_name[0].name}).then(done => {

                    }).catch(err => {
                      console.log(err,"analytics error");
                    })
 })
                 
                
            
          


}

   handleSpeakerEvent = (comp_name,conf_name) => (event,data) => {
    event.stopPropagation();
    //console.log(event,data,"dd")
    var speakers = {...this.state.speakers};
    var comp = speakers[comp_name];
    var conf_id = null;
     speakers[comp_name].map(x => {
      if(x.name==conf_name){
       conf_id =   x.speaker[0].id;
       if(data.rating==1){
        x.starred = true;
       }
       else{
        x.starred=false;
       }
      }
    })
    if(data.rating==1){
        
        axios.post('save_event',{conference_id:conf_id}).then(saved => {
           
      
        }).catch(err => {
          console.log(err);
        })
          var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }
        axios.post('record',{conference_id:conf_id,via:'Compare-Peers',star_type:'small',action:'Saved',identity:8,plan_type:plan_name[0].name}).then(done => {

        }).catch(err => {
          console.log(err,"analytics error");
        })
      }
      else{
        
         var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }
        axios.post('delete_event',{conference_id:conf_id}).then(saved => {
      
              
        }).catch(err => {
          console.log(err);
        })
         axios.post('record',{conference_id:conf_id,via:'Compare-Peers',star_type:'small',action:'Un-Saved',identity:8,plan_type:plan_name[0].name}).then(done => {

        }).catch(err => {
          console.log(err,"analytics error");
        })
      }
      this.setState({speakers:speakers})
     // console.log(right_side.id,obj.id,'oo')
      
      //this.setState({conf_list:conference});
    
    
  }

  switchTab = (type) => event => {
    //console.log(TimeMe.getTimeOnPageInSeconds("participation-tab"),TimeMe.getTimeOnPageInSeconds("speakers-tab"))
    if(type == 'showparticipation'){
      this.setState({[type]:true,showspeakers:false})
       TimeMe.startTimer('participation-tab')
        TimeMe.stopTimer('speakers-tab');
        this.fetchParticipation();
    }
    else{
      const{ peers , speakers} = this.state;
      var  openPeer;
      var allKeySpeakers = Object.keys(speakers);
       if(allKeySpeakers.length) {
        var check=peers.find(p=>p.text===allKeySpeakers.find(sp=>p.text===sp))
        openPeer = check?[check.value]:[];
      }

       this.setState({[type]:true,showparticipation:false,activeIndex:peers.length?openPeer:[]});
       TimeMe.stopTimer('participation-tab');
       TimeMe.startTimer('speakers-tab');
       this.fetchSpeakers();
    }
  }
  calculatePlan = () =>{
    var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }

      return plan_name;
  }
   componentWillUnmount(){
    //console.log(TimeMe.getTimeOnPageInSeconds("participation-tab"),TimeMe.getTimeOnPageInSeconds("speakers-tab"))
    if(TimeMe.getTimeOnPageInSeconds("participation-tab") && TimeMe.getTimeOnPageInSeconds("participation-tab")!=0){
       var plan_name = this.calculatePlan();                
      axios.post('record',{identity:10,tab:'Participation Tab',time_spent:TimeMe.getTimeOnPageInSeconds("participation-tab"),plan_type:plan_name[0].name}).then(done => {

      }).catch(err => {
        console.log(err,"analytics error");
      })
    }
    if(TimeMe.getTimeOnPageInSeconds("speakers-tab") && TimeMe.getTimeOnPageInSeconds("speakers-tab")!=0){
       var plan_name = this.calculatePlan();
                    
      axios.post('record',{identity:10,tab:'Speakers Tab',time_spent:TimeMe.getTimeOnPageInSeconds("speakers-tab"),plan_type:plan_name[0].name}).then(done => {

      }).catch(err => {
        console.log(err,"analytics error");
      })
    }
    TimeMe.resetAllRecordedPageTimes();
  }

   openConfPopup = (id) => event => {
      TimeMe.startTimer("event-conf-popup");
      this.setState({modalOpen:id})
        
    }

 handleClose = () => {
  
      this.setState({ modalOpen: false })
    }

    handleClick = obj => (e, titleProps) => {     
      const { index } = titleProps;
      const { activeIndex } = this.state;
      var index1 = this.state.activeIndex.indexOf(obj.value)!=-1?this.state.activeIndex.filter(x=>x!=obj.value):[...activeIndex, obj.value] 
      this.setState({activeIndex:index1})
    }
    checkExist = value=>{
      if(value)
        return value;
      return "TBD";
    }
    handleEvent = (ArrayId,type) => (event,data) => {
      var ArrayId2 ;
      backup_conf = {...ArrayId[0]};
      if(this.state.showparticipation){
        ArrayId2 = ArrayId[0].id;
        
      }
      else{
        ArrayId2=ArrayId[0].speaker[0].id;
        //  var newSpeaker = {...ArrayId[0].speaker[0]}
        // backup_conf= {...newSpeaker};
        // console.log(ArrayId2);
        // console.log(backup_conf);
  
      }
    if(TimeOutSave){
      clearTimeout(TimeOutSave);
      clearTimeout(TimeOutRecord);
      clearTimeout(common);
    }
    //event.stopPropagation();
        this.setState({undo:true},()=>{

    var list = [...this.state.list];    
    var speakers = {...this.state.speakers};    
        if(data.rating==1){
         if(this.state.showparticipation){
               if(ArrayId.length > 1) {
              list.map(cf=>{
                cf.starred = true;
              })
            }
            else {
              list.map(cf=>{
                if(cf.id === ArrayId[0].id) {
                   cf.starred = true;             
                }
              }
              )
            }
         }
         else {
          for(var spks in speakers){
            speakers[spks].map(cf=>{
              cf.speaker.map(cf2=>{
                if(cf2.id === ArrayId2)
                cf.starred = true;
              })
            })
          }
         }
        // TimeOutSave= setTimeout(()=>{
        //   if(this.state.undo ) {
        //       this.setState({undo:false},()=>{
        //           axios.post('save_event',{conference_id:ArrayId2}).then(saved => {
        //          this.setState({undo:false})
        //         }).catch(err => {
        //           console.log(err);
        //         })
        //           })
        //   }
        //   clearTimeout(TimeOutSave);
        //     clearTimeout(TimeOutRecord);
        //     clearTimeout(common);
        // },timeDuration)
                  axios.post('save_event',{conference_id:ArrayId2}).then(saved => {
                 this.setState({undo:false})
                }).catch(err => {
                  console.log(err);
                })
        var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }
        // TimeOutRecord = setTimeout(()=>{
        //   if(this.state.undo) {
        //     this.setState({undo:false},()=>{
        //       axios.post('record',{conference_id:ArrayId2,via:'Search-Events',star_type:type,action:'Un-Saved',identity:8,plan_type:plan_name[0].name}).then(done => {
        //     }).catch(err => {
        //       console.log(err,"analytics error");
        //     })
        //     })
        //   }
        //   clearTimeout(TimeOutSave);
        //     clearTimeout(TimeOutRecord);
        //     clearTimeout(common);


        // },timeDuration)
              axios.post('record',{conference_id:ArrayId2,via:'Compare-Peers',star_type:type,action:'Saved',identity:8,plan_type:plan_name[0].name}).then(done => {
            }).catch(err => {
              console.log(err,"analytics error");
            })
        }
        else{
         if(this.state.showparticipation){
               if(ArrayId.length > 1) {
              list.map(cf=>{
                cf.starred = false;
              })
            }
            else {
              list.map(cf=>{
                if(cf.id === ArrayId[0].id) {
                   cf.starred = false;             
                }
              }
              )
            }
         }
         else {
          for(var spks in speakers){
            speakers[spks].map(cf=>{
              cf.speaker.map(cf2=>{   
                if(cf2.id === ArrayId2)
                cf.starred = false;
              })

            })
          }
         }
        // TimeOutSave= setTimeout(()=>{
        //   if(this.state.undo ) {
        //       this.setState({undo:false},()=>{
        //           axios.post('delete_event',{conference_id:ArrayId2}).then(saved => {    
        //         this.setState({undo:false})
        //         }).catch(err => {
        //           console.log(err);
        //         })
        //           })
        //   }
        //   clearTimeout(TimeOutSave);
        //     clearTimeout(TimeOutRecord);
        //     clearTimeout(common);
        // },timeDuration)
                  axios.post('delete_event',{conference_id:ArrayId2}).then(saved => {    
                this.setState({undo:false})
                }).catch(err => {
                  console.log(err);
                })
        var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }
        // TimeOutRecord = setTimeout(()=>{
        //   if(this.state.undo) {
        //     this.setState({undo:false},()=>{
        //       axios.post('record',{conference_id:ArrayId2,via:'Search-Events',star_type:type,action:'Un-Saved',identity:8,plan_type:plan_name[0].name}).then(done => {
        //     }).catch(err => {
        //       console.log(err,"analytics error");
        //     })
        //     })
        //   }
        //   clearTimeout(TimeOutSave);
        //     clearTimeout(TimeOutRecord);
        //     clearTimeout(common);


        // },timeDuration)
              axios.post('record',{conference_id:ArrayId2,via:'Compare-Peers',star_type:type,action:'Un-Saved',identity:8,plan_type:plan_name[0].name}).then(done => {
            }).catch(err => {
              console.log(err,"analytics error");
            })
        }
      common=setTimeout(()=>{
          if(TimeOutSave) {
            this.undoChanges(false);
          }
        },timeDuration)      
        if(this.state.showparticipation){
          this.setState({list});
        }
        else {
          this.setState({speakers});
        }
        })
    
    
  }
    undoChanges=(restore = true)=>{
    clearTimeout(TimeOutSave);
            clearTimeout(TimeOutRecord);
            clearTimeout(common);      
            const { list, speakers, showparticipation } = this.state;       
            if(showparticipation) {
              var newList = list.map(cf=>{
                if(cf.id === backup_conf.id)
                  cf.starred = backup_conf.starred;
                return cf;
              });
              this.setState({list:newList,undo:false}) 
            }
            else {
              for(var spks in speakers) {
                 speakers[spks].map(cf=>{
                  cf.speaker.map(cf2=>{
                      if(cf2.id === backup_conf.speaker[0].id)
                       cf.starred = backup_conf.starred?1:0;
                  })
                 })
              }
              this.setState({peers:this.state.peers,undo:false})
            }
     
  }
      sort=(field,order)=>{
         this.state.list.sort((obj1,obj2)=>{
         if(obj1[field] && obj2[field]) {
          var nameA=obj1[field].toLowerCase(), nameB=obj2[field].toLowerCase();
       if (nameA < nameB) //sort string ascending
        return -1;
       if (nameA > nameB)
        return 1;
       return 0;
         }
         else return 0;
      })
        
        if(order==="down"){
          this.state.list=this.state.list.reverse();
        }

      this.setState({activeCol:`${field} ${order}`})
    }
        sortDate=(order)=>{
            this.state.list.sort((obj1,obj2)=>{
        var nameA=new Date(obj1['start_date']).getTime(), nameB=new Date(obj2['start_date']).getTime();
       if (nameA < nameB) //sort string ascending
        return -1;
       if (nameA > nameB)
        return 1;
       return 0;
      });
      if(order==="down"){       
          this.state.list=this.state.list.reverse();
        }
        this.setState({activeCol:`date ${order}`})
    }
   
    handleYearSelect = (selectedYear) => {
      const currentYear=new Date().getFullYear();
      if(selectedYear==currentYear){
        this.setState({years:[currentYear - 2, currentYear-1,currentYear]})
      }
      else{
      this.setState({years:[selectedYear - 1, selectedYear, currentYear]});
      }
      this.setState({ year: selectedYear});
  }

  render(){
    if(DEFAULTPLAN==="Free1") {
      this.props.history.push('/search-events');
    }
   const arr = [0,1,2,3,6,7,8,,9];
   const { fetchingdata, activeCol,year } = this.state;
  // window.tp.experience.execute();
    const nf1 = new Intl.NumberFormat("en-US", {
         style:"currency",
          currency:"USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });
        
    return(
      <Fragment>
      <style>
        {`
        .long.arrow.alternate{
              cursor: pointer;
            font-size: 18px;
            margin: 0;
            width: 11px;
        }
       .fl-right{
        display: inline-block;
        padding-left: 11px;
       }
       th.companies{
        padding-right:64px !important;

       }
       th.companies >div{
        margin-right:-50px;
        
       }`
             }
      </style>

      <PeerHeader year={this.state.year} action={this.savePeersData} loading={this.startLoader} download={this.downloadCsv} list={this.state.list} speaker={this.state.speakers} resetaction={this.resetPeers} />
      {/*<FilterHeader filters={this.state.industries} action={this.changeFilter} changed={this.state.changedfilters} pdf={this.downloadPdf} />*/}
      {!this.state.compare && DEFAULTPLAN!='Free'
        && <Container fluid style={{height:'70vh'}}>
      <Header as="h3" style={{marginTop: '10px'}}> <Icon name="arrow up"/></Header>
      <Header as="h3" style={{marginTop: '0px'}}>Add companies to compare</Header>
     
      <div className="compare-peers-header">
      <span>COMPARE PEERS</span>
      </div>
    </Container>}

    {
      this.state.compare &&  <Container fluid>
      <Grid style={{padding: '10px 0px',margin: '0'}}><Grid.Column largeScreen={2} mobile={6} tablet={6}><Segment onClick={this.switchTab('showparticipation')} className="get-border-radius" style={{cursor: 'pointer'}} textAlign="center" size="large"  basic={this.state.showspeakers} inverted={this.state.showparticipation} color={this.state.showparticipation?'blue':null}><Header as="h5" style={{textTransform:'uppercase',fontSize:'12px'}} color={!this.state.showparticipation?'blue':null}>Participation</Header></Segment></Grid.Column>
            {/* Spekers removed 9th August 2021 */}
            {/* <Grid.Column largeScreen={2} mobile={5} tablet={5}>  
            <Segment textAlign="center" className="get-border-radius" style={{cursor: 'pointer'}} onClick={this.switchTab('showspeakers')}  basic={this.state.showparticipation} inverted={this.state.showspeakers} color={this.state.showspeakers?'blue':null}><Header as="h5" color={!this.state.showspeakers?'blue':null} style={{textTransform:'uppercase',fontSize:'12px'}}>Speakers</Header></Segment></Grid.Column> */}
            <Grid.Column verticalAlign="middle" width={5} floated="right">
             <Dropdown style={{float:'right'}} trigger={<Header color="blue" as="a" size="tiny" floated="right"><Icon name="angle left"/>{this.state.year}</Header>}>
              <Dropdown.Menu direction="left">
              {this.state.years.map((year, index) => (
                        <Dropdown.Item key={index} onClick={() => this.handleYearSelect(year)}>{year}</Dropdown.Item>
                    ))}
                                        
                                       </Dropdown.Menu>
                                   </Dropdown>
              </Grid.Column></Grid>
              { this.state.fetchingdata && <span></span>
                // <Table>
                // <Table.Header className="table-peers-head">
                // <Table.Row>
                //            <Table.HeaderCell singleLine><Placeholder><Placeholder.Line/></Placeholder></Table.HeaderCell>
                //            <Table.HeaderCell><Placeholder><Placeholder.Line/></Placeholder></Table.HeaderCell>
                //            <Table.HeaderCell><Placeholder><Placeholder.Line/></Placeholder></Table.HeaderCell>
                //            <Table.HeaderCell><Placeholder><Placeholder.Line/></Placeholder></Table.HeaderCell>
                //            <Table.HeaderCell><Placeholder><Placeholder.Line/></Placeholder></Table.HeaderCell>
                //   </Table.Row>
                // </Table.Header>
                // <Table.Body>
                //  <Table.Cell><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
                //   <Table.Cell><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
                //   <Table.Cell><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
                //    <Table.Cell><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
                   
                // </Table.Body>
                // </Table>
              }
    {this.state.showparticipation && this.state.list.length>0 &&  <div id="table-fixed" className='summary-div'><Table  unstackable id="print-table" >
                   <Table.Header fullWidth className="table-peers-head">
                   
                         <Table.Row>
                           <Table.HeaderCell singleLine></Table.HeaderCell>                          
                           <Table.HeaderCell>EVENTS <div className="fl-right"><Icon name = "long arrow alternate up" onClick={this.sort.bind(this,"event_name","up")} style={{color:activeCol=="event_name up"?"#000000de":"#717171de"}}/><Icon name = "long arrow alternate down" onClick={this.sort.bind(this,"event_name","down")} style={{color:activeCol=="event_name down"?"#000000de":"#717171de"}}/></div></Table.HeaderCell>
                            <Table.HeaderCell>Date <div className="fl-right"><Icon name = "long arrow alternate up" onClick={this.sortDate.bind(this,"up")} style={{color:activeCol=="date up"?"#000000de":"#717171de"}}/><Icon name = "long arrow alternate down" onClick={this.sortDate.bind(this,"down")} style={{color:activeCol=="date down"?"#000000de":"#717171de"}}/></div></Table.HeaderCell>
                            {this.state.peers.map((obj,index) => {
                              return(
                                 <Table.HeaderCell key={index}>{obj.text}</Table.HeaderCell>
                              )
                             
                            })}
                         </Table.Row>
                   </Table.Header>
                   <Table.Body>
                   {/*this.state.undo && <Table.Row style={{textAlign:"center"}}><Table.Cell colSpan={10} style = {{color:"#5290ff"}}><Icon style={{position:"relative",top:"4px"}}name = "redo" rotated="counterclockwise"/><Button style = {{color:"inherit",backgroundColor:"transparent",paddingLeft:"3px"}}onClick={this.undoChanges}>UNDO LAST CHANGES</Button></Table.Cell></Table.Row>*/}
                   {
                    this.state.fetchingdata && this.state.list.slice(0,3).map((obj,index) => {
                      //console.log(obj,"o")
                         return(
                         <Table.Row key={index}>
                           <Table.Cell collapsing><Rating  size="huge" /></Table.Cell>
                           <Table.Cell ><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
                           <Table.Cell className="conf-name" width={3}><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
                          {this.state.peers.map((obj1,index) => {
                            return(
                               <Table.Cell key={index}><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
                            )
                          })}
                          
                           
                         </Table.Row>
                       )})
                   }
                     {
                       !this.state.fetchingdata && DEFAULTPLAN=='Month' && this.state.list.slice(0,3).map((obj,index) => {
                         return(
                         <Table.Row key={index}>
                           <Table.Cell collapsing><Rating rating={obj.starred?1:0} onRate={this.handleEvent([obj])} size="huge" defaultRating={this.state.rating}/></Table.Cell>
                             <Modal  open={this.state.modalOpen && this.state.modalOpen==obj.id } onClose={this.handleClose} trigger={<Table.Cell onClick={this.openConfPopup(obj.id)} className="conf-name" width={4} style={{cursor:'pointer'}}>{this.state.fetchingdata?(<Placeholder><Placeholder.Line/></Placeholder>):(obj.event_name)}</Table.Cell>}>
                            <Modal.Content>
                            <SingleConferencePage events={true} data={obj} unsave={this.handleEvent}  />
                            </Modal.Content>
                            </Modal>
                            <Table.Cell>{obj.date }</Table.Cell>
                          {this.state.peers.map((obj1,index) => {
                            return(
                               <Table.Cell key={index}>{obj[obj1.text]?(obj[obj1.text].type):(null)}<br/> {obj[obj1.text]?(obj[obj1.text].price?(nf1.format(obj[obj1.text].price)):null):(null)}</Table.Cell>
                            )
                          })}
                          
                           
                         </Table.Row>
                       )})
                      }
                      {
                       !this.state.fetchingdata && DEFAULTPLAN!='Month' && this.state.list.slice(0,3).map((obj,index) => {
                         return(
                         <Table.Row key={index}>
                           <Table.Cell collapsing><Rating rating={obj.starred?1:0} onRate={this.handleEvent([obj])} size="huge" defaultRating={this.state.rating}/></Table.Cell>
                          <Modal  open={this.state.modalOpen && this.state.modalOpen==obj.id } onClose={this.handleClose} trigger={<Table.Cell onClick={this.openConfPopup(obj.id)} className="conf-name" width={4} style={{cursor:'pointer'}}>{this.state.fetchingdata?(<Placeholder><Placeholder.Line/></Placeholder>):(obj.event_name)}</Table.Cell>}>
                            <Modal.Content>
                            <SingleConferencePage events={true} data={obj} unsave={this.handleEvent}  />
                            </Modal.Content>
                            </Modal>
                             <Table.Cell>{obj.date }</Table.Cell>
                          {this.state.peers.map((obj1,index) => {
                            return(
                               <Table.Cell key={index}>{obj[obj1.text]?(obj[obj1.text].type):(null)}<br/> {obj[obj1.text]?(obj[obj1.text].price?(nf1.format(obj[obj1.text].price)):null):(null)}</Table.Cell>
                            )
                          })}
                          
                           
                         </Table.Row>
                       )})
                      }
                       
                      {!this.state.fetchingdata && DEFAULTPLAN!='Month' && this.state.list.slice(3,this.state.list.length).map((obj,index) => {
                         return(
                        
                          <Table.Row key={index} style={{width: '100%'}}>
                      {/*  <Dimmer.Dimmable   blurring dimmed={DEFAULTPLAN=='Free' || DEFAULTPLAN=='Month'} style={{width: '100%'}}>
                         <Dimmer active={DEFAULTPLAN=='Free' || DEFAULTPLAN=='Month'} style={{width: '100%'}} >
                          <Header as='h4' icon inverted >
                              <Icon name='lock' />
                              <Link to="/account">Upgrade</Link> now to see full results!
                            </Header>
                          </Dimmer>*/}
                           <Table.Cell collapsing><Rating rating={obj.starred?1:0} onRate={this.handleEvent([obj])} size="huge" defaultRating={this.state.rating}/></Table.Cell>
                           <Modal  open={this.state.modalOpen && this.state.modalOpen==obj.id } onClose={this.handleClose} trigger={<Table.Cell onClick={this.openConfPopup(obj.id)} className="conf-name" width={4} style={{cursor:'pointer'}}>{this.state.fetchingdata?(<Placeholder><Placeholder.Line/></Placeholder>):(obj.event_name)}</Table.Cell>}>
                            <Modal.Content>
                            <SingleConferencePage events={true} data={obj} unsave={this.handleEvent}  />
                            </Modal.Content>
                            </Modal>
                             <Table.Cell>{obj.date }</Table.Cell>
                          {this.state.peers.map((obj1,index) => {
                            return(
                               <Table.Cell key={index}>{obj[obj1.text]?(obj[obj1.text].type):(null)}<br/> {obj[obj1.text]?(obj[obj1.text].price?(nf1.format(obj[obj1.text].price)):null):(null)}</Table.Cell>
                            )
                          })}
                          
                           
                        
                         
                         </Table.Row>
                       )})}
                   </Table.Body>
                    { !this.state.fetchingdata && DEFAULTPLAN!='Month' && <Table.Footer className="table-peers-head" fullWidth>
                      <Table.Row>
                        <Table.HeaderCell/>
                        <Table.HeaderCell >Total Estimated Investment</Table.HeaderCell>
                         <Table.HeaderCell/>
                        {this.state.peers.map((obj,index) => {
                          
                          return(
                            <Table.HeaderCell key={index}>
                            {this.state.total[obj.text]?nf1.format(this.state.total[obj.text]):null}
                            </Table.HeaderCell>
                          )
                        })}
                      </Table.Row>
                    </Table.Footer>}
             </Table></div>}             
              {!this.state.fetchingdata && this.state.showspeakers && Object.keys(this.state.speakers).length>0  &&<div id="print-speaker"> 
              {this.state.peers.map((obj,index) => {
                //console.log(obj,"o")
        //console.log(this.state.speakers,this.state.speakers[obj.industry],obj,"indut")
               return(<Fragment key={index}>    {this.state.speakers[obj.text] &&
                    
                 <div className='summary-div' style={{width: '100%',maxWidth: '100%'}}>
                
                  <Accordion exclusive={false} style={{width: '100%',maxWidth: '100%',marginBottom:Object.keys(this.state.speakers).length>1?'10px':'0'}}>
                                              <Accordion.Title active={this.state.activeIndex.indexOf(obj.value)!=-1} index={obj.value} onClick={this.handleClick(obj)} style={{borderRadius:'.28571429rem .28571429rem 0 0', backgroundColor:'#cce2ff', padding:'1.02857em 0.785714em'}}>
                                              <Header as="h4">{obj.text} &nbsp;<Icon data-html2canvas-ignore="true" style={{float:'right'}} name={this.state.activeIndex.indexOf(obj.value)!=-1?'chevron up':'chevron down'}  /></Header>
                                              </Accordion.Title>
                                              <Accordion.Content className="overflow-mobile"  active={this.state.activeIndex.indexOf(obj.value)!=-1} style={{background:'white',padding: '0',paddingTop: '0.5em'}}>
                                             <div>
                                              {/*this.state.undo && <Grid className="speaker-chart-grid" style={{padding:'0px',margin: '0',marginBottom:"7px",borderBottom:"1px solid rgba(34,36,38,.15)"}}><Grid.Column width={16} style = {{color:"#5290ff",textAlign:"center",margin:"0px",padding:"0.7rem",}}><Icon style={{position:"relative",top:"4px"}}name = "redo" rotated="counterclockwise"/><Button style = {{color:"inherit",backgroundColor:"transparent",paddingLeft:"3px"}}onClick={this.undoChanges}>UNDO LAST CHANGES</Button></Grid.Column></Grid>*/}
                     {
                      this.state.speakers[obj.text] && this.state.speakers[obj.text].map((obj1,index) => {
                        //console.log(obj1,"obj")
                        if(obj1.speaker.length==1){
                          //console.log('1 length')
                            return(
                              <Fragment key={index}>
                             
                              <Grid className="speaker-chart-grid" style={{marginLeft: '0',marginRight: '0',padding:0,marginTop: index==0?'-7px':'0'}}>
                            
                              <Grid.Column className="rating-speaker" textAlign="center" verticalAlign="middle" style={{width: 'auto',padding:'0.7rem'}} verticalAlign="middle"><Rating rating={obj1.starred?1:0} size="huge" onRate={this.handleEvent([obj1])} defaultRating={this.state.rating} /></Grid.Column>
                               <Grid.Column width={3} style={{margin: '0',padding:'0.7rem'}}  className="conf-name"><Grid style={{padding:'0',margin: '0',height:'100%'}}><Grid.Column style={{padding:'0',margin: '0'}} verticalAlign="middle" width={16}>{obj1.name}</Grid.Column></Grid></Grid.Column>
                               <Grid.Column width={3}>{obj1.date}</Grid.Column>
                               <Grid.Column style={{width: 'calc(100% - 37.5% - 45.2px)',padding:0}} >
                               <Grid style={{padding:0,margin:0}}>
                               <Grid.Column verticalAlign="middle"  width={5} style={{padding:'0',margin: '0'}}>
                               
                               <Grid style={{margin: '0'}}><Grid.Column width={4}><Image centered  size="tiny" src={speakerimg} style={{width: '35px'}} /></Grid.Column><Grid.Column verticalAlign="middle" width={12}  ><Header as="h5" >
                                <Header.Content>
                                {obj1.speaker[0].Name}
                                <Header.Subheader>{obj1.speaker[0].Title}</Header.Subheader>
                                </Header.Content>
                                </Header></Grid.Column>
                                </Grid>
                               </Grid.Column>
                              <Grid.Column   verticalAlign="middle" style={{padding:'0.7rem',margin: '0'}} width={2}>{this.checkExist(obj1.speaker[0].Role)}</Grid.Column>
                              <Grid.Column   verticalAlign="middle" style={{padding:'0.7rem',margin: '0'}} width={5}>{this.checkExist(obj1.speaker[0].Topic)}</Grid.Column>
                              
                              </Grid>
                              </Grid.Column>
                              </Grid>

                              <Divider className="speaker-chart-grid" style={{marginBottom: '0px'}}/>
                            </Fragment>
                       )
                        }
                        else{
                        //  console.log('more 1 length')
                          return(
                            <Fragment key={index}>
                            {/*this.state.undo && <Table.Row style={{textAlign:"center"}}><Table.Cell colSpan={10} style = {{color:"#5290ff"}}><Icon style={{position:"relative",top:"4px"}}name = "redo" rotated="counterclockwise"/><Button style = {{color:"inherit",backgroundColor:"transparent",paddingLeft:"3px"}}onClick={this.undoChanges}>UNDO LAST CHANGES</Button></Table.Cell></Table.Row>*/}
                            <Grid className="speaker-chart-grid" style={{marginLeft: '0',marginRight: '0',padding:0,marginTop: index==0?'-7px':'0'}}>
                              <Grid.Column className="rating-speaker" textAlign="center" verticalAlign="middle" style={{width: 'auto',padding:'0.7rem'}} verticalAlign="middle"><Rating rating={obj1.starred?1:0} size="huge" onRate={this.handleEvent([obj1])} defaultRating={this.state.rating} /></Grid.Column>
                                 <Grid.Column width={3} style={{margin: '0',padding:'0.7rem'}}  className="conf-name"><Grid style={{padding:'0',margin: '0',height:'100%'}}><Grid.Column style={{padding:'0',margin: '0'}} verticalAlign="middle" width={16}>{obj1.name}</Grid.Column></Grid></Grid.Column>
                                 <Grid.Column width={3} style={{margin: '0',padding:'0.7rem'}} ><Grid style={{padding:'0',margin: '0',height:'100%'}}><Grid.Column style={{padding:'0',margin: '0'}} verticalAlign="middle" width={16}>{obj1.date}</Grid.Column></Grid></Grid.Column>
                                <Grid.Column  style={{width: 'calc(100% - 37.5% - 45.2px)',padding:' 0'}} >
                               
                              {obj1.speaker.map((speaker,index) => {
                              return(
                                <Fragment key={index}>
                                <Grid style={{padding:0,margin:0}}>
                                <Grid.Column verticalAlign="middle" width={5} style={{margin: '0',padding:'0'}}>
                               <Grid style={{margin: '0'}}><Grid.Column  width={4}><Image centered  size="tiny" src={speakerimg} style={{width: '35px'}} /></Grid.Column><Grid.Column width={12}  verticalAlign="middle"><Header as="h5" >
                                <Header.Content>
                                {speaker.Name}
                                <Header.Subheader>{speaker.Title}</Header.Subheader>
                                </Header.Content>
                                </Header></Grid.Column>
                                </Grid>
                               </Grid.Column>
                               {/*<Grid.Column style={{width: '0.8%',padding:0}}></Grid.Column>*/}
                                <Grid.Column verticalAlign="middle" style={{margin: '0',padding:'0.7rem'}}  width={2}>{this.checkExist(speaker.Role)}</Grid.Column>
                                {/* <Grid.Column style={{width: '1%',padding:0}}></Grid.Column>*/}
                                <Grid.Column verticalAlign="middle" style={{margin: '0',padding:'0.7rem'}} width={5}>{this.checkExist(speaker.Topic)}</Grid.Column>
                                
                                </Grid>
                                {index!=obj1.speaker.length-1 && <Divider className="speaker-chart-grid" style={{marginBottom: '0px',marginTop:0}}/>}
                                 </Fragment>
                        
                         )
                            })}
                                </Grid.Column>
                                 
                                </Grid>
                                 <Divider className="speaker-chart-grid" style={{marginBottom: '0px'}}/>
                            </Fragment>
                           
                          )
                        }
                       })
                      }
                   </div>
                                              </Accordion.Content>              
                                                 </Accordion>   
                
                  
                 
           </div>

                }
               
                </Fragment>)
              
              })
    
              }</div>

          } 
           
          {!fetchingdata && this.state.list.length==0 && !this.state.showspeakers &&  <div style={{height:DEFAULTPLAN=='Annual'?'50vh':'auto'}}>
          <p style={{fontSize: '1.2rem',fontWeight:700,textAlign:'initial',marginTop: '20px'}}>Looks like you’ve found some whitespace for your company! We have no selected calendar year events for your selected companies.</p>
      </div>}  
        {(Object.keys(this.state.speakers).length==0 && this.state.showspeakers && !fetchingdata) &&  <div style={{height:DEFAULTPLAN=='Annual'?'50vh':'auto'}}>
         <p style={{fontSize: '1.2rem',fontWeight:700,textAlign:'initial',marginTop: '20px'}}>Looks like you’ve found some whitespace for your company! We have no selected calendar year events for your selected companies.</p>      
      </div>}
     
      {this.state.downloading &&
                <Dimmer active page inverted>
                               <Loader inline='centered' inverted content='Loading' />
                            </Dimmer>
              }

      </Container>
    }
     { (DEFAULTPLAN==="Free" || DEFAULTPLAN==="Month") && <div id="compare-peers-template"></div> }  
    </Fragment>
    )
  }
}

export default withRouter(ComparePeers)