import React, { Component, Fragment } from "react";
import {
  Container,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Grid,
  Checkbox,
  Transition,
  Button,
  Input,
  Dropdown,
  Divider,
  Modal,
} from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import download from "./../../assets/img/download.png";
import axios from "axios";
var DEFAULTPLAN = "Annual";
var LocationString =
  "Australia,Bahrain,Bahamas,Belgium,Brunei,Canada,China,Denmark,Finland,France,Germany,Hong Kong,India,Indonesia,Ireland,Israel,Kenya,Mexico,Monaco,Netherlands,Nigeria,Rwanda,Singapore,South Africa,Spain,Switzerland,United Arab Emirates,United Kingdom,United States,Virtual";
var LocationList = LocationString.split(",").map((c) => {
  return { country: c, selected: false };
});

class FilterHeader extends Component {
  state = {
    open_search: false,
    range_type: "upcoming",
    user: {},
    search: "",
    old_filter: null,
    filters: {},
    maxindustries: false,
    old_search: "",
    old_location: [],
    countries: LocationList,
    us_states: [
      { state: "AZ", selected: false },
      { state: "CA", selected: false },
      { state: "CO", selected: false },
      { state: "CT", selected: false },
      { state: "DC", selected: false },
      { state: "FL", selected: false },
      { state: "GA", selected: false },
      { state: "HI", selected: false },
      { state: "IL", selected: false },
      { state: "IN", selected: false },
      { state: "LA", selected: false },
      { state: "MD", selected: false },
      { state: "MA", selected: false },
      { state: "MI", selected: false },
      { state: "MN", selected: false },
      { state: "MT", selected: false },
      { state: "NV", selected: false },
      { state: "NJ", selected: false },
      { state: "NY", selected: false },
      { state: "RI", selected: false },
    ],
    location: [],
    industries: [],
    filters_app: { industries: [] },
    industry_filter: false,
    location_filter: false,
    range_filter: false,
    selected_date: [],
    date_filter: [
      { month: "Jan", selected: false, id: "118", fullname: "Jan 2018" },
      { month: "Feb", selected: false, id: "218", fullname: "Feb 2018" },
      { month: "Mar", selected: false, id: "318", fullname: "Mar 2018" },
      { month: "Apr", selected: false, id: "418", fullname: "Apr 2018" },
      { month: "May", selected: false, id: "518", fullname: "May 2018" },
      { month: "Jun", selected: false, id: "618", fullname: "Jun 2018" },
      { month: "Jul", selected: false, id: "718", fullname: "Jul 2018" },
      { month: "Aug", selected: false, id: "818", fullname: "Aug 2018" },
      { month: "Sep", selected: false, id: "918", fullname: "Sep 2018" },
      { month: "Oct", selected: false, id: "1018", fullname: "Oct 2018" },
      { month: "Nov", selected: false, id: "1118", fullname: "Nov 2018" },
      { month: "Dec", selected: false, id: "1218", fullname: "Dec 2018" },
      { month: "Jan", selected: false, id: "119", fullname: "Jan 2019" },
      { month: "Feb", selected: false, id: "219", fullname: "Feb 2019" },
      { month: "Mar", selected: false, id: "319", fullname: "Mar 2019" },
      { month: "Apr", selected: false, id: "419", fullname: "Apr 2019" },
      { month: "May", selected: false, id: "519", fullname: "May 2019" },
      { month: "Jun", selected: false, id: "619", fullname: "Jun 2019" },
      { month: "Jul", selected: false, id: "719", fullname: "Jul 2019" },
      { month: "Aug", selected: false, id: "819", fullname: "Aug 2019" },
      { month: "Sep", selected: false, id: "919", fullname: "Sep 2019" },
      { month: "Oct", selected: false, id: "1019", fullname: "Oct 2019" },
      { month: "Nov", selected: false, id: "1119", fullname: "Nov 2019" },
      { month: "Dec", selected: false, id: "1219", fullname: "Dec 2019" },
      { month: "Dec", selected: false, id: "120", fullname: "Jan 2020" },
      { month: "Feb", selected: false, id: "220", fullname: "Feb 2020" },
      { month: "Mar", selected: false, id: "320", fullname: "Mar 2020" },
      { month: "Apr", selected: false, id: "420", fullname: "Apr 2020" },
      { month: "May", selected: false, id: "520", fullname: "May 2020" },
      { month: "Jun", selected: false, id: "620", fullname: "Jun 2020" },
      { month: "Jul", selected: false, id: "720", fullname: "Jul 2020" },
      { month: "Aug", selected: false, id: "820", fullname: "Aug 2020" },
      { month: "Sep", selected: false, id: "920", fullname: "Sep 2020" },
      { month: "Oct", selected: false, id: "1020", fullname: "Oct 2020" },
      { month: "Nov", selected: false, id: "1120", fullname: "Nov 2020" },
      { month: "Dec", selected: false, id: "1220", fullname: "Dec 2020" },
    ],
    selected_date_range: null,
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };
  handleUpgradeClick = (text) => {
    const config = { headers: { "Content-Type": "application/json" } };
    var plan_name;
    if (DEFAULTPLAN == "Free") {
      var resources = localStorage.getItem("resources");
      resources = JSON.parse(resources);
      var free_id = process.env.REACT_APP_FREE_PLAN;
      plan_name = resources.filter((x) => {
        if (x.rid == free_id) {
          return x;
        }
      });
    } else if (DEFAULTPLAN == "Month") {
      var resources = localStorage.getItem("resources");
      resources = JSON.parse(resources);
      var mtm_id = process.env.REACT_APP_MTM_PLAN;
      plan_name = resources.filter((x) => {
        if (x.rid == mtm_id) {
          return x;
        }
      });
    } else {
      var resources = localStorage.getItem("resources");
      resources = JSON.parse(resources);
      var ann_id = process.env.REACT_APP_ANN_PLAN;
      var etp_id = process.env.REACT_APP_ETP_PLAN;
      plan_name = resources.filter((x) => {
        if (x.rid == ann_id || x.rid == etp_id) {
          return x;
        }
      });
    }
    //console.log(plan_name,"pn")
    //var plan  = DEFAULTPLAN=='Free'?'Starter':DEFAULTPLAN=='Month'?'Flexible':'Premium'
    const data = JSON.stringify({
      identity: 14,
      link: text,
      plan_type: plan_name[0].name,
    });
    axios
      .post("/record", data, config)
      .then(() => {})
      .catch((error) => console.log(error, "analytics error"));
  };

  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.applySearch();
    }
  };

  applySearch = () => {
    var search = (" " + this.state.search).slice(1);
    this.setState({ old_search: search });
    var location = null;
    if (this.state.location.length > 0) {
      var countries = this.state.location.filter((x) => {
        return LocationList.find((c) => c.country == x);
      });
      var states = this.state.location.filter((x) => {
        return LocationList.find((c) => c.country == x);
      });
      states = [];

      location = { state: states, country: countries };
    }
    var obj = null;
    if (this.state.selected_date_range) {
      var arr = this.state.selected_date_range.split("-");

      arr[0] = arr[0].trim();
      arr[1] = arr[1].trim();
      //console.log(arr,"a")
      obj = {};
      obj.start = arr[0];
      obj.end = arr[1];
    }
    this.props.action(
      this.state.filters_app.industry,
      obj,
      location,
      search,
      this.state.range_type,
      "applied"
    );
  };

  // closeSearch = () => {
  // 	this.
  // }
  getUpgradeMsg = () => {
    const { industry_filter, range_filter, location_filter } = this.state;
    if (industry_filter)
      return (
        <Header
          style={{ marginLeft: "5px" }}
          className="error-header"
          as="h5"
          inverted
        >
          {" "}
          <a
            href="http://www.thefr.com/event-analyzer"
            target="_blank"
            onClick={() => this.handleUpgradeClick("Upgrade Industry")}
          >
            Upgrade{" "}
          </a>
          to All Access to select specific industries.
        </Header>
      );
    else if (range_filter)
      return (
        <Header
          style={{ marginLeft: "5px" }}
          className="error-header"
          as="h5"
          inverted
        >
          {" "}
          <a
            href="http://www.thefr.com/event-analyzer"
            target="_blank"
            onClick={() => this.handleUpgradeClick("Upgrade Date")}
          >
            Upgrade{" "}
          </a>
          to All Access to select a custom date range.
        </Header>
      );
    else
      return (
        <Header
          style={{ marginLeft: "5px" }}
          className="error-header"
          as="h5"
          inverted
        >
          {" "}
          <a
            href="http://www.thefr.com/event-analyzer"
            target="_blank"
            onClick={() => this.handleUpgradeClick("Upgrade Location")}
          >
            Upgrade{" "}
          </a>
          to All Access to select specific countries.
        </Header>
      );
  };
  openfilter = (name) => (event) => {
    this.saveFilter();

    this.setState({ [name]: !this.state[name] });
    if (name == "industry_filter") {
      //this.setState({selected_date_range:this.state.old})
      // if(this.state.old_filter){
      // 	var old = JSON.parse(JSON.stringify(this.state.old_filter));
      // 	//console.log(this.state.old_filter)
      // 	this.setState({date_filter:old})
      // }
      // else{
      // 	var date_filter= [...this.state.date_filter];
      // 	date_filter.map(obj => {
      // 		obj.selected=false
      // 	})
      // 	this.setState({date_filter:date_filter})
      // }
      // if(JSON.stringify(this.state.old_location)!=JSON.stringify(this.state.location)){
      // 		//console.log(this.state.old_countries,this.state.old_location,this.state.old_states,"old")
      // 		var old = JSON.parse(JSON.stringify(this.state.old_location));
      // 		//console.log(this.state.old_countries,this.state.old_states,"old 1")
      // 		if(this.state.old_countries){
      // 			var old_countries = JSON.parse(JSON.stringify(this.state.old_countries));
      // 				this.setState({countries:old_countries})					}
      // 			else{
      // 				//console.log('here',this.state.countries)
      // 				var countries = JSON.parse(JSON.stringify(this.state.countries))
      // 				 countries.map(obj => {
      // 				//	console.log(obj,'1')
      // 					obj.selected=false;
      // 				})
      // 				//console.log(old_countries,"o")
      // 				this.setState({countries:countries})
      // 			}
      // 			if(this.state.old_states){
      // 			var old_states = JSON.parse(JSON.stringify(this.state.old_states));
      // 				this.setState({us_states:old_states})					}
      // 			else{
      // 				var us_states = JSON.parse(JSON.stringify(this.state.us_states))
      // 				us_states.map(obj => {
      // 					obj.selected=false;
      // 				})
      // 				this.setState({us_states:us_states})
      // 			}
      // 			//console.log(old_states,old,old_countries,"apply old")
      // 			this.setState({location:old})
      // 	}
      this.setState({ range_filter: false, location_filter: false });
    } else if (name == "range_filter") {
      this.setState({ industry_filter: false, location_filter: false });
      if (this.state.selected_date_range) {
        var arr = this.state.selected_date_range.split("-");
        var date_filter = [...this.state.date_filter];
        date_filter.map((x) => {
          x.selected = false;
        });
        arr[0] = arr[0].trim();
        arr[1] = arr[1].trim();
        var year1 = arr[0].substr(arr[0].length - 4, arr[0].length);
        var year2 = arr[1].substr(arr[1].length - 4, arr[1].length);
        if (year1 == year2 && year1 == "2018") {
          year2 = parseInt(year1) + 1;
          arr[0] = arr[0].substr(0, arr[0].length - 4) + "2018";
          arr[1] = arr[1].substr(0, arr[1].length - 4) + "2018";
        } else if (year1 == year2 && year1 != "2018") {
          year1 = parseInt(year1) - 1;
          arr[0] = arr[0].substr(0, arr[0].length - 4) + "2019";
          arr[1] = arr[1].substr(0, arr[1].length - 4) + "2019";
        } else {
          arr[0] = arr[0].substr(0, arr[0].length - 4) + "2018";
          arr[1] = arr[1].substr(0, arr[1].length - 4) + "2019";
        }

        var startid = date_filter.findIndex((x) => x.fullname === arr[0]);
        var endid = date_filter.findIndex((x) => x.fullname === arr[1]);

        if ((startid && endid) || (startid == 0 && endid)) {
          for (var i = startid; i <= endid; i++) {
            date_filter[i].selected = true;
          }
        }
        this.setState({ year1: year1, year2: year2, date_filter: date_filter });
      }
    } else {
      //this.setState({selected_date_range:this.state.old})
      // 	if(this.state.old_filter){
      // 		var old = JSON.parse(JSON.stringify(this.state.old_filter));
      // //			console.log(this.state.old_filter)
      // 		this.setState({date_filter:old})
      // 	}
      // 	else{
      // 		var date_filter= [...this.state.date_filter];
      // 		date_filter.map(obj => {
      // 			obj.selected=false
      // 		})
      // 		this.setState({date_filter:date_filter})
      // 	}
      //var old_location = JSON.parse(JSON.stringify(this.state.location))
      //this.setState({old_location:old_location})
      this.setState({ range_filter: false, industry_filter: false });
      //console.log(this.state.location_filter,"loc")
      // if(this.state.location_filter){
      // //	console.log(JSON.stringify(this.state.old_location)!=JSON.stringify(this.state.location),this.state.old_location,this.state.location,"checking")
      // 	if(JSON.stringify(this.state.old_location)!=JSON.stringify(this.state.location)){
      // //		console.log(this.state.old_countries,this.state.old_location,this.state.old_states,"old")
      // 		var old = JSON.parse(JSON.stringify(this.state.old_location));
      // //		console.log(this.state.old_countries,this.state.old_states,"old 1")
      // 		if(this.state.old_countries){
      // 			var old_countries = JSON.parse(JSON.stringify(this.state.old_countries));
      // 				this.setState({countries:old_countries})					}
      // 			else{
      // //				console.log('here',this.state.countries)
      // 				var countries = JSON.parse(JSON.stringify(this.state.countries))
      // 				 countries.map(obj => {
      // 					//console.log(obj,'1')
      // 					obj.selected=false;
      // 				})
      // //				console.log(old_countries,"o")
      // 				this.setState({countries:countries})
      // 			}
      // 			if(this.state.old_states){
      // 			var old_states = JSON.parse(JSON.stringify(this.state.old_states));
      // 				this.setState({us_states:old_states})					}
      // 			else{
      // 				var us_states = JSON.parse(JSON.stringify(this.state.us_states))
      // 				us_states.map(obj => {
      // 					obj.selected=false;
      // 				})
      // 				this.setState({us_states:us_states})
      // 			}
      // //			console.log(old_states,old,old_countries,"apply old")
      // 			this.setState({location:old})
      // 	}
      // }
    }
  };

  saveFilter = () => {
    var filters = {};
    if (this.state.selected_date_range) {
      var arr = this.state.selected_date_range.split("-");

      arr[0] = arr[0].trim();
      arr[1] = arr[1].trim();
      //	console.log(arr,"a")
      var obj = {};
      obj.start = arr[0];
      obj.end = arr[1];
      filters.date = obj;
    }
    if (this.state.location.length > 0) {
      var countries = [...this.state.location];
      var states = this.state.location.filter((x) => {
        return x != "United States" && x != "United Kingdom" && x != "Canada";
      });
      states = [];
      var location = { state: states, country: countries };
      filters.location = location;
      // console.log(this.state.location,"location");
    } else {
      var countries = [...this.state.countries];
      var countryloc = [];
      countries.map((obj) => {
        if (obj.country === "United States" || obj.country === "Virtual") {
          obj.selected = true;
        } else {
          obj.selected = false;
        }
        if (obj.selected) countryloc.push(obj.country);
      });
      filters.location = { state: [], country: countryloc };
      this.setState({ location: countryloc });
    }
    if (
      this.state.filters_app.industry &&
      this.state.filters_app.industry.length > 0
    ) {
      filters.industry = JSON.parse(
        JSON.stringify(this.state.filters_app.industry)
      );
    }

    //console.log('calling')
    // var filters = {};
    // if(this.state.filters_app.industry.length>0){
    // 	filters.industry=this.state.filters_app.industry
    // }
    // filters.date
    if (filters.industry) {
      filters.industry = filters.industry
        .filter((x) => {
          if (x.selected) {
            return x.id;
          }
        })
        .map((obj) => obj.id);
    }
    if (this.state.range_type && !this.state.selected_date_range) {
      filters.range_type = this.state.range_type;
    }

    // console.log(filters,"fff")
    axios
      .post("save_events_filters", filters)
      .then((saved) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  setfilter = (filters) => {
    //console.log(filters,"fertyuiop")
    // filters.map(obj => {
    // 	console.log(obj,"o")
    // })
    var pFree1 =
      JSON.parse(localStorage.getItem("user_details")).plan === "Free1";
    var selected = filters.filter((obj) => {
      //console.log(obj,"obj");
      return obj.selected == true || pFree1;
    });
    //console.log(selected,"s");
    var filters_app = { ...this.state.filters_app };
    filters_app.industry = selected;
    this.setState({ filters_app: filters_app, industries: filters });
    //this.fetchSavedFilters();
  };

  componentDidMount() {
    //console.log('called 1 ')
    var user = JSON.parse(localStorage.user_details);
    this.setState({
      year1: new Date().getFullYear(),
      year2: new Date().getFullYear() + 1,
    });
    var range = [];
    //console.log(new Date().getFullYear()+1)
    for (var i = 2018; i <= new Date().getFullYear() + 1; i++) {
      //console.log(i,'i')
      range.push(i);
    }
    this.setState({ plan: user.plan, year_range: range });
    if (user.plan == "Free") {
      this.setState({ maxind: process.env.REACT_APP_FREE_IND });
    }
    if (user.plan == "Month") {
      this.setState({ maxind: process.env.REACT_APP_MTM_IND });
    }
    if (user.plan == "Annual") {
      this.setState({ maxind: process.env.REACT_APP_ANN_IND });
    }
    if (window.innerWidth < 768) {
      this.fetchSavedFilters();
      this.setState({ opensearch: true });
    } else {
      this.fetchSavedFilters();
      this.setState({ opensearch: false });
    }

    this.fetchSavedFilters();
  }

  fetchOldFilters = (ind) => {
    //console.log('called 3')
    var user = JSON.parse(localStorage.user_details);
    axios
      .get("fetch_events_filters")
      .then((data) => {
        if (data.data) {
          var selected = null;
          var range = null;
          var date_filter = null;
          var location = null;
          var search = null;
          if (data.data.industry) {
            if (ind.data.length > 0) {
              //	onsole.log(data.data.industries,"ind1")
              var industries = JSON.parse(JSON.stringify(ind.data));
              //console.log(industries,"i")
              // industries.map(obj => {
              // 	obj.selected = false;
              // })
              industries.map((obj) => {
                data.data.industry.map((x) => {
                  if (x == obj.id) {
                    obj.selected = true;
                  }
                });
              });
              //console.log(industries,"ind")
              this.setfilter(industries);
              selected = industries.filter((obj) => {
                return obj.selected == true;
              });
              //this.setState({industries:ind.data});
            }

            //his.setState({old:})
          } else {
            var industries = JSON.parse(JSON.stringify(ind.data));
            // industries.map(obj => {
            // 	obj.selected = false;
            // })
            industries.map((obj) => {
              obj.selected = true;
            });
            //console.log(industries,"ind")
            this.setfilter(industries);
            selected = industries.filter((obj) => {
              return obj.selected == true;
            });
          }

          if (data.data.date) {
            if (data.data.date.start && data.data.date.end) {
              range = data.data.date.start + " - " + data.data.date.end;
              var date_filter = JSON.parse(
                JSON.stringify(this.state.date_filter)
              );
              var startid = null,
                endid = null;
              var start_year = data.data.date.start.substr(
                data.data.date.start.length - 4,
                data.data.date.start.length
              );
              var end_year = data.data.date.end.substr(
                data.data.date.end.length - 4,
                data.data.date.end.length
              );
              if (start_year == end_year && start_year == "2018") {
                end_year = parseInt(start_year) + 1;
                data.data.date.start =
                  data.data.date.start.substr(
                    0,
                    data.data.date.start.length - 4
                  ) + "2018";
                data.data.date.end =
                  data.data.date.end.substr(0, data.data.date.end.length - 4) +
                  "2018";
              } else if (start_year == end_year && start_year != "2018") {
                start_year = parseInt(start_year) - 1;
                data.data.date.start =
                  data.data.date.start.substr(
                    0,
                    data.data.date.start.length - 4
                  ) + "2019";
                data.data.date.end =
                  data.data.date.end.substr(0, data.data.date.end.length - 4) +
                  "2019";
              } else {
                data.data.date.start =
                  data.data.date.start.substr(
                    0,
                    data.data.date.start.length - 4
                  ) + "2018";
                data.data.date.end =
                  data.data.date.end.substr(0, data.data.date.end.length - 4) +
                  "2019";
              }

              startid = date_filter.findIndex(
                (x) => x.fullname === data.data.date.start
              );
              endid = date_filter.findIndex(
                (x) => x.fullname === data.data.date.end
              );

              if ((startid && endid) || (startid == 0 && endid)) {
                for (var i = startid; i <= endid; i++) {
                  date_filter[i].selected = true;
                }
              }
              this.setState({
                old: range,
                year1: start_year,
                year2: end_year,
                old_filter: JSON.parse(JSON.stringify(date_filter)),
                date_filter: date_filter,
                selected_date_range: range,
                range_type: null,
              });
            }
          }
          var obj = null;
          if (data.data.location) {
            //var location = [];
            var countries = [];
            var states = [];
            location = [];
            if (data.data.location.state) {
              data.data.location.state.map((obj) => {
                location.push(obj);
              });
              states = [...this.state.us_states];
              states.map((obj) => {
                data.data.location.state.map((x) => {
                  if (obj.state == x) {
                    obj.selected = true;
                  }
                });
              });
            }
            if (data.data.location.country) {
              data.data.location.country.map((obj) => {
                location.push(obj);
              });
              countries = [...this.state.countries];
              countries.map((obj) => {
                data.data.location.country.map((x) => {
                  if (obj.country == x) {
                    obj.selected = true;
                  }
                });
              });
            }
            if (states.length > 0) {
              this.setState({ us_states: states, old_states: states });
            }
            if (countries.length > 0) {
              this.setState({ countries: countries, old_countries: countries });
            }
            this.setState({ location: location, old_location: location });
          } else {
            var location = {};
            if (typeof this.state.countries[0] == "string") {
              this.state.countries = this.state.countries.map((c) => {
                if (c.country === "United States" || c.country === "Virtual") {
                  c.selected = true;
                } else {
                  c.selected = false;
                }
                return c;
              });
            } else {
              location.country = this.state.countries.map((c) => {
                if (c.country === "United States" || c.country === "Virtual") {
                  c.selected = true;
                } else {
                  c.selected = false;
                }
                if (c.selected) return c.country;
              });
            }
            location.state = [];
            this.state.location = location.country;
          }
          if (data.data.range_type && !data.data.date) {
            this.setState({ range_type: data.data.range_type });
          } else {
            this.state.range_type = null;
            var { date_filter } = this.state;
            var year = new Date().getFullYear();
            var month = new Date().getMonth();
            if (year == "2020") {
              month = month + 12;
            }

            // for(var i=month;i<month+6;i++) {
            //               date_filter[i].selected=true;
            // }
            //  selected_date_range = `${date_filter[month].month} ${year} - ${date_filter[month+5].month} ${year}`;
            //  this.state.selected_date_range=selected_date_range;
            // this.setState({selected_date_range,range_type:null});
            obj = {};
            obj.start = `${date_filter[month].month} ${year}`;
            obj.end = `${date_filter[month + 5].month} ${year}`;
          }
          if (data.data.search) {
            search = data.data.search;
            this.setState({
              old_search: search,
              search: search,
              opensearch: true,
            });
          }

          if (range) {
            var arr = range.split("-");

            arr[0] = arr[0].trim();
            arr[1] = arr[1].trim();
            obj = {};
            obj.start = arr[0];
            obj.end = arr[1];
          }
          if (location && location.length > 0) {
            // var countries = location.filter(x => {
            // 	return x=='United States' || x == 'United Kingdom' || x=='Canada'
            // })
            // var states =location.filter(x => {
            // 	return x!='United States' && x != 'United Kingdom' && x!='Canada'
            // })
            var countries = [...location];
            var states = [];
            location = { state: states, country: countries };
          }
          // if(user.plan=='Free1') {
          // 	location={};
          // 	if(typeof this.state.countries[0]!=='string'){
          // 	 location.country=this.state.countries.map(c=>{c.selected=true; return c.country});
          // 	}

          // 	var {date_filter} = this.state;
          // 	var year = new Date().getFullYear();
          // 	var month = new Date().getMonth();
          // 	if(year=='2020'){
          // 		month=month+12;
          // 	}

          // 	for(var i=month;i<month+6;i++) {
          //                date_filter[i].selected=true;
          // 	}
          // 	var selected_date_range = `${date_filter[month].month} ${year} - ${date_filter[month+5].month} ${year}`;

          //            obj={};
          //                 obj.start =  `${date_filter[month].month} ${year}`;
          //                 obj.end =  `${date_filter[month+5].month} ${year}`;
          // 	var industries = JSON.parse(JSON.stringify(ind.data))
          // 	this.setfilter(industries);
          // 	var selected = industries

          // }
          // if(user.plan=='Free1'){
          // 	this.state.range_type=null;
          // 	this.setState({selected_date_range:selected_date_range})
          // 	// this.selected_date_range=`${obj.start} - ${obj.end}`;
          // }
          this.props.action(
            selected,
            obj,
            location,
            search,
            this.state.range_type,
            "first time"
          );
        } else {
          if (data.data.industry) {
            if (ind.data.length > 0) {
              var industries = JSON.parse(JSON.stringify(ind.data));
              industries.map((obj) => {
                data.data.industry.map((x) => {
                  if (x == obj.id) {
                    obj.selected = true;
                  }
                });
              });
              this.setfilter(industries);
              selected = industries.filter((obj) => {
                return obj.selected == true;
              });
            }
          }

          var search = null;
          var location = {};
          location.country = this.state.countries.map((c) => {
            if (c.country === "United States" || c.country === "Virtual") {
              c.selected = true;
            } else {
              c.selected = false;
            }
            // c.selected = false;
            return c.country;
          });

          var { date_filter } = this.state;
          var year = new Date().getFullYear();
          var month = new Date().getMonth();
          if (year == "2020") {
            month = month + 12;
          }

          for (var i = month; i < month + 6; i++) {
            date_filter[i].selected = true;
          }
          var selected_date_range = `${date_filter[month].month} ${year} - ${
            date_filter[month + 5].month
          } ${year}`;

          // obj={};
          //      obj.start =  `${date_filter[month].month} ${year}`;
          //      obj.end =  `${date_filter[month+5].month} ${year}`;
          obj = null;
          var industries = JSON.parse(JSON.stringify(ind.data));
          this.setfilter(industries);
          var selected = industries;
          this.state.range_type = "Upcoming"; // DEFAULT SELECTION (show in header);
          this.state.range_type = "upcoming"; // DEFAULT SELECTION (show in in upcoming/past filter);
          this.state.selected_date_range = null;
          this.state.date_filter.map((d) => {
            d.selected = false;
          });
          // Default selection
          this.state.location = ["United States", "Virtual"];
          //this.state.location = this.state.countries.map((c) => c.country);
          // this.setState({selected_date_range:selected_date_range})
          this.props.action(
            selected,
            obj,
            location,
            search,
            this.state.range_type,
            "first time"
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchSavedFilters = () => {
    var pFree1 =
      JSON.parse(localStorage.getItem("user_details")).plan === "Free1";

    const startTime = performance.now();
    axios
      .get("fetch_industries")
      .then((ind) => {
        if (ind.data.length > 0) {
          for (var i = 0; i < ind.data.length; i++) {
            if (pFree1) {
              ind.data[i].selected = true;
            } else {
              ind.data[i].selected = false;
            }
          }
          if (this.props.location.search) {
            let params = new URLSearchParams(this.props.location.search);
            var filter = params.get("filter");
            if (filter) {
              if (ind.data.length > 0) {
                //	onsole.log(data.data.industries,"ind1")
                var industries = JSON.parse(JSON.stringify(ind.data));
                industries.map((obj) => {
                  if (obj.industry == filter) {
                    obj.selected = true;
                  }
                });
                var selected = industries.filter((obj) => {
                  return obj.selected == true || pFree1;
                });
                if (selected.length > 0) {
                  this.setfilter(industries);
                  this.props.action(
                    selected,
                    null,
                    null,
                    null,
                    "upcoming",
                    "first time"
                  );
                } else {
                  this.fetchOldFilters(ind);
                }
              }
            }
          } else {
            this.fetchOldFilters(ind);
          }
          const duration = performance.now() - startTime;
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          localStorage.removeItem("user_details");
          this.props.history.push("/");
        }
      });
  };

  componentDidUpdate(prevProps) {
    //console.log(prevProps,JSON.stringify(this.props.filters)!=JSON.stringify(prevProps.filters),"prev")
    // Typical usage (don't forget to compare props):
    if (this.props.filters) {
      if (
        JSON.stringify(this.props.filters) != JSON.stringify(prevProps.filters)
      ) {
        this.setfilter(this.props.filters);
      }
    }
  }
  handleRange = (id) => (event) => {
    this.setState({ range_type: null });
    if (this.state.selected_date.length == 1) {
      var selected = [...this.state.selected_date];
      selected.push(id);
      this.setState({ selected_date: selected });
      var index1 = null;
      var index2 = null;
      selected.map((obj, index) => {
        var data = this.state.date_filter.findIndex((x) => x.id == obj);
        if (index1 == null) {
          index1 = data;
        } else if (index2 == null) {
          index2 = data;
        }
      });
      if (index1 < index2) {
        var selected = [...this.state.date_filter];
        var first_month = null;
        var last_month = null;
        selected.map((obj, index) => {
          if (index1 <= index && index <= index2) {
            obj.selected = true;
          }
        });
        this.setState({ date_filter: selected }, () => {});
        var year = selected[index1].id.substr(selected[index1].id.length - 2);
        if (year == "18") {
          year = this.state.year1;
        } else {
          year = this.state.year2;
        }
        var year2 = selected[index2].id.substr(selected[index2].id.length - 2);
        if (year2 == "18") {
          year2 = this.state.year1;
        } else {
          year2 = this.state.year2;
        }
        // var year = this.state.year1;
        // var year2 = this.state.year2;
        first_month = selected[index1].month + " " + year;
        last_month = selected[index2].month + " " + year2;
        var range = first_month + " - " + last_month;
        this.setState({ selected_date_range: range });
      } else {
        var selected = [...this.state.date_filter];
        selected.map((obj, index) => {
          if (index2 <= index && index <= index1) {
            obj.selected = true;
          }
        });
        this.setState({ date_filter: selected }, () => {});
        var year = selected[index1].id.substr(selected[index1].id.length - 2);
        if (year == "18") {
          year = this.state.year1;
        } else {
          year = this.state.year2;
        }
        var year2 = selected[index2].id.substr(selected[index2].id.length - 2);

        if (year2 == "18") {
          year2 = this.state.year1;
        } else {
          year2 = this.state.year2;
        }
        // var year = this.state.year2;
        // var year2 = this.state.year1;
        first_month = selected[index2].month + " " + year2;
        last_month = selected[index1].month + " " + year;
        var range = first_month + " - " + last_month;
        this.setState({ selected_date_range: range });
      }
    } else if (this.state.selected_date.length == 2) {
      var selected = [];
      selected.push(id);
      this.setState({ selected_date: selected, selected_date_range: null });
      var selected = [...this.state.date_filter];
      selected.map((obj, index) => {
        if (obj.id == id) {
          obj.selected = true;
        } else {
          obj.selected = false;
        }
      });
      this.setState({ date_filter: selected });
    } else {
      var selected = [...this.state.selected_date];
      selected.push(id);
      this.setState({ selected_date: selected });
      var selected_month = [...this.state.date_filter];
      selected_month.map((obj, index) => {
        if (obj.id == id) {
          obj.selected = true;
        } else {
          obj.selected = false;
        }
      });
      this.setState({ date_filter: selected_month });
    }
  };

  handleCheckbox = (obj) => (event, data) => {
    var filters = [...this.state.industries];
    var role = JSON.parse(localStorage.getItem("user_details")).role;

    // if(this.state.filters_app.industry.length==this.state.maxind && role!=='admin'){
    if (obj.selected == true) {
      filters.map((obj1) => {
        if (obj1.id == obj.id) {
          obj1.selected = false;
        }
      });
      this.setfilter(filters);
    } else {
      filters.map((obj1) => {
        if (obj1.id == obj.id) {
          obj1.selected = true;
        }
      });
      this.setfilter(filters);
    }
    // else{
    // 	// If we need to not show error and just switch industry use the commented portion
    // 	// console.log(this.state.filters_app,"f")
    // 	// var filters_app = {...this.state.filters_app};
    // 	// var id = filters_app.industry[filters_app.industry.length-1].id;
    // 	// console.log(id,"id");
    // 	// filters_app.industry.splice(-1,1);
    // 	// this.setState({filters_app:filters_app});
    // 	// //this.state.filters_app.industry.splice(-1,1);
    // 	// filters.map(obj1 => {
    // 	// 	if(obj1.id == obj.id){
    // 	// 		obj1.selected=true;

    // 	// 	}
    // 	// 	if(obj1.id == id){
    // 	// 		obj1.selected =false;
    // 	// 	}
    // 	// })
    // 	// //console.log(this.props,"p")
    // 	// this.setfilter(filters);
    // 	this.setState({maxindustries:true});
    // 	var that = this;
    // 	setTimeout(function(){that.setState({maxindustries:false})},5000)
    // }
    // } End of check if admin or not
    // else{
    // 	if(obj.selected==true){
    // 		filters.map(obj1 => {
    // 			if(obj1.id == obj.id){
    // 				obj1.selected=false;

    // 			}
    // 		})
    // 		//console.log(this.props,"p")
    // 		this.setfilter(filters);
    // 		//filters.push({changed:true});
    // 		// this.props.action(filters);
    // 	}
    // 	else{
    // 		//console.log(obj,"o")
    // 		filters.map(obj1 => {
    // 			if(obj1.id == obj.id){
    // 				obj1.selected=true;

    // 			}
    // 		})
    // 		//console.log(this.props,"p")
    // 		this.setfilter(filters);
    // 			//filters.push({changed:true});
    // 		// this.props.action(filters);

    // 	}
    // }
  };

  applyIndustry = () => {
    if (this.state.filters_app.industry) {
      var obj = null;
      var location = null;
      if (this.state.selected_date_range) {
        var arr = this.state.selected_date_range.split("-");

        arr[0] = arr[0].trim();
        arr[1] = arr[1].trim();
        obj = {};
        obj.start = arr[0];
        obj.end = arr[1];
      }
      if (this.state.location.length > 0) {
        var countries = this.state.location.filter((x) => {
          // return x=='United States' || x == 'United Kingdom' || x=='Canada'
          return LocationList.find((c) => c.country == x);
        });
        var states = this.state.location.filter((x) => {
          // return x!='United States' && x != 'United Kingdom' && x!='Canada'
          return LocationList.find((c) => c.country == x);
        });
        states = [];
        location = { state: states, country: countries };
      }
      this.props.action(
        this.state.filters_app.industry,
        obj,
        location,
        this.state.search,
        this.state.range_type,
        "applied"
      );
      this.setState({ industry_filter: false });
      this.saveFilter();
    }
  };

  applyDate = () => {
    if (this.state.selected_date_range) {
      var arr = this.state.selected_date_range.split("-");

      arr[0] = arr[0].trim();
      arr[1] = arr[1].trim();
      var obj = {};
      obj.start = arr[0];
      obj.end = arr[1];
      var dates = JSON.parse(JSON.stringify(this.state.date_filter));
      this.setState({ old: this.state.selected_date_range, old_filter: dates });
      var location = null;
      if (this.state.location.length > 0) {
        var countries = this.state.location.filter((x) => {
          return LocationList.find((c) => c.country == x);
        });
        var states = this.state.location.filter((x) => {
          return LocationList.find((c) => c.country == x);
        });
        states = [];

        location = { state: states, country: countries };
      }
      this.props.action(
        this.state.filters_app.industry,
        obj,
        location,
        this.state.search,
        this.state.range_type,
        "applied"
      );
      this.setState({ range_filter: false });
      this.saveFilter();
    }
    if (this.state.range_type) {
      var location = null;
      if (this.state.location.length > 0) {
        var countries = this.state.location.filter((x) => {
          return LocationList.find((c) => c.country == x);
        });
        var states = this.state.location.filter((x) => {
          return LocationList.find((c) => c.country == x);
        });
        states = [];

        location = { state: states, country: countries };
      }
      this.props.action(
        this.state.filters_app.industry,
        null,
        location,
        this.state.search,
        this.state.range_type,
        "applied"
      );
      this.setState({ range_filter: false });
      this.saveFilter();
    }
  };

  applyState = (obj) => (event, data) => {
    Array.prototype.remove = function () {
      var what,
        a = arguments,
        L = a.length,
        ax;
      while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
          this.splice(ax, 1);
        }
      }
      return this;
    };
    var us_states = [...this.state.us_states];
    //var selected_states= [...this.state.states]
    var location = [...this.state.location];
    if (obj.selected) {
      us_states.map((obj1) => {
        if (obj1.state == obj.state) {
          obj1.selected = false;
        }
      });
      //selected_states.remove(obj.state);
      location.remove(obj.state);

      //selected_states.splice(index,1);
    } else {
      us_states.map((obj1) => {
        if (obj1.state == obj.state) {
          obj1.selected = true;
        }
      });
      //selected_states.push(obj.state);
      location.push(obj.state);
    }
    this.setState({ location: location, us_states: us_states });
    // var old_states = JSON.parse(JSON.stringify(us_states));
    // var location = JSON.parse(JSON.stringify(this.state.location));
    // this.setState({old_states:old_states,old_location:location})
  };
  applyCountry = (obj) => (event, data) => {
    var pFree1 =
      JSON.parse(localStorage.getItem("user_details")).plan === "Free1";
    if (!pFree1) {
      Array.prototype.remove = function () {
        var what,
          a = arguments,
          L = a.length,
          ax;
        while (L && this.length) {
          what = a[--L];
          while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
          }
        }
        return this;
      };
      var countries = [...this.state.countries];
      //var selected_countries= [...this.state.selected_countries]
      var location = [...this.state.location];
      if (obj.selected) {
        countries.map((obj1) => {
          if (obj1.country == obj.country) {
            obj1.selected = false;
          }
        });
        //selected_countries.remove(obj.country);
        location.remove(obj.country);

        //selected_states.splice(index,1);
      } else {
        countries.map((obj1) => {
          if (obj1.country == obj.country) {
            obj1.selected = true;
          }
        });
        //selected_countries.push(obj.country);
        location.push(obj.country);
      }
      this.setState({ location: location, countries: countries });
      // var old_states = JSON.parse(JSON.stringify(countries));
      // var location = JSON.parse(JSON.stringify(location));
      // this.setState({old_countries:old_states,old_location:location})
    }
  };

  applyLocation = () => {
    var user = JSON.parse(localStorage.user_details);
    if (this.state.location.length > 0 || user.plan === "Free1") {
      var countries = this.state.location.filter((x) => {
        return LocationList.find((c) => c.country == x);
      });
      var states = this.state.location.filter((x) => {
        return LocationList.find((c) => c.country == x);
      });
      states = [];
      var location = { state: states, country: countries };
      var old_location = JSON.parse(JSON.stringify(this.state.location));
      var old_countries = JSON.parse(JSON.stringify(this.state.countries));
      var old_states = JSON.parse(JSON.stringify(this.state.us_states));
      this.setState({
        old_countries: old_countries,
        old_location: old_location,
        old_states: old_states,
      });
      var obj = null;
      if (this.state.selected_date_range) {
        var arr = this.state.selected_date_range.split("-");

        arr[0] = arr[0].trim();
        arr[1] = arr[1].trim();
        obj = {};
        obj.start = arr[0];
        obj.end = arr[1];
      }
      this.props.action(
        this.state.filters_app.industry,
        obj,
        location,
        this.state.search,
        this.state.range_type,
        "applied"
      );
      this.setState({ location_filter: false });
      this.saveFilter();
    }
  };

  resetIndustry = () => {
    var filters = { ...this.state.filters_app };
    filters.industry = [];
    var industries = [...this.state.industries];
    // industries.map(obj =>{
    // 	obj.selected = false;
    // })
    industries.map((obj) => {
      obj.selected = true;
    });
    this.setState({ filters_app: filters, industries: industries }, () => {
      this.saveFilter();
    });
    var obj = null;
    var location = null;
    if (this.state.selected_date_range) {
      var arr = this.state.selected_date_range.split("-");

      arr[0] = arr[0].trim();
      arr[1] = arr[1].trim();
      obj = {};
      obj.start = arr[0];
      obj.end = arr[1];
    }
    if (this.state.location.length > 0) {
      // var countries = this.state.location.filter(x => {
      // 	return x=='United States' || x == 'United Kingdom' || x=='Canada'
      // })
      var countries = [...this.state.location];
      // var states =this.state.location.filter(x => {
      // 	return x!='United States' && x != 'United Kingdom' && x!='Canada'
      // })
      var states = [];

      location = { state: states, country: countries };
    }
    // console.log(filters,"filters");
    // this.props.action(null,obj,location,this.state.search,this.state.range_type,'reset')
    this.setState({ industry_filter: false });
    // this.setState({industry_filter:true})
    this.setfilter(industries);
    this.props.action(
      industries,
      obj,
      location,
      this.state.search,
      this.state.range_type,
      "reset"
    );
  };

  resetDate = () => {
    var selected_date_range = this.state.selected_date_range;
    selected_date_range = null;
    var date = this.state.date_filter;
    date.map((obj) => {
      obj.selected = false;
    });
    this.setState(
      {
        selected_date_range: selected_date_range,
        date_filter: date,
        old: null,
        old_filter: null,
        range_type: "upcoming",
      },
      () => {
        this.saveFilter();
      }
    );
    var location = null;
    if (this.state.location.length > 0) {
      var countries = this.state.location.filter((x) => {
        return LocationList.find((c) => c.country == x);
      });
      var states = this.state.location.filter((x) => {
        return LocationList.find((c) => c.country == x);
      });
      states = [];

      location = { state: states, country: countries };
    }
    this.props.action(
      this.state.filters_app.industry,
      null,
      location,
      this.state.search,
      "upcoming",
      "reset"
    );
    this.setState({ range_filter: false });
  };

  resetLocation = () => {
    // var location = [];
    var location = {};
    var old_location = [];
    var old_countries = null;
    var old_states = null;
    var obj = null;
    var us_states = [...this.state.us_states];
    // us_states.map(obj => {
    // 	obj.selected = false;
    // })
    var countryloc = [];
    // console.log(us_states,"states");
    us_states.map((obj) => {
      obj.selected = true;
    });
    var countries = [...this.state.countries];
    // countries.map(obj => {
    // 	obj.selected = false;
    // })
    countries.map((obj) => {
      obj.selected = true;
      countryloc.push(obj.country);
    });
    location = { state: [], country: countryloc };
    // console.log(countries,"countries",location,"locations");
    this.setState(
      {
        old_states: old_states,
        old_countries: old_countries,
        old_location: old_location,
        us_states: us_states,
        countries: countries,
        location: location,
      },
      () => {
        this.saveFilter();
      }
    );
    if (this.state.selected_date_range) {
      var arr = this.state.selected_date_range.split("-");

      arr[0] = arr[0].trim();
      arr[1] = arr[1].trim();
      //console.log(arr,"a")
      obj = {};
      obj.start = arr[0];
      obj.end = arr[1];
    }
    // this.props.action(this.state.filters_app.industry,obj,null,this.state.search,this.state.range_type,'reset')
    // this.setState({location_filter:true})
    this.props.action(
      this.state.filters_app.industry,
      obj,
      location,
      this.state.search,
      this.state.range_type,
      "reset"
    );
    this.setState({ location_filter: false });
  };

  removeSearch = () => {
    // if(JSON.parse(localStorage.getItem('user_details')).plan!=='Free1') {
    // console.log(this.state.opensearch,"opensearch");
    if (this.state.opensearch) {
      this.setState({ opensearch: false, search: "" });
      var location = null;
      var obj = null;
      //console.log(this.state.search,"ss")
      if (this.state.location.length > 0) {
        var countries = this.state.location.filter((x) => {
          return LocationList.find((c) => c.country == x);
        });
        var states = this.state.location.filter((x) => {
          return LocationList.find((c) => c.country == x);
        });
        states = [];

        location = { state: states, country: countries };
      }
      if (this.state.selected_date_range) {
        var arr = this.state.selected_date_range.split("-");

        arr[0] = arr[0].trim();
        arr[1] = arr[1].trim();
        //console.log(arr,"a")
        obj = {};
        obj.start = arr[0];
        obj.end = arr[1];
      }
      this.props.action(
        this.state.filters_app.industry,
        obj,
        location,
        null,
        this.state.range_type,
        "reset"
      );
    } else {
      this.setState({ opensearch: true });
    }
    // }
  };

  pastorupcoming = (type) => (event) => {
    var selected = [...this.state.date_filter];
    selected.map((x) => {
      x.selected = false;
    });
    this.setState({
      range_type: type,
      selected_date: [],
      selected_date_range: null,
      date_filter: selected,
    });
  };

  downloadpdf = () => {
    this.props.pdf();
  };

  downloadfree = () => {
    this.setState({ upgrade: true });
    setTimeout(() => {
      this.setState({ upgrade: false });
    }, 2000);
  };
  downloadFile = () => {
    var plan = DEFAULTPLAN;
    if (plan == "Free" || plan == "Free1" || plan == "Month") {
      this.downloadfree();
    } else {
      this.props.csv();
    }
  };
  changeyearfilter = (year) => (event) => {
    var date_filter = [...this.state.date_filter];
    date_filter.map((x) => {
      x.selected = false;
    });
    this.setState({ year1: year, year2: year + 1, date_filter: date_filter });
  };

  handleClose = () => this.setState({ upgrade: false });

  render() {
    const {
      industry_filter,
      range_filter,
      date_filter,
      selected_date_range,
      location_filter,
      opensearch,
      us_states,
      countries,
      plan,
    } = this.state;
    const y_2018 = date_filter.slice(0, 12);
    const y_2019 = date_filter.slice(12, 24);
    var pFree1 = DEFAULTPLAN === "Free1";
    // console.log(this.state);
    return (
      <Fragment>
        <style>
          {`.ui.grid>.row.w-auto{ width:auto !important;padding-right:10px }
				.right.item >div {
			    display: flex;
			    align-items: center;
			    justify-content: center;
			}
			.msg-container:after,.msg-container:before {
				clear: both;
				display: block;
				content: '';
			}
			.msg-container {
				background: #585858;
				padding-bottom: 11px;
				padding-right: 36px;
			}
			`}
        </style>
        <Menu
          borderless
          size="large"
          secondary
          pointing
          stackable
          className="filter-header"
          style={{ margin: "0 auto" }}
        >
          <Container fluid>
            <Menu.Item>
              <Segment
                color={industry_filter ? "blue" : null}
                inverted={industry_filter ? true : false}
                onClick={this.openfilter("industry_filter")}
                style={{ width: "290px", margin: "0 auto" }}
              >
                <Grid>
                  <Grid.Column width={13} textAlign="center">
                    <Header
                      className="industry_names"
                      as="h5"
                      style={{
                        textTransform: "uppercase",
                        color: industry_filter ? "white" : "#000",
                        fontSize: "12px",
                      }}
                    >
                      {this.state.filters_app.industry &&
                      this.state.filters_app.industry.length > 0 ? (
                        this.state.filters_app.industry.map((obj, index) => {
                          return (
                            <Fragment key={index}>
                              {obj.industry}
                              {index ==
                              this.state.filters_app.industry.length -
                                1 ? null : (
                                <Fragment>, </Fragment>
                              )}
                            </Fragment>
                          );
                        })
                      ) : (
                        <Fragment>Industry</Fragment>
                      )}
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={3} textAlign="center">
                    <Icon name="caret down" />
                  </Grid.Column>
                </Grid>
              </Segment>
            </Menu.Item>

            <Menu.Item>
              {(selected_date_range || this.state.range_type) && (
                <Segment
                  color={range_filter ? "blue" : null}
                  inverted={range_filter ? true : false}
                  onClick={this.openfilter("range_filter")}
                  style={{ width: "290px", margin: "0 auto" }}
                >
                  <Grid>
                    <Grid.Column width={13} textAlign="center">
                      <Header
                        as="h5"
                        style={{
                          textTransform: "uppercase",
                          color: range_filter ? "white" : "#000",
                          fontSize: "12px",
                        }}
                      >
                        {selected_date_range || this.state.range_type}
                        {this.state.range_type ? " Conferences" : ""}
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={3} textAlign="center">
                      <Icon name="caret down" />
                    </Grid.Column>
                  </Grid>
                </Segment>
              )}
              {!selected_date_range && !this.state.range_type && (
                <Segment
                  color={range_filter ? "blue" : null}
                  inverted={range_filter ? true : false}
                  onClick={this.openfilter("range_filter")}
                  style={{ color: "#fff" }}
                  basic
                >
                  DATE RANGE <Icon name="caret down" />
                </Segment>
              )}
            </Menu.Item>
            <Menu.Item>
              {this.state.location && this.state.location.length > 0 && (
                <Segment
                  color={location_filter ? "blue" : null}
                  inverted={location_filter ? true : false}
                  onClick={this.openfilter("location_filter")}
                  style={{ width: "290px", margin: "0 auto" }}
                >
                  <Grid>
                    <Grid.Column width={13} textAlign="center">
                      <Header
                        as="h4"
                        className="industry_names"
                        style={{
                          textTransform: "uppercase",
                          color: location_filter ? "white" : "#000",
                          fontSize: "12px",
                        }}
                      >
                        {this.state.location.map((obj, index) => {
                          return (
                            <span key={index}>
                              {obj}
                              {index ==
                              this.state.location.length - 1 ? null : (
                                <span>, </span>
                              )}{" "}
                            </span>
                          );
                        })}
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={3} textAlign="center">
                      <Icon name="caret down" />
                    </Grid.Column>
                  </Grid>
                </Segment>
              )}
              {this.state.location && this.state.location.length == 0 && (
                <Segment
                  color={location_filter ? "blue" : null}
                  inverted={location_filter ? true : false}
                  onClick={this.openfilter("location_filter")}
                  style={{ color: "#fff" }}
                  basic
                >
                  LOCATION <Icon name="caret down" />
                </Segment>
              )}
            </Menu.Item>
            <Menu.Item position="right">
              <Segment
                className={opensearch ? "no-padding" : "padding-class"}
                style={{ color: "#fff", margin: "0 auto" }}
                basic
              >
                <Input
                  onKeyPress={this.handleKeyPress}
                  size="big"
                  className={opensearch ? "input-open" : "input-closed"}
                  value={this.state.search}
                  onChange={this.handleChange("search")}
                  inverted
                  icon={
                    <Icon
                      name="search"
                      onClick={this.applySearch}
                      circular
                      link
                    />
                  }
                  placeholder="Type Event Name..."
                />{" "}
                &nbsp;
                <Icon
                  style={{ marginRight: !opensearch ? "20px" : "10px" }}
                  onClick={this.removeSearch}
                  size="large"
                  name={opensearch ? "delete" : "search"}
                />
                &nbsp;
                {window.innerWidth > 768 &&
                  (DEFAULTPLAN == "Free" ||
                    DEFAULTPLAN == "Free1" ||
                    DEFAULTPLAN == "Month") && (
                    <div
                      style={{
                        display: "inline",
                        cursor: "pointer",
                        backgroundColor: "#585858",
                      }}
                      className="inverted download-menu"
                    >
                      <span>
                        <Image
                          verticalAlign="middle"
                          src={download}
                          onClick={this.downloadFile}
                        />{" "}
                        {(DEFAULTPLAN == "Free" ||
                          DEFAULTPLAN == "Free1" ||
                          DEFAULTPLAN == "Month") && (
                          <Icon
                            corner="top right"
                            name="lock"
                            style={{ color: "#fff" }}
                          />
                        )}
                      </span>
                    </div>
                  )}
                {window.innerWidth > 768 &&
                  DEFAULTPLAN != "Free" &&
                  DEFAULTPLAN != "Free1" &&
                  DEFAULTPLAN != "Month" &&
                  this.props.list.length > 0 && (
                    <div
                      style={{
                        display: "inline",
                        cursor: "pointer",
                        backgroundColor: "#585858",
                      }}
                      className="inverted download-menu"
                    >
                      <span>
                        <Image
                          verticalAlign="middle"
                          src={download}
                          onClick={this.downloadFile}
                        />
                      </span>
                    </div>
                  )}
                {window.innerWidth < 768 &&
                  (DEFAULTPLAN == "Free" ||
                    DEFAULTPLAN == "Free1" ||
                    DEFAULTPLAN == "Month") && (
                    <div
                      style={{
                        display: "inline",
                        cursor: "pointer",
                        backgroundColor: "#585858",
                      }}
                      className="inverted download-menu"
                    >
                      <span>
                        <Image
                          verticalAlign="middle"
                          src={download}
                          onClick={this.downloadFile}
                        />{" "}
                        {(DEFAULTPLAN == "Free" ||
                          DEFAULTPLAN == "Free1" ||
                          DEFAULTPLAN == "Month") && (
                          <Icon
                            corner="top right"
                            name="lock"
                            style={{ color: "#fff" }}
                          />
                        )}
                      </span>
                    </div>
                  )}
                {window.innerWidth < 768 &&
                  DEFAULTPLAN != "Free" &&
                  DEFAULTPLAN != "Free1" &&
                  DEFAULTPLAN != "Month" && (
                    <div
                      style={{
                        display: "inline",
                        cursor: "pointer",
                        backgroundColor: "#585858",
                      }}
                      className="inverted download-menu"
                    >
                      <span>
                        <Image
                          verticalAlign="middle"
                          src={download}
                          onClick={this.downloadFile}
                        />{" "}
                        {(DEFAULTPLAN == "Free" ||
                          DEFAULTPLAN == "Free1" ||
                          DEFAULTPLAN == "Month") && (
                          <Icon
                            corner="top right"
                            name="lock"
                            style={{ color: "#fff" }}
                          />
                        )}
                      </span>
                    </div>
                  )}
              </Segment>
            </Menu.Item>
          </Container>
        </Menu>
        {this.state.upgrade && (
          <div className="msg-container">
            {" "}
            <Header
              style={{ marginLeft: "5px", float: "right" }}
              className="error-header"
              as="h5"
              inverted
            >
              {" "}
              <a
                href="http://www.thefr.com/event-analyzer"
                target="_blank"
                onClick={() =>
                  this.handleUpgradeClick("download search events")
                }
              >
                Upgrade{" "}
              </a>{" "}
              to All Access to download your search results.
            </Header>{" "}
          </div>
        )}
        <div className="filters">
          {industry_filter && (
            <Transition
              transitionOnMount
              visible={industry_filter}
              duartion={1000}
              animation="fade"
            >
              <Container fluid>
                {this.state.filters_app.industry.length < 1 && (
                  <Header className="filters" style={{ marginLeft: "5px" }}>
                    Please select at least one.
                  </Header>
                )}
                {DEFAULTPLAN == "Free1" && this.getUpgradeMsg()}
                <Grid stackable inverted columns={2}>
                  <Grid.Column width={13}>
                    <Transition
                      unmountOnHide
                      animation="fade"
                      duration={800}
                      visible={
                        this.state.maxindustries && DEFAULTPLAN != "Annual"
                      }
                    >
                      <Header className="error-header" as="h5" inverted>
                        {" "}
                        Can only select{" "}
                        {this.state.maxind == 1
                          ? "one"
                          : this.state.maxind}{" "}
                        {this.state.maxind == 1 ? "industry" : "industries"} at
                        a time in this plan,{" "}
                        <Link
                          to="/account"
                          onClick={() =>
                            this.handleUpgradeClick("industry filter")
                          }
                        >
                          Upgrade
                        </Link>{" "}
                        to select more industries.
                      </Header>
                    </Transition>
                    <Transition
                      unmountOnHide
                      animation="fade"
                      duration={800}
                      visible={
                        this.state.maxindustries && DEFAULTPLAN == "Annual"
                      }
                    >
                      <Header className="error-header" as="h5" inverted>
                        {" "}
                        Can only select 5 at a time.
                      </Header>
                    </Transition>
                    <Grid stackable inverted columns={5}>
                      {this.state.industries.map((obj, index) => {
                        return (
                          // <Grid.Column width={3} key={index}><Checkbox readOnly={ !pFree1 } onChange={this.handleCheckbox(obj)} style={{color:'#fff'}} label={obj.industry} value={obj.id} checked={obj.selected} /> </Grid.Column>
                          <Grid.Column width={3} key={index}>
                            <Checkbox
                              readOnly={pFree1}
                              onChange={this.handleCheckbox(obj)}
                              style={{ color: "#fff" }}
                              label={obj.industry}
                              value={obj.id}
                              checked={obj.selected}
                            />{" "}
                          </Grid.Column>
                        );
                      })}
                    </Grid>
                  </Grid.Column>
                  {/*<Grid.Column floated="right" verticalAlign="bottom" textAlign="center" width={3}><Button primary onClick={this.applyIndustry} disabled={!pFree1} >Apply</Button>&nbsp;<Button inverted  basic onClick={this.resetIndustry} disabled={!pFree1} >Reset</Button></Grid.Column>*/}
                  <Grid.Column
                    floated="right"
                    verticalAlign="bottom"
                    textAlign="center"
                    width={3}
                  >
                    <Button
                      primary
                      onClick={this.applyIndustry}
                      disabled={pFree1}
                    >
                      Apply
                    </Button>
                    &nbsp;
                    <Button
                      inverted
                      basic
                      onClick={this.resetIndustry}
                      disabled={pFree1}
                    >
                      Reset
                    </Button>
                  </Grid.Column>
                </Grid>
              </Container>
            </Transition>
          )}
          {range_filter && (
            <Transition
              transitionOnMount
              visible={range_filter}
              duartion={1000}
              animation="fade"
            >
              <Container fluid>
                {DEFAULTPLAN == "Free1" && this.getUpgradeMsg()}
                <Grid divided stackable inverted>
                  {DEFAULTPLAN != "Free1" && (
                    <Grid.Column className="grid-btns" width={16}>
                      <Header className="error-header" as="h5" inverted>
                        {DEFAULTPLAN == "Free" ? (
                          <span>
                            <a
                              to="/http://www.thefr.com/event-analyzer"
                              target="_blank"
                              onClick={() =>
                                this.handleUpgradeClick("date filter")
                              }
                            >
                              Upgrade
                            </a>{" "}
                            to set a custom date range
                          </span>
                        ) : (
                          "**Filter by a specific month range OR use the past/upcoming toggle."
                        )}
                      </Header>
                    </Grid.Column>
                  )}
                  <Grid.Column width={6}>
                    <Grid stackable style={{ paddingRight: "5px" }}>
                      <Grid.Column width={2} style={{ paddingRight: "0em" }}>
                        <Header inverted>
                          <Header.Content>
                            <Dropdown
                              className="year-dropdown"
                              inline
                              trigger={<span>{this.state.year1}</span>}
                            >
                              <Dropdown.Menu>
                                {this.state.year_range.map((x) => {
                                  return (
                                    <Dropdown.Item
                                      key={x}
                                      onClick={this.changeyearfilter(x)}
                                    >
                                      {x}
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>{" "}
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={14}>
                        <Grid columns={6}>
                          {y_2018.map((obj, index) => {
                            return (
                              <Grid.Column key={index}>
                                <Button
                                  disabled={DEFAULTPLAN == "Free" || pFree1}
                                  size="tiny"
                                  onClick={this.handleRange(obj.id)}
                                  basic={!obj.selected}
                                  primary={obj.selected}
                                  inverted={!obj.selected}
                                >
                                  {obj.month}
                                </Button>{" "}
                              </Grid.Column>
                            );
                          })}
                        </Grid>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Grid stackable>
                      <Grid.Column width={2}>
                        <Header inverted>
                          {this.state.year2}
                          {DEFAULTPLAN == "Free" && (
                            <center>
                              <Icon
                                id="upgrade_from_free"
                                style={{ fontSize: "1em" }}
                                size="tiny"
                                name="lock"
                              />
                            </center>
                          )}
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={14}>
                        <Grid columns={6}>
                          {y_2019.map((obj, index) => {
                            return (
                              <Grid.Column key={index}>
                                <Button
                                  disabled={DEFAULTPLAN == "Free" || pFree1}
                                  size="tiny"
                                  onClick={this.handleRange(obj.id)}
                                  basic={!obj.selected}
                                  primary={obj.selected}
                                  inverted={!obj.selected}
                                >
                                  {obj.month}
                                </Button>{" "}
                              </Grid.Column>
                            );
                          })}
                        </Grid>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column
                    verticalAlign="middle"
                    width={3}
                    style={{ height: "115px" }}
                  >
                    <Button.Group style={{ marginTop: "20px" }}>
                      <Button
                        disabled={pFree1}
                        onClick={this.pastorupcoming("past")}
                        color={this.state.range_type == "past" ? "blue" : null}
                        basic={this.state.range_type != "past"}
                        primary={this.state.range_type == "past"}
                        inverted={this.state.range_type != "past"}
                        size="tiny"
                      >
                        Past
                      </Button>
                      <Button.Or />
                      <Button
                        disabled={pFree1}
                        onClick={this.pastorupcoming("upcoming")}
                        color={
                          this.state.range_type == "upcoming" ? "blue" : null
                        }
                        basic={this.state.range_type != "upcoming"}
                        primary={this.state.range_type == "upcoming"}
                        inverted={this.state.range_type != "upcoming"}
                        size="tiny"
                      >
                        Upcoming
                      </Button>
                    </Button.Group>
                  </Grid.Column>
                </Grid>
                <Grid
                  className="grid-btns"
                  divided
                  stackable
                  inverted
                  style={{ paddingTop: "0px" }}
                >
                  <Grid.Column
                    floated="right"
                    verticalAlign="bottom"
                    textAlign="right"
                    width={16}
                  >
                    <Button
                      disabled={
                        (!this.state.selected_date_range &&
                          !this.state.range_type) ||
                        pFree1
                      }
                      primary
                      onClick={this.applyDate}
                    >
                      Apply
                    </Button>
                    &nbsp;
                    <Button
                      inverted
                      basic
                      onClick={this.resetDate}
                      disabled={pFree1}
                    >
                      Reset
                    </Button>
                  </Grid.Column>
                </Grid>
              </Container>
            </Transition>
          )}
          {location_filter && (
            <Transition
              transitionOnMount
              visible={location_filter}
              duartion={1000}
              animation="fade"
            >
              <Container fluid>
                {DEFAULTPLAN == "Free1" && this.getUpgradeMsg()}
                {this.state.location.length < 1 && (
                  <Header className="filters" style={{ marginLeft: "5px" }}>
                    Please select at least one.
                  </Header>
                )}
                <Grid stackable divided inverted>
                  <Grid.Column width={12}>
                    <Grid style={{ padding: "0em 1em" }}>
                      {this.state.countries.map((obj, index) => {
                        return (
                          <Grid.Row className="w-auto" key={index}>
                            <Checkbox
                              style={{ color: "#fff" }}
                              label={obj.country}
                              value={obj.country}
                              checked={obj.selected}
                              onChange={this.applyCountry(obj)}
                              readOnly={pFree1}
                            />
                          </Grid.Row>
                        );
                      })}
                    </Grid>
                  </Grid.Column>
                  <Grid.Column
                    floated="right"
                    verticalAlign="bottom"
                    textAlign="center"
                    width={3}
                    style={{ boxShadow: "none" }}
                  >
                    <Button
                      disabled={this.state.location.length == 0 || pFree1}
                      primary
                      onClick={this.applyLocation}
                    >
                      Apply
                    </Button>
                    &nbsp;
                    <Button
                      inverted
                      basic
                      onClick={this.resetLocation}
                      disabled={pFree1}
                    >
                      Reset
                    </Button>
                  </Grid.Column>
                </Grid>
              </Container>
            </Transition>
          )}
        </div>
        {/*  <Modal  open={this.state.upgrade}
        		onClose={this.handleClose} 
        		size="small"
        		>
			    <Modal.Content >
			    <Header as="h2" icon textAlign="center">
			    <Icon name="lock"/><br/>
			    <Link to="/account" onClick={() =>this.handleUpgradeClick("download")}>Upgrade</Link> now to save these details for your records
			    </Header>
			    </Modal.Content>
			  </Modal>*/}
      </Fragment>
    );
  }
}

export default withRouter(FilterHeader);
