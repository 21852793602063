import React, { Component,Fragment } from 'react';
import {
  Header,
  Segment,
  Dimmer,
  Loader
} from 'semantic-ui-react';
import PropTypes from 'prop-types'

import { withRouter,Switch,Route } from "react-router-dom";
import allRoutes from './../../routes/allroutes.jsx';
import HeaderFile from './Header.jsx';
import LoginHeader from './LoginHeader.jsx';
import './../../assets/css/main.css';
import axios from 'axios';
import scriptjs from 'scriptjs'
import ReactGA from 'react-ga';
//import brandLogo from './../assets/images/invansys.png';

//All routes in the main app come from allroutes.jsx
function checkIfPreview() {
  var url = window.location.href;
  if(url.indexOf("/preview/")!==-1){
    return true;
  }
  return false;
}
const switchRoutes = (
  <Switch>
    {allRoutes.map((prop, key) => {
          //console.log(user,"u")
          return (
            <Route exact  path={prop.path} render={(props) => <prop.component {...props}  />}  key={key} />
          );
        })
    }
  </Switch>
);
const ResponsiveContainer = ({ children, history }) => (
  <div>
   <HeaderFile children={children} />
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

class BasicLayout extends Component{
  state = {user:{},renderco:false}

  componentDidMount(){
   this.setSession();
   
  }
  componentWillReceiveProps(prevProps){
    if(prevProps.location.pathname !== this.props.location.pathname && this.props.location.pathname!=="/") {
     if(!localStorage.getItem('user_details')) {
      this.props.history.push('/')
     }      
   
    }
  }
  setSession=()=>{
        if(!checkIfPreview()) {

          // axios.post('/session_check').then(()={}).catch(err=>{
          //   console.log(err);
          //   if(err.response){
          //     localStorage.removeItem('user_details');
          //     this.props.history.push('/')
          //   }
          // })
          
      this.check_state_change();
      // this.fetch_resources();
    // localStorage.removeItem('access_expired')
    // scriptjs('//cdn.tinypass.com/api/tinypass.min.js',() => {
    //   console.log(process.env.REACT_APP_PIANO_AID,process.env.REACT_APP_SANDBOX,"p")
    //   var tp = window.tp || [];
    //   // Set Application ID
    //   tp.push(["setAid", ''+process.env.REACT_APP_PIANO_AID+'']);
    //   // Is application in sandbox?
    //   tp.push(["setSandbox",process.env.REACT_APP_SANDBOX=='true'?true:false]);
    //   // Does application use Piano ID?
    //   tp.push(["setUseTinypassAccounts", false]);
    //   tp.push(["setUsePianoIdUserProvider", true ]);
    //   // Execute when the page is first loaded
    //   tp.push(["init", () => {
    //       tp.experience.init();
    //       //console.log(tp.pianoId.isUserValid(),"valid")
    //         if(tp.pianoId.isUserValid()){
    //             var user = tp.pianoId.getUser();
    //             //console.log(user,"user")
    //             axios.post('login',{email:user.email,password:user.uid,firstname:user.given_name,lastname:user.family_name}).then(login => {
    //               if(login.status==200){


    //               axios.post('get_access_info',{user_id:user.uid}).then(details => {
    //                 if(details.data.accesses){
    //                   if(details.data.accesses.length>0){
    //                     //console.log(details.data.accesses.length,"l")
    //                     var user = login.data;
    //                      if(details.data.accesses.length==1 && details.data.accesses[0].granted){
    //                     login.data.access = details.data.accesses[0].resource.rid;
    //                     //console.log(login.data,details.data,"login")
                        

    //                      if(user.access == process.env.REACT_APP_ANN_PLAN){
    //                                 user.plan = 'Annual';
    //                                 //user.industries = process.env.REACT_APP_ANN_IND
    //                               }
    //                               else if(user.access == process.env.REACT_APP_MTM_PLAN){
    //                                 user.plan = 'Month';
    //                                 //user.industries = process.env.REACT_APP_MTM_IND
    //                               }
    //                               else  if(user.access == process.env.REACT_APP_FREE_PLAN){
    //                                 user.plan = 'Free';
    //                                 //user.industries = process.env.REACT_APP_FREE_IND
    //                               }
    //                       else{
    //                         //console.log(user.access,process.env,"env")
    //                         this.setState({renderco:true})
    //                       }
    //                       //localStorage.setItem('user_details',JSON.stringify(user));
    //                     }
    //                     else {
    //                     var annual = details.data.accesses.filter(x => {
    //                       if(x.resource.rid==process.env.REACT_APP_ANN_PLAN && x.granted){
    //                         return x;
    //                       }
    //                     })
    //                     //console.log(annual,"a")
    //                     if(annual.length>0){
    //                       user.plan = 'Annual';
    //                     }
    //                     else{
    //                       var month = details.data.accesses.filter(x => {
    //                       if(x.resource.rid==process.env.REACT_APP_MTM_PLAN  && x.granted){
    //                         return x;
    //                       }
    //                        })
    //                       //console.log(month,"m")
    //                       if(month.length>0){
    //                         user.plan = 'Month'
    //                       }
    //                       else{
    //                          var free = details.data.accesses.filter(x => {
    //                           if(x.resource.rid==process.env.REACT_APP_FREE_PLAN  && x.granted ){
    //                             return x;
    //                           }
    //                            })
    //                        if(free.length>0){
    //                         user.plan = 'Free'
    //                        }
    //                        else{
    //                           localStorage.access_expired=true;
    //                           this.setState({renderco:true})
    //                        }
    //                       }
    //                     }
    //                   }
    //                     localStorage.setItem('user_details',JSON.stringify(user));
    //                     if(window.location.href=='/'){
    //                       this.props.history.push('/dashboard')
    //                     }
    //                     this.setState({renderco:true})
                        
                      
    //                 }
    //                 else{
    //                   //console.log(details,"d")
    //                   localStorage.access_expired=true;
    //                    this.setState({renderco:true})
    //                   //window.location.href = 'https://thefr.com/event-analyzer'
    //                 }
    //                 }
    //                 else{
    //                   tp.pianoId.logout();
    //                   this.setState({renderco:true})
    //                   //this.props.history.push('/')
    //                 }
                    
    //               }).catch(err => {
    //                 console.log(err);
    //               })
    //             }
    //         }).catch(err => {
    //           tp.pianoId.logout();
    //           this.setState({renderco:true})
    //           this.props.history.push('/')
    //         })
    //             //console.log(tp.pianoId.getUser(),"user")
    //     }
    //     else{
    //       this.setState({renderco:true})
    //       this.props.history.push('/')
    //     }
    //   }]);
    //   //console.log(window.tp.pianoId,"tp")
      

    // })
    // // var that = this;
    // // this.user_update();
    // // var tp = window.tp;
    
  
   
      
    }
  }
  check_state_change = () => {
    this.props.history.listen(pathname => {
      //console.log(pathname,"from inside")
      
      if(localStorage.path!=pathname.pathname){
        localStorage.setItem('path',pathname.pathname);
         ReactGA.pageview(window.location.pathname)
        //console.log('now call')
      }
    })
  }

  user_update = () => {
    //  axios.get('session_check').then(userdata => {
    //     localStorage.setItem('user_details',JSON.stringify(userdata.data))
    //     if(localStorage.user_details){
    //   //console.log(localStorage.user_details,'uu')
    //   var user = localStorage.user_details;
    //   this.setState({user:user})
    //   //console.log(user,"u")
    // }
    // else{
    //   this.props.history.push('/')
    // }
    //   }).catch(err => {
    //     if(err.response){
    //       localStorage.removeItem('user_details')
    //        this.props.history.push('/')
    //     }
    //     console.log(err);
    //   })
  }

  fetch_resources = () => {
    axios.get('fetch_piano_resources').then(data => {
      localStorage.resources = JSON.stringify(data.data.resources);
    }).catch(err => {
      console.log(err,"err");
    })
  }

  render(){
    //this.user_update();
    this.state.renderco=true;
    if(this.state.renderco || checkIfPreview()){
       return(
         <div className={checkIfPreview()?"preview-page":""}>
           <style>
             {`
              .preview-page .main-header.menu {
                  display: none !important;
              }

              .preview-page .main-footer {
                  display: none !important;
              }`
           }
           </style>
            <ResponsiveContainer history={this.props.history}>
            <Segment basic className="panel-bg">
            <div id="screenshot">
            {switchRoutes}
            </div>
             <Header as="h5" textAlign="center" className="main-footer">&copy; Copyright {new Date().getFullYear()} The Financial Revolutionist<br/> Contact Us: <a href="mailto:support@thefr.com">support@thefr.com</a></Header>
            </Segment>
            </ResponsiveContainer>
         </div>
      )
    }
    else{
      return (
         <Dimmer.Dimmable blurring as={Segment} dimmed={!this.state.renderco} style={{minHeight: '100vh'}}>
          <Dimmer active={!this.state.renderco} inverted>
            <Loader>Loading</Loader>
          </Dimmer>
        </Dimmer.Dimmable>
      )
    }
   
  }
}

export default withRouter(BasicLayout);

