import React, { Component, Fragment } from 'react';
import {
    Button,
    Container,
    Header,
    Image,
    Segment,
    Accordion,
    // Label,
    Dimmer,
    Loader,
    Divider,
    // List,
    Grid,
    Modal,
    Icon,
    Menu,
    Visibility,
    Table,
    Rating,
    Placeholder,
    Popup,
    // Checkbox
    Dropdown
    // Loader
} from 'semantic-ui-react';
import { withRouter, Link } from "react-router-dom";
import SingleConferencePage from './SingleConferencePage.jsx'
 import TimeMe from  'timeme.js';
//import moment from 'moment';
//import tz from 'moment-timezone';
// import ics from 'ics';

// import BigCalendar from 'react-big-calendar';
// import moment from 'moment';
// import 'moment/locale/nb';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import companies from './../../assets/img/companies.png';
import meetings from './../../assets/img/meetings.png';
import './../../assets/css/main.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import html2canvas from 'html2canvas';
import download from './../../assets/img/download.png';
import ad from './../../assets/img/ad.png';
var DEFAULTPLAN='Annual';

// const { writeFileSync } = require('fs');
//import fs from 'fs';
var backup_conf;
var TimeOutSave;
var TimeOutRecord;
var common;
var timeDuration = 5000;
var selectType = "";

const ics = require('ics');
const headers = {
    left: 'prev,title,next',
    center: '',
    right: ''
};
class MyEventCalendar extends Component {
    state = {
        activeCol:"",
        undo:false,
        calendarVis: false,
        summaryVis: true,
        comparisonVis: false,
        comparisonType: 'ALL PRICING',
        rating: 1,
        downloadModal: false,
        calendarInfo: {},
        // startDate:'',
        // endDate:'',
        
        events: [1],
        fetchingdata: true,
        activeIndex: null,
        modalOpen: null,
        user:{},
    };

    componentDidMount() {
        // axios.get('session_check').then(details =>{
        //     if(details.data.plan_type==process.env.REACT_APP_FREE_PLAN){
        //         details.data.plan='Free';
        //     }
        //     localStorage.setItem('user_details',JSON.stringify(details.data))
        //     this.setState({user:details.data});
        // }).catch(err => {
        //     console.log(err);
        // })
        if(localStorage.user_details){
            var user = JSON.parse(localStorage.user_details);
            this.setState({user:user},()=>this.fetchCalender())
        }
        else {this.fetchCalender();}
        // if(localStorage.user_details){
        //     var user = JSON.parse(localStorage.user_details);
        //     if(user.plan == 'Free'){
        //       this.props.history.push('/')
        //     }
        // }
        
    }
    fetchCalender=()=>{
      axios.get('fetch_user_events').then(events => {
            events.data.map(x => {
                x.activeIndex = [];
                // x.title = x.name;
                // x.borderColor ='#8d72ff'
                // x.color='#eeeaff'
                // x.textColor="#000000"
                // x.allDay=true;
                //x.end= x.end+" 20:00:00"
            })
            this.setState({ events: events.data, fetchingdata: false })
            axios.get('fetch_event_calendar_data').then(calendar => {
                calendar.data.map(y => {
                    if (!y.early_bird) {

                        y.borderColor = '#8d72ff'
                        y.color = '#eeeaff'
                        y.textColor = "#000000"
                    } else {
                        y.title = "EB " + y.title;
                        y.borderColor = '#e70000'
                        y.color = '#ffeaea'
                        y.textColor = "#000000"
                    }

                    y.allDay = true;
                })
                this.setState({ cal_events: calendar.data })
            }).catch(err => {
                console.log(err);
            })

        }).catch(err => {
            console.log(err);
            if(err.response.status === 401)
                {
                  localStorage.removeItem('user_details');
                  this.props.history.push('/')
                }
        })
          var plan_name =[{name:DEFAULTPLAN}];
          //           if(this.state.user.plan=='Free'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id = process.env.REACT_APP_FREE_PLAN;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Free1'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id1 = process.env.REACT_APP_FREE_PLAN1;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id1){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Month'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var mtm_id = process.env.REACT_APP_MTM_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==mtm_id){
          //                 return x;
          //               }
          //             })
          //           }
          //           else{
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var ann_id = process.env.REACT_APP_ANN_PLAN;
          //             var etp_id = process.env.REACT_APP_ETP_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==ann_id || x.rid==etp_id){
          //                 return x;
          //               }
          //             })
          //           }
         axios.post('record',{type:'ALL PRICING',identity:12,plan_type:plan_name[0].name}).then(done => {

        }).catch(err => {
            console.log(err,"analytics error");
        })
    }
    handleClick = obj => (e, titleProps) => {
        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = obj.activeIndex.indexOf(index) === -1 ? [...obj.activeIndex, index] : obj.activeIndex.filter(item => item !== index)

        var events = [...this.state.events];
        events.map(x => {
            if (x.id == obj.id) {
                x.activeIndex = newIndex;
                //console.log(x, "o")
            }
        })
        this.setState({ events: events })
         var type = null;
        //console.log(index,"index")
        if(index.includes('spo')){
            type = 'sponsor'
        }
        else if(index.includes('exh')){
            type = 'exhibitor'
        }
        else{
            type = 'attendee'
        }
        var id = index.substr(3,index.length)
        if(!titleProps.active){
            var plan_name;
                    if(this.state.user.plan=='Free'){
                      var resources = localStorage.getItem('resources');
                      resources = JSON.parse(resources);
                      var free_id = process.env.REACT_APP_FREE_PLAN;
                       plan_name = resources.filter(x => {
                        if(x.rid==free_id){
                          return x;
                        }
                      })

                    }
                    else if(this.state.user.plan=='Month'){
                      var resources = localStorage.getItem('resources');
                      resources = JSON.parse(resources);
                      var mtm_id = process.env.REACT_APP_MTM_PLAN;
                      plan_name = resources.filter(x => {
                        if(x.rid==mtm_id){
                          return x;
                        }
                      })
                    }
                    else{
                      var resources = localStorage.getItem('resources');
                      resources = JSON.parse(resources);
                      var ann_id = process.env.REACT_APP_ANN_PLAN;
                      var etp_id = process.env.REACT_APP_ETP_PLAN;
                      plan_name = resources.filter(x => {
                        if(x.rid==ann_id || x.rid==etp_id){
                          return x;
                        }
                      })
                    }
            axios.post('record',{type:type,identity:13,conference_id:id,plan_type:plan_name[0].name}).then(done => {

        }).catch(err => {
            console.log(err,"analytics error");
        })
        }
    }

    
    icsDate = (today) => {
        // today= new Date(today)
        // today=moment(today).subtract(1, 'days');
        var todayDate = new Date(today)
        var dd = todayDate.getDate();
        var mm = (todayDate.getMonth() + 1); //January is 0!
        var yyyy = todayDate.getFullYear();
        var tmH = todayDate.getHours();
        var tmM = todayDate.getMinutes();
        // var tm = today.getTime();
        // var tmH = moment(today).tz('UTC').format('HH');
        //var tmM = moment(today).tz('UTC').format('mm');
        // today= yyyy+mm+dd+tm;
        var arrtime = [];
        arrtime.push(yyyy);
        arrtime.push(mm);
        arrtime.push(dd);
        arrtime.push(tmH);
        arrtime.push(tmM);
        // console.log("Date::",arrtime);
        return arrtime;
    };

    handleDateClick = (info) => {
      console.log("date is click");
     // bind with an arrow function
        info.jsEvent.preventDefault();
        //console.log(info, "i")
        this.setState({ 'downloadModal': true });
        this.setState({ 'calendarInfo': info },() => {
            var plan_name;
                    if(this.state.user.plan=='Free'){
                      var resources = localStorage.getItem('resources');
                      resources = JSON.parse(resources);
                      var free_id = process.env.REACT_APP_FREE_PLAN;
                       plan_name = resources.filter(x => {
                        if(x.rid==free_id){
                          return x;
                        }
                      })

                    }
                    else if(this.state.user.plan=='Month'){
                      var resources = localStorage.getItem('resources');
                      resources = JSON.parse(resources);
                      var mtm_id = process.env.REACT_APP_MTM_PLAN;
                      plan_name = resources.filter(x => {
                        if(x.rid==mtm_id){
                          return x;
                        }
                      })
                    }
                    else{
                      var resources = localStorage.getItem('resources');
                      resources = JSON.parse(resources);
                      var ann_id = process.env.REACT_APP_ANN_PLAN;
                      var etp_id = process.env.REACT_APP_ETP_PLAN;
                      plan_name = resources.filter(x => {
                        if(x.rid==ann_id || x.rid==etp_id){
                          return x;
                        }
                      })
                    }
            axios.post('record',{identity:9,conference_id:this.state.calendarInfo.event.id,plan_type:plan_name[0].name}).then(done => {

            }).catch(err => {
                console.log(err,"analytics error")
            })
        });

    };


    handleclose = () => {
        this.setState({ 'downloadModal': false })
    };
    // handleDownload=()=>{
    //   // console.log("Event Popover button click");
    // };
    handleSummary = () => {
        this.setState({ 'calendarVis': false });
        this.setState({ 'comparisonVis': false });
        this.setState({ 'summaryVis': true });
    };
    handleCalendar = () => {
        this.setState({ 'summaryVis': false });
        this.setState({ 'comparisonVis': false });
        this.setState({ 'calendarVis': true });
        axios.get('fetch_event_calendar_data').then(calendar => {
                calendar.data.map(y => {
                    if (!y.early_bird) {

                        y.borderColor = '#8d72ff'
                        y.color = '#eeeaff'
                        y.textColor = "#000000"
                    } else {
                        y.title = "EB " + y.title;
                        y.borderColor = '#e70000'
                        y.color = '#ffeaea'
                        y.textColor = "#000000"
                    }

                    y.allDay = true;
                })
                this.setState({ cal_events: calendar.data })
            }).catch(err => {
                console.log(err);
            })
    };
    handleComparison = () => {
        if(this.state.user.plan!='Free'){
             this.setState({ 'summaryVis': false });
            this.setState({ 'calendarVis': false });
            this.setState({ 'comparisonVis': true });
        }
        else{
            this.setState({showupgrade:true})
            setTimeout(() => {
                this.setState({showupgrade:false})
            },2000)
        }
       
    };
    handleCompTypeChange = (event, data) => {
        // console.log("DATA",data);
        this.setState({ 'comparisonType': data.value });
        var events = this.state.events.map(x => x.id);
       var plan_name =[{name:DEFAULTPLAN}];
          //           if(this.state.user.plan=='Free'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id = process.env.REACT_APP_FREE_PLAN;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Free1'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id1 = process.env.REACT_APP_FREE_PLAN1;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id1){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Month'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var mtm_id = process.env.REACT_APP_MTM_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==mtm_id){
          //                 return x;
          //               }
          //             })
          //           }
          //           else{
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var ann_id = process.env.REACT_APP_ANN_PLAN;
          //             var etp_id = process.env.REACT_APP_ETP_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==ann_id || x.rid==etp_id){
          //                 return x;
          //               }
          //             })
          //           }
         axios.post('record',{type:data.value,identity:12,events:events,plan_type:plan_name[0].name}).then(done => {

        }).catch(err => {
            console.log(err,"analytics error");
        })
        // console.log("comparisonType" , this.state.comparisonType);
    };
    downloadModeChange = (obj, data) => {
        // console.log("Download Option",data);
        ics.createEvent({
            title: obj.name,
            start: this.icsDate(obj.start_date),
            end: this.icsDate(obj.end_date),
            description: obj.about,
            location: obj.venue + ', ' + obj.city
        }, (error, value) => {
            if (error) {
                console.log(error)
            }
            if (value) {
                // console.log(value)
                var uri = "data:text/calendar;charset=utf8," + escape(value);
                var downloadLink = document.createElement("a");
                downloadLink.href = uri;
                downloadLink.download = "event.ics";

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);


            }

        })
        var plan_name =[{name:DEFAULTPLAN}];
          //           if(this.state.user.plan=='Free'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id = process.env.REACT_APP_FREE_PLAN;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Free1'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id1 = process.env.REACT_APP_FREE_PLAN1;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id1){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Month'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var mtm_id = process.env.REACT_APP_MTM_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==mtm_id){
          //                 return x;
          //               }
          //             })
          //           }
          //           else{
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var ann_id = process.env.REACT_APP_ANN_PLAN;
          //             var etp_id = process.env.REACT_APP_ETP_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==ann_id || x.rid==etp_id){
          //                 return x;
          //               }
          //             })
          //           }
          axios.post('record',{identity:9,conference_id:[obj.id],plan_type:plan_name[0].name}).then(done => {

            }).catch(err => {
                console.log(err,"analytics error")
            })
        axios.post('record',{identity:5,file_type:'Event Calendar',file_format:'ics',via:'My-Events',conference_id:[obj.id],plan_type:plan_name[0].name}).then(done => {

        }).catch(err => {
            console.log(err,"analytics error")
        })
    }
  undoChanges=(restore = true)=>{
    selectType = "";
    clearTimeout(TimeOutSave);
            clearTimeout(TimeOutRecord);
            clearTimeout(common);
      
        this.setState({events:restore?backup_conf:this.state.events,undo:false})
     
  }
    handleEvent = (ArrayId,type) => (event, data) => {
          if(TimeOutSave){
            clearTimeout(TimeOutSave);
            clearTimeout(TimeOutRecord);
            clearTimeout(common);
          }
        this.setState({undo:true},()=>{
              backup_conf = this.state.events.map(cf=>{
                return { ...cf};
              });
              var conference = [...this.state.events];
              var plan_name =[{name:DEFAULTPLAN}];
          //           if(this.state.user.plan=='Free'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id = process.env.REACT_APP_FREE_PLAN;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Free1'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id1 = process.env.REACT_APP_FREE_PLAN1;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id1){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Month'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var mtm_id = process.env.REACT_APP_MTM_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==mtm_id){
          //                 return x;
          //               }
          //             })
          //           }
          //           else{
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var ann_id = process.env.REACT_APP_ANN_PLAN;
          //             var etp_id = process.env.REACT_APP_ETP_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==ann_id || x.rid==etp_id){
          //                 return x;
          //               }
          //             })
          //           }
          if (data.rating == 1) {
                    if(ArrayId.length > 1) {
                    conference.map(cf=>{
                      cf.starred = true;
                    })
                  }
                  else {
                    conference.map(cf=>{
                      if(cf.id === ArrayId[0].id) {
                         cf.starred = true;             
                    }
                  }
                  )}

                TimeOutSave = setTimeout(()=>{
                  if(this.state.undo){
                    this.setState({undo:false},()=>{
                        axios.post('save_event', { conference_id: ArrayId.map(cf=>cf.id)}).then(saved => {
                          this.setState({undo:false})
                        }).catch(err => {
                            console.log(err);
                        })
                    })
                  }
                    clearTimeout(TimeOutSave);
                    clearTimeout(TimeOutRecord);
                    clearTimeout(common);
                },timeDuration)
            // var plan_name;
            //         if(this.state.user.plan=='Free'){
            //           var resources = localStorage.getItem('resources');
            //           resources = JSON.parse(resources);
            //           var free_id = process.env.REACT_APP_FREE_PLAN;
            //            plan_name = resources.filter(x => {
            //             if(x.rid==free_id){
            //               return x;
            //             }
            //           })

            //         }
            //         else if(this.state.user.plan=='Month'){
            //           var resources = localStorage.getItem('resources');
            //           resources = JSON.parse(resources);
            //           var mtm_id = process.env.REACT_APP_MTM_PLAN;
            //           plan_name = resources.filter(x => {
            //             if(x.rid==mtm_id){
            //               return x;
            //             }
            //           })
            //         }
            //         else{
            //           var resources = localStorage.getItem('resources');
            //           resources = JSON.parse(resources);
            //           var ann_id = process.env.REACT_APP_ANN_PLAN;
            //           plan_name = resources.filter(x => {
            //             if(x.rid==ann_id){
            //               return x;
            //             }
            //           })
            //         }

                    TimeOutRecord = setTimeout(()=>{
                      if(this.state.undo){
                         this.setState({undo:false},()=>{
                             axios.post('record',{conference_id:ArrayId.map(cf=>cf.id),via:'My-Events',star_type:type,action:'Saved',identity:8,plan_type:plan_name[0].name}).then(done => {
                            }).catch(err => {
                              console.log(err,"analytics error");
                            })
                         })
                      }
                        clearTimeout(TimeOutSave);
                        clearTimeout(TimeOutRecord);
                        clearTimeout(common);                      
                    },timeDuration)

        }// end rating = 1
         else {
             if(ArrayId.length > 1) {
              conference.map(cf=>{
                cf.starred = false;
              })
            }
            else {
              conference.map(cf=>{
                if(cf.id === ArrayId[0].id) {
                   var index = conference.findIndex(cf=>cf.id===ArrayId[0].id);
                   conference.splice(index,1);              
              }
            }
            )}
            // var undo = [...this.state.undoeventslist];
            // undo.push(obj.id);
            // this.setState({undoevents:true,undoeventslist:undo})
            // setTimeout(() => {
            //   this.setState({undoevents:false,undoeventslist:[]})
            // },5000)
            // this.setState({ modalOpen: false })
            TimeOutSave = setTimeout(()=>{
              if(this.state.undo) {
                this.setState({undo:false},()=>{
                  axios.post('delete_event', { conference_id:ArrayId.map(cf=>cf.id) }).then(saved => {
                axios.get('fetch_user_events').then(events => {
                    this.setState({ events: events.data, fetchingdata: false})
                }).catch(err => {
                    console.log(err);
                })

                }).catch(err => {
                    console.log(err);
                })  

                  axios.post('record',{conference_id:ArrayId.map(cf=>cf.id),via:'My-Events',star_type:type,action:'Un-Saved',identity:8,plan_type:plan_name[0].name}).then(done => {

                  }).catch(err => {
                    console.log(err,"analytics error");
                  })
                })             
              }
                clearTimeout(TimeOutSave);
                clearTimeout(TimeOutRecord);
                clearTimeout(common);
            },timeDuration)
            
            // TimeOutRecord = setTimeout(()=>{
            //   console.log(this.state.undo,"undo in unsaved");
            //   if(this.state.undo) {
            //     this.setState({undo:false},()=>{
            //       axios.post('record',{conference_id:ArrayId.map(cf=>cf.id),via:'My-Events',star_type:type,action:'Un-Saved',identity:8,plan_type:plan_name[0].name}).then(done => {

            //       }).catch(err => {
            //         console.log(err,"analytics error");
            //       })
            //     })
            //   }
            // },timeDuration)
         }
            common=setTimeout(()=>{
            if(TimeOutSave) {
              this.undoChanges(false);
            }
          },timeDuration)
        this.setState({events:conference});
      })
    }

    download = (type) => event => {
        // this.startLoader();
        this.setState({ downloading: true })
        if (type == 'summary') {
            this.setState({ comparisonVis: false, summaryVis: true }, () => {

                const input = document.getElementById('printable-table');
                if (input) {
                    var width = input.offsetWidth;
                    var height = input.offsetHeight;
                    //console.log(input,"i")
                    html2canvas(input, {
                            logging: true,
                            useCORS: true,
                            dpi: 300,
                            scale: 2
                        })
                        .then((canvas) => {
                            const imgData = canvas.toDataURL('image/png');
                            axios({ method: 'POST', url: 'download_individual_event', data: { width: canvas.width, height: canvas.height, img: imgData }, responseType: 'blob' }).then(data => {
                                var name = 'summary.pdf'
                                const link = document.createElement('a');
                                //console.log(data.data,"d")
                                const url = window.URL.createObjectURL(new Blob([data.data]));
                                link.href = url
                                link.setAttribute('download', name);
                                link.setAttribute("target", "_blank"); //or any other extension
                                document.body.appendChild(link);
                                //console.log(link,"link")
                                this.setState({ downloading: false })
                                link.click();
                            }).catch(err => {
                                console.log(err);
                            })
                            var conf_ids = this.state.events.map(x => x.id);
                            var plan_name =[{name:DEFAULTPLAN}];
          //           if(this.state.user.plan=='Free'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id = process.env.REACT_APP_FREE_PLAN;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Free1'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id1 = process.env.REACT_APP_FREE_PLAN1;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id1){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Month'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var mtm_id = process.env.REACT_APP_MTM_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==mtm_id){
          //                 return x;
          //               }
          //             })
          //           }
          //           else{
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var ann_id = process.env.REACT_APP_ANN_PLAN;
          //             var etp_id = process.env.REACT_APP_ETP_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==ann_id || x.rid==etp_id){
          //                 return x;
          //               }
          //             })
          //           }
                              axios.post('record',{identity:5,file_type:'Event Summary',file_format:'pdf',conference_id:conf_ids,via:'My-Events',plan_type:plan_name[0].name}).then(done => {

                              }).catch(err => {
                                console.log(err,"analytics error");
                              })
                        })
                }



            })

        } else {
            this.setState({ comparisonVis: true, summaryVis: false }, () => {
                var events = [...this.state.events];
                events.map(x => {
                    var spo = 'spo' + x.id;
                    var exh = 'exh' + x.id;
                    var att = 'att' + x.id;
                    x.activeIndex = [spo, exh, att];
                })
                this.setState({ events: events }, () => {
                    const input = document.getElementById('printable-comparsion');
                    if (input) {
                        var width = input.offsetWidth;
                        var height = input.offsetHeight;
                        //console.log(input,"i")
                        html2canvas(input, {
                                logging: true,
                                useCORS: true,
                                dpi: 300,
                                scale: 2
                            })
                            .then((canvas) => {
                                const imgData = canvas.toDataURL('image/png');
                                axios({ method: 'POST', url: 'download_individual_event', data: { width: canvas.width, height: canvas.height, img: imgData }, responseType: 'blob' }).then(data => {
                                    var name = 'comparison.pdf'
                                    const link = document.createElement('a');
                                    //console.log(data.data,"d")
                                    const url = window.URL.createObjectURL(new Blob([data.data]));
                                    link.href = url
                                    link.setAttribute('download', name);
                                    link.setAttribute("target", "_blank"); //or any other extension
                                    document.body.appendChild(link);
                                    //console.log(link,"link")
                                    this.setState({ downloading: false })
                                    link.click();
                                }).catch(err => {
                                    console.log(err);
                                })
                                 var conf_ids = this.state.events.map(x => x.id);
                                var plan_name =[{name:DEFAULTPLAN}];
          //           if(this.state.user.plan=='Free'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id = process.env.REACT_APP_FREE_PLAN;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Free1'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id1 = process.env.REACT_APP_FREE_PLAN1;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id1){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Month'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var mtm_id = process.env.REACT_APP_MTM_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==mtm_id){
          //                 return x;
          //               }
          //             })
          //           }
          //           else{
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var ann_id = process.env.REACT_APP_ANN_PLAN;
          //             var etp_id = process.env.REACT_APP_ETP_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==ann_id || x.rid==etp_id){
          //                 return x;
          //               }
          //             })
          //           }
                                 axios.post('record',{identity:5,file_type:'Event Price Comparison',file_format:'pdf',conference_id:conf_ids,via:'My-Events',plan_type:plan_name[0].name}).then(done => {

                                  }).catch(err => {
                                    console.log(err,"analytics error");
                                  })
                            })
                    }
                })




            })


        }


    }



  downloadfree = () => {
    this.setState({upgrade:true})
    setTimeout(() => {this.setState({upgrade:false})},2000)
  }

  handleCloseFree = () => this.setState({ upgrade: false })


   openConfPopup = (id) => event => {
      TimeMe.startTimer("event-conf-popup");
      this.setState({modalOpen:id})
        
    }

    handleAnalytics = (obj) => event => {
        if(obj){
            var plan_name =[{name:DEFAULTPLAN}];
          //           if(this.state.user.plan=='Free'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id = process.env.REACT_APP_FREE_PLAN;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Free1'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id1 = process.env.REACT_APP_FREE_PLAN1;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id1){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Month'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var mtm_id = process.env.REACT_APP_MTM_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==mtm_id){
          //                 return x;
          //               }
          //             })
          //           }
          //           else{
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var ann_id = process.env.REACT_APP_ANN_PLAN;
          //             var etp_id = process.env.REACT_APP_ETP_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==ann_id || x.rid==etp_id){
          //                 return x;
          //               }
          //             })
          //           }
            axios.post('record',{identity:7,via:"My-Events",email_to:obj.organiser,conference_id:obj.id,plan_type:plan_name[0].name}).then(done => {

            }).catch(err => {
                console.log(err,"analytics error");
            })
        }
    }


    handleClose = () => {
    TimeMe.stopTimer("event-conf-popup");
     var conf_id = this.state.modalOpen
      this.setState({ modalOpen: false })
      var timespent = TimeMe.getTimeOnPageInSeconds("event-conf-popup")
     var plan_name =[{name:DEFAULTPLAN}];
          //           if(this.state.user.plan=='Free'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id = process.env.REACT_APP_FREE_PLAN;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Free1'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id1 = process.env.REACT_APP_FREE_PLAN1;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id1){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Month'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var mtm_id = process.env.REACT_APP_MTM_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==mtm_id){
          //                 return x;
          //               }
          //             })
          //           }
          //           else{
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var ann_id = process.env.REACT_APP_ANN_PLAN;
          //             var etp_id = process.env.REACT_APP_ETP_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==ann_id || x.rid==etp_id){
          //                 return x;
          //               }
          //             })
          //           }
        axios.post('record',{identity:11,conference_id:conf_id,time_spent:timespent,event_type:'Popup Conference Card',plan_type:plan_name[0].name}).then(done => {

        }).catch(err => {
            console.log(err,"analytics error")
        })
        TimeMe.resetAllRecordedPageTimes();
    }
    handleUpgradeClick=text=>{   
       const config={headers:{"Content-Type": "application/json"}};
       var plan_name =[{name:DEFAULTPLAN}];
          //           if(this.state.user.plan=='Free'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id = process.env.REACT_APP_FREE_PLAN;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Free1'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id1 = process.env.REACT_APP_FREE_PLAN1;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id1){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Month'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var mtm_id = process.env.REACT_APP_MTM_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==mtm_id){
          //                 return x;
          //               }
          //             })
          //           }
          //           else{
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var ann_id = process.env.REACT_APP_ANN_PLAN;
          //             var etp_id = process.env.REACT_APP_ETP_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==ann_id || x.rid==etp_id){
          //                 return x;
          //               }
          //             })
          //           }
       const data=JSON.stringify({identity:14,link:text,plan_type:plan_name[0].name});
       axios.post("/record",data,config)
       .then(done => {

       })
       .catch((error) =>console.log(error));
    }

    restoreEvents = () => {
      if(this.state.undoeventslist.length>0){
            axios.post('save_event', { conference_id: this.state.undoeventslist }).then(saved => {
                  axios.get('fetch_user_events').then(events => {
                    events.data.map(x => {
                        x.activeIndex = [];
                        // x.title = x.name;
                        // x.borderColor ='#8d72ff'
                        // x.color='#eeeaff'
                        // x.textColor="#000000"
                        // x.allDay=true;
                        //x.end= x.end+" 20:00:00"
                    })
                    this.setState({ events: events.data, fetchingdata: false, undoeventslist:[],undoevents:false })
                }).catch(err => {
                    console.log(err);
                })
            }).catch(err => {
                console.log(err);
            })
      }
    }
    sortDate=(order)=>{
            this.state.events.sort((obj1,obj2)=>{
        var nameA=new Date(obj1['start_date']).getTime(), nameB=new Date(obj2['start_date']).getTime();
       if (nameA < nameB) //sort string ascending
        return -1;
       if (nameA > nameB)
        return 1;
       return 0;
      });
      if(order==="down"){       
          this.state.events=this.state.events.reverse();
        }
        this.setState({activeCol:`date ${order}`})
    }
    sortNum=(field,order)=>{
      this.state.events.sort((obj1,obj2)=>{
        var nameA=obj1[field], nameB=obj2[field];
       if (nameA < nameB) //sort string ascending
        return -1;
       if (nameA > nameB)
        return 1;
       return 0;
      });
      if(order==="down"){
          this.state.events=this.state.events.reverse();
        }
        this.setState({activeCol:`${field} ${order}`})
    }
 sort=(field,order)=>{
        
         this.state.events.sort((obj1,obj2)=>{
           if(order === 'up') {
         		if(obj1[field] === "" || obj1[field] === null) return 1;
		    	  if(obj2[field] === "" || obj2[field] === null) return -1;
         	}else if(order === 'down'){
         		if(obj1[field] === "" || obj1[field] === null) return -1;
		    	  if(obj2[field] === "" || obj2[field] === null) return 1;
         	}
		       if(obj1[field].toUpperCase() === obj2[field].toUpperCase()) return 0;
		       return obj1[field].toUpperCase() < obj2[field].toUpperCase() ? -1 : 1; 
      	})

        if(order==="down"){
          this.state.events=this.state.events.reverse();
        }

      this.setState({activeCol:`${field} ${order}`})
    }
        checkLoading =( data, returnType = <Placeholder><Placeholder.Line/></Placeholder> )=> {
      const { fetchingdata } = this.state;
      if(fetchingdata)
        return (returnType);
      return data;
    }
        sendAgendaData = (conference_id,link) =>{   
    var body = {
      conference_id,
      plan_type:this.calculatePlan()[0].name,
      identity:17,
      via:"My-Events"
    };
    console.log(body);
    axios.post('record',body);
  }
    downloadCsv = (file_type,id,downloadLink) =>{
    var filters = {};
    filters.conferences=[...this.state.events];
    var plan_name =[{name:DEFAULTPLAN}];
          //           if(this.state.user.plan=='Free'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id = process.env.REACT_APP_FREE_PLAN;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Free1'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id1 = process.env.REACT_APP_FREE_PLAN1;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id1){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Month'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var mtm_id = process.env.REACT_APP_MTM_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==mtm_id){
          //                 return x;
          //               }
          //             })
          //           }
          //           else{
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var ann_id = process.env.REACT_APP_ANN_PLAN;
          //             var etp_id = process.env.REACT_APP_ETP_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==ann_id || x.rid==etp_id){
          //                 return x;
          //               }
          //             })
          //           }

         if(file_type === 'Brochure'){
          console.log(id,"id",downloadLink,"downloadLink",downloadLink.split('.'));
          var fileformat = downloadLink.split('.')[downloadLink.split('.').length-1];
          console.log(fileformat,"fileformat");
          axios.post('record',{identity:5,file_type,file_format:fileformat,conference_id:id,via:'My-Events',plan_type:plan_name[0].name}).then(done => {

            }).catch(err => {
              console.log(err,"analytics error");
            })
         }
  }
      calculatePlan = () =>{
    var plan_name =[{name:DEFAULTPLAN}];
          //           if(this.state.user.plan=='Free'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id = process.env.REACT_APP_FREE_PLAN;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Free1'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var free_id1 = process.env.REACT_APP_FREE_PLAN1;
          //              plan_name = resources.filter(x => {
          //               if(x.rid==free_id1){
          //                 return x;
          //               }
          //             })

          //           }
          //           else if(this.state.user.plan=='Month'){
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var mtm_id = process.env.REACT_APP_MTM_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==mtm_id){
          //                 return x;
          //               }
          //             })
          //           }
          //           else{
          //             var resources = localStorage.getItem('resources');
          //             resources = JSON.parse(resources);
          //             var ann_id = process.env.REACT_APP_ANN_PLAN;
          //             var etp_id = process.env.REACT_APP_ETP_PLAN;
          //             plan_name = resources.filter(x => {
          //               if(x.rid==ann_id || x.rid==etp_id){
          //                 return x;
          //               }
          //             })
          //           }
        return plan_name;            
  }
    render() {
      if(this.state.user.plan=='Free1') {
        this.props.history.push('/search-events');
      }
        const arr = Array.from({ length: 12 }, () => Math.floor(Math.random() * 11));
        const comparisonArr = Array.from({ length: 16 }, () => Math.floor(Math.random() * 15));
        const options = [
            { key: 'a', text: 'ALL PRICING', value: 'ALL PRICING' },
            { key: 's', text: 'SPONSOR', value: 'SPONSOR' },
            { key: 'e', text: 'EXHIBITOR', value: 'EXHIBITOR' },
            { key: 'at', text: 'ATTEND', value: 'ATTEND' }
        ];
        const { activeIndex , activeCol,fetchingdata} = this.state
        const nf = new Intl.NumberFormat("en-US", {


            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        });
        return (
            <Fragment>
            <style>
        {`
        .long.arrow.alternate{
              cursor: pointer;
            font-size: 18px;
            margin: 0;
            width: 11px;
        }
       .fl-right{
        display: inline-block;
        padding-left: 11px;
       }
       th.companies{
        padding-right:64px !important;

       }
       th.companies >div{
        margin-right:-50px;
        
       }
       .inverted.download-menu{
        display:none !important;
      }
       `
             }
      </style>
              {this.state.showupgrade &&  <div className="filters">
               <Container fluid>
                       <Header className="error-header" as="h5" inverted style={{paddingTop:'10px'}}><span><Link to="/account" onClick={() =>this.handleUpgradeClick("price comparison")}>Upgrade</Link> now to compare event pricing</span></Header>
                       </Container>
                        </div>}
                         {this.state.upgrade &&  <div className="filters">
               <Container fluid>
                       <Header className="error-header" as="h5" inverted style={{paddingTop:'10px'}}><span><Link to="/account" onClick={() =>this.handleUpgradeClick("download events")}>Upgrade</Link> now to save these details for your record</span></Header>
                       </Container>
                        </div>}
                <Menu   
                     borderless
                      size='large'
                      secondary
                      pointing
                      stackable
                      className="filter-header"
                      style={{marginTop: '0px'}}
                    >
                      <Container fluid>

                        <Menu.Item style={{padding: '0'}}>

                          <Button
                            size="large" color={this.state.summaryVis?'blue':null} className={this.state.summaryVis?'my-event-buttons':"my-event-buttons white-btn"} onClick={this.handleSummary}>SUMMARY</Button>
                            
                            {/* <Button
                            size="large" color={this.state.comparisonVis?'blue':null} className={this.state.user.plan=='Free'?'locked my-event-buttons':this.state.comparisonVis?'my-event-buttons':"my-event-buttons white-btn"} onClick={this.handleComparison}>PRICE COMPARISON &nbsp; {this.state.user.plan=='Free' &&<Icon name="lock"  />}</Button> */}
                            
                            <Button
                            size="large" color={this.state.calendarVis?'blue':null}  className={this.state.calendarVis?'my-event-buttons':"my-event-buttons white-btn"} onClick={this.handleCalendar}>CALENDAR</Button>
                        </Menu.Item>
                        <Menu.Item position="right" style={{height: '100%'}}>
                        {window.innerWidth>768 && (this.state.user.plan=='Free' || this.state.user.plan=='Month') && this.state.events.length>0 && <Dropdown onClick={this.downloadfree} id={DEFAULTPLAN=='Free'?'upgrade_from_free':'upgrade_from_month'} open={this.state.user.plan!='Free' && this.state.user.plan!='Month'}  direction="left" inline trigger={<span><Image verticalAlign="middle" src={download}/> {(this.state.user.plan=='Free' || this.state.user.plan=='Month') && <Icon corner='top right' name='lock' style={{color: '#fff'}} />}</span>} className="inverted download-menu" style={{backgroundColor: '#585858'}}>
                                       <Dropdown.Menu >
                                          <Dropdown.Header>Download</Dropdown.Header>
                                       <Dropdown.Item onClick={this.download('summary')}>Summary </Dropdown.Item>
                                          <Dropdown.Item onClick={this.download('comparsion')}>Price Comparison </Dropdown.Item>
                                       </Dropdown.Menu>
                                   </Dropdown>}
                                   {window.innerWidth>768 && this.state.user.plan!='Free' && this.state.user.plan!='Month' && this.state.events.length>0 && <Dropdown direction="left" inline trigger={<span><Image verticalAlign="top" src={download}/> {this.state.user.plan=='Free' && <Icon corner='top right' name='lock' style={{color: '#fff'}} />}</span>} className="inverted download-menu" style={{backgroundColor: '#585858'}}>
                                       <Dropdown.Menu >
                                          <Dropdown.Header>Download</Dropdown.Header>
                                          <Dropdown.Item onClick={this.download('summary')}>Summary </Dropdown.Item>
                                          <Dropdown.Item onClick={this.download('comparsion')}>Price Comparison </Dropdown.Item>
                                       </Dropdown.Menu>
                                   </Dropdown>}

                                  {window.innerWidth<768 &&  (this.state.user.plan=='Free' || this.state.user.plan=='Month') &&  this.state.events.length>0 &&<Dropdown onClick={this.downloadfree} id={DEFAULTPLAN=='Free'?'upgrade_from_free':'upgrade_from_month'} open={this.state.user.plan!='Free' && this.state.user.plan!='Month'}  direction="left" inline trigger={<span><Image verticalAlign="middle" src={download}/> {(this.state.user.plan=='Free' || this.state.user.plan=='Month') && <Icon corner='top right' name='lock' style={{color: '#fff'}} />}</span>} className="inverted download-menu" style={{backgroundColor: '#585858'}}>
                                       <Dropdown.Menu >
                                          <Dropdown.Header>Download</Dropdown.Header>
                                       <Dropdown.Item onClick={this.download('summary')}>Summary </Dropdown.Item>
                                          <Dropdown.Item onClick={this.download('comparsion')}>Price Comparison </Dropdown.Item>
                                       </Dropdown.Menu>
                                   </Dropdown>}
                 {window.innerWidth<768 && this.state.user.plan!='Free' && this.state.user.plan!='Month' &&  this.state.events.length>0 &&<Dropdown  inline trigger={<span><Image verticalAlign="top" src={download}/></span>} className="inverted download-menu" style={{backgroundColor: '#585858'}}>
                                       <Dropdown.Menu >
                                          <Dropdown.Header>Download</Dropdown.Header>
                                         <Dropdown.Item onClick={this.download('summary')}>Summary </Dropdown.Item>
                                          <Dropdown.Item onClick={this.download('comparsion')}>Price Comparison </Dropdown.Item>
                                       </Dropdown.Menu>
                                   </Dropdown>}
                   
               
              
               </Menu.Item>
                      </Container>
                </Menu>
 {this.state.downloading &&
                <Dimmer active page inverted>
                               <Loader inline='centered' inverted content='Loading' />
                            </Dimmer>
              }


{/**********Summary**********/}
        {this.state.summaryVis &&  <Container fluid className='summary-container'>
            {/*<Segment className='summary-segment'>*/}
           <div id="printable-table" className="summary-div">
              <Table unstackable className="normal-table" style={{display:"block",minWidth:"100%", marginTop:"14px",maxHeight:"800px",overflowY:"auto"}}>             
                    <Table.Header className="table-head">
                          <Table.Row style= {{minWidth:"100%"}}>                          
                            <Table.HeaderCell textAlign="center" style={{whiteSpace:"pre"}}>{this.state.events.length} Saved</Table.HeaderCell>
                             <Table.HeaderCell >Email</Table.HeaderCell>
                            <Table.HeaderCell >Calendar</Table.HeaderCell>
                            <Table.HeaderCell width={3}>Name<div className="fl-right"><Icon name = "long arrow alternate up" onClick={this.sort.bind(this,"name","up")} style={{color:activeCol=="name up"?"#000000de":"#717171de"}}/><Icon name = "long arrow alternate down" onClick={this.sort.bind(this,"name","down")} style={{color:activeCol=="name down"?"#000000de":"#717171de"}}/></div></Table.HeaderCell>
                            <Table.HeaderCell width={2}>Date<div className="fl-right"><Icon name = "long arrow alternate up" onClick={this.sortDate.bind(this,"up")} style={{color:activeCol=="date up"?"#000000de":"#717171de"}}/><Icon name = "long arrow alternate down" onClick={this.sortDate.bind(this,"down")} style={{color:activeCol=="date down"?"#000000de":"#717171de"}}/></div></Table.HeaderCell>
                            
                            <Table.HeaderCell className="companies" width={2}>City<div className="fl-right"><Icon name = "long arrow alternate up" onClick={this.sort.bind(this,"city","up")} style={{color:activeCol=="city up"?"#000000de":"#717171de"}}/><Icon name = "long arrow alternate down" onClick={this.sort.bind(this,"city","down")} style={{color:activeCol=="city down"?"#000000de":"#717171de"}}/></div></Table.HeaderCell>
                            <Table.HeaderCell width={2} >Industry</Table.HeaderCell>
                            <Table.HeaderCell width={2} textAlign="right" className="companies">Companies<div className="fl-right"><Icon name = "long arrow alternate up" onClick={this.sortNum.bind(this,"companies","up")} style={{color:activeCol=="companies up"?"#000000de":"#717171de"}}/><Icon name = "long arrow alternate down" onClick={this.sortNum.bind(this,"companies","down")} style={{color:activeCol=="companies down"?"#000000de":"#717171de"}}/></div></Table.HeaderCell>
                            <Table.HeaderCell width={1}>Agenda</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Download</Table.HeaderCell>
                          </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {
                      this.state.undo &&  
                      <Table.Row  textAlign="center" colSpan="7">
                            <Table.Cell colSpan="9" style={{color:'#5290ff',cursor:'pointer'}} onClick={this.undoChanges}><Icon name="undo"/>UNDO LAST CHANGES</Table.Cell>
                            
                          </Table.Row>
                    }
                      {
                        this.state.events.map((obj,index) => {
                          return(
                          <Table.Row>
                            <Table.Cell textAlign="center">{this.checkLoading(<Rating style={{textAlign:"center"}} rating={obj.starred?1:0} onRate={this.handleEvent([obj])} size="huge" defaultRating={this.state.rating}/>,<Rating rating={1} size="huge" />)}</Table.Cell>
                              <Table.Cell textAlign="center" style={{borderLeft:'1px solid #d0d0d0'}}>{this.state.fetchingdata?(<Icon style={{margin:'0 auto',marginTop: '-10px',cursor:"pointer"}} name="mail outline" size="large" />):(<center>{obj.organiser && <Button style={{padding:0,border:'none',boxShadow:'none'}} onClick={this.handleAnalytics(obj)}  basic as="a" href={"mailto:"+obj.organiser+"?subject=Found%20on%20the%20Event%20Analyzer%20platform&body=I%20found%20your%20event%20on%20The%20Financial%20Revolutionist%27s%20Event%20Analyzer%20platform.%20I%27d%20like%20to%20learn%20more%20about%20your%20event."}><Icon style={{margin:'0 auto',marginTop: '-10px'}} name="mail outline" size="large" /></Button>}</center>)}</Table.Cell>
                              <Table.Cell  textAlign = "center">{this.checkLoading(<Icon onClick={this.downloadModeChange.bind(this,obj)} style={{margin:'0 auto',marginTop: '-10px',cursor:"pointer"}} name="calendar alternate outline" size="large" />,<Icon  style={{margin:'0 auto',marginTop: '-10px'}} name="calendar alternate outline" size="large" />)}</Table.Cell>
                               <Modal  open={this.state.modalOpen && this.state.modalOpen==obj.id } onClose={this.handleClose} trigger={!fetchingdata?<Table.Cell onClick={this.openConfPopup(obj.id)} className="conf-name" width={4} style={{cursor:'pointer'}}>{(obj.name)}</Table.Cell>:<Table.Cell className="conf-name" width={4} style={{cursor:'pointer'}}><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>}>
                                  <Modal.Content>
                                  <SingleConferencePage events={true} data={obj} unsave={this.handleEvent}  />
                                  </Modal.Content>
                                </Modal>
                              <Table.Cell width={3}>{this.checkLoading(obj.date)}</Table.Cell>
                              <Table.Cell width={1} >{this.checkLoading(obj.city)}</Table.Cell>
                              <Table.Cell width={2}>{this.checkLoading(obj.industry)}</Table.Cell>
                              <Table.Cell width={2} textAlign="right">{this.checkLoading(<Fragment>{obj.companies}{obj.peers?(<span> | {obj.peers} {obj.peers==1?'Peer':'Peers'}</span>):(null)}</Fragment>)}</Table.Cell>
                              <Table.Cell width={1}>{fetchingdata? <a style={{fontWeight:"bold"}} > Agenda</a>:(obj.agenda_link?<a href={obj.agenda_link} target="_blank" style={{fontWeight:"bold"}} onClick={this.sendAgendaData.bind(this,obj.id,obj.download)}> Agenda</a>:null) }</Table.Cell>
                              <Table.Cell width={1} textAlign = "center">{!fetchingdata?(obj.download?this.checkLoading(<a href={obj.download} target="_blank" onClick={this.downloadCsv.bind(this,"Brochure",[obj.id],obj.download)}><Icon link style={{margin:'0 auto',marginTop: '-10px',color:"#000"}} name="arrow alternate circle down outline" size="large" /></a>,""):""):<Icon link style={{margin:'0 auto',marginTop: '-10px'}} name="arrow alternate circle down outline" size="large" />}</Table.Cell>
                      </Table.Row>
                        )})
                       }
                    </Table.Body>
              </Table>
                          </div>
           {/* </Segment>*/}
            {this.state.events.length==0 && <p style={{fontSize: '1.2rem',fontWeight:700,textAlign:'initial',marginTop: '20px'}}>Return to <Link to="/search-events">Search Events</Link>, and click the star icon to begin saving a list of events of interest.</p>}

          </Container>}



{/**********Price Comparison**********/}
          {this.state.comparisonVis && <Container fluid className='comparison-container'>
              <Dropdown style={{marginTop: '20px'}} icon="caret down" defaultValue={options[0].value} options={options} downward="true" onChange={this.handleCompTypeChange}>
                  {/*<Dropdown.Menu>
                                      <Dropdown.Item text="ALL PRICING" value="ALL PRICING"></Dropdown.Item>
                                      <Dropdown.Item text="SPONSOR" value="SPONSOR"></Dropdown.Item>
                                    </Dropdown.Menu>*/}
              </Dropdown>
              <br/><br/>
             
            <Grid id="printable-comparsion" stackable stretched columns={3}>
              {this.state.events.map((obj,index) => {
                return(
                 <Grid.Column key={index}>
                <Segment className='comparison-segment comparison-columns' key={index} >
                  <Header as='h2' style={{minHeight:'90px'}}>{obj.name}</Header>
                          <Segment textAlign='center' className="venue-segment">
                          <Grid stretched style={{minHeight: '110px'}}>
                          <Grid.Column width={16} verticalAlign="middle">
                          <Header as='h5'>{obj.date}{obj.city?(<span>{', '+obj.city}</span>):null}</Header><Divider/>
                          <Header as='h5'>{obj.venue}</Header>
                          </Grid.Column>
                          </Grid>
                          </Segment>
                          <br/>
                          <Grid  column={2} className="comp-grid">
                          <Grid.Column width={11}><Header as="h3">
                              <Image src={companies} size='small'/>
                              <Header.Content>{obj.companies} Companies {obj.peers && obj.peers!=1?('| '+obj.peers+' Peers'):(obj.peers && obj.peers==1)?('| '+obj.peers+' Peer'):(null)}
                              <Header.Subheader>To Date</Header.Subheader>
                              </Header.Content>
                              </Header></Grid.Column>
                        {/*  <Grid.Column width={7}><Header as="h5">
                              <Image src={meetings} size='mini'/><br/>
                              <Header.Content>{obj.att_count} Attendees
                              <Header.Subheader>Estimated</Header.Subheader>
                              </Header.Content>
                              </Header></Grid.Column>*/}
                          </Grid>
                          <br/>
                          {(this.state.comparisonType=='ALL PRICING' || this.state.comparisonType == 'SPONSOR')  && <Fragment>  <Accordion exclusive={false}>
                                              <Accordion.Title active={obj.activeIndex.indexOf('spo'+obj.id)!=-1} index={'spo'+obj.id} onClick={this.handleClick(obj)}>
                                                
                                              <Header as='h4' style={{margin:'0.5rem 0rem'}}>Sponsor ({obj.s_pricing?obj.s_pricing.length:0})  <Icon style={{float:'right',fontSize: '1.4em'}} name={obj.activeIndex.indexOf('spo'+obj.id)!=-1?'minus circle':'plus circle'} color="blue" /></Header> 
                                              <Divider/>
                                              </Accordion.Title>
                                              <Accordion.Content  active={obj.activeIndex.indexOf('spo'+obj.id)!=-1}>
                                                {
                                                       obj.s_pricing && obj.s_pricing.map((x,index1) => {
                                                          return(
                                                            <Fragment key={index1}>
                                                            <Grid key={index1} container className="participation">
                                                            <Grid.Column width={11} textAlign='left'><span>{x.plan_type} {x.rate_type?(<span>({x.rate_type})</span>):null}</span> {(x.valid_till || x.attendee_type) &&<Popup trigger={<Icon name="info circle" color="blue" />} content={<span>{x.valid_till?(<span><b>Valid Until</b> : {x.valid_till}</span>):null} {x.valid_till && x.attendee_type && <br/>} {x.attendee_type?(<span><b>Sponsor Type</b>: {x.attendee_type}</span>):null}</span>} />}</Grid.Column>
                                                            <Grid.Column width={5} className='part-value' textAlign='right'>$ {nf.format(x.price)}</Grid.Column>
                                                          
                                                           </Grid><Divider/></Fragment>
                                                          )
                                                        })
                                                      }
                                                      
                                              </Accordion.Content>              
                                                 </Accordion>   
                                                     
                                                      <br/>
                                                   </Fragment>    } 
                                                  
                                                   
                            {(this.state.comparisonType=='ALL PRICING' || this.state.comparisonType == 'EXHIBITOR')  &&  <Fragment>  <Accordion exclusive={false}>
                                              <Accordion.Title active={obj.activeIndex.indexOf('exh'+obj.id)!=-1} index={'exh'+obj.id} onClick={this.handleClick(obj)}>
                                                
                                              <Header as='h4' style={{margin:'0.5rem 0rem'}}>Exhibitor ({obj.e_pricing?obj.e_pricing.length:0})  <Icon style={{float:'right',fontSize: '1.4em'}} name={obj.activeIndex.indexOf('exh'+obj.id)!=-1?'minus circle':'plus circle'} color="blue" /></Header> 
                                              <Divider/>
                                              </Accordion.Title>
                                              <Accordion.Content  active={obj.activeIndex.indexOf('exh'+obj.id)!=-1}>
                                                {
                                                       obj.e_pricing && obj.e_pricing.map((x,index1) => {
                                                          return(
                                                            <Fragment key={index1}>
                                                            <Grid key={index1} container className="participation">
                                                            <Grid.Column width={11} textAlign='left'><span>{x.plan_type} {x.rate_type?(<span>({x.rate_type})</span>):null}</span> {(x.valid_till || x.attendee_type) &&<Popup trigger={<Icon name="info circle" color="blue" />} content={<span>{x.valid_till?(<span><b>Valid Until</b> : {x.valid_till}</span>):null} {x.valid_till && x.attendee_type && <br/>} {x.attendee_type?(<span><b>Exhibitor Type</b>: {x.attendee_type}</span>):null}</span>} />}</Grid.Column>
                                                            <Grid.Column width={5} className='part-value' textAlign='right'>$ {nf.format(x.price)}</Grid.Column>
                                                          
                                                           </Grid><Divider/></Fragment>
                                                          )
                                                        })
                                                      }
                                                      
                                              </Accordion.Content>              
                                                 </Accordion>   
                                                     
                                                      <br/>
                                                   </Fragment>    } 
                                                    
                                                   
                                                {(this.state.comparisonType=='ALL PRICING' || this.state.comparisonType == 'ATTEND')  &&       <Fragment>  <Accordion>
                                              <Accordion.Title active={obj.activeIndex.indexOf('att'+obj.id)!=-1} index={'att'+obj.id} onClick={this.handleClick(obj)}>
                                                
                                              <Header as='h4' style={{margin:'0.5rem 0rem'}}>Attend ({obj.a_pricing?obj.a_pricing.length:0})  <Icon style={{float:'right',fontSize: '1.4em'}} name={obj.activeIndex.indexOf('att'+obj.id)!=-1?'minus circle':'plus circle'} color="blue" /></Header> 
                                              <Divider/>
                                              </Accordion.Title>
                                              <Accordion.Content  active={obj.activeIndex.indexOf('att'+obj.id)!=-1}>
                                                {
                                                       obj.a_pricing && obj.a_pricing.map((x,index1) => {
                                                          return(
                                                            <Fragment key={index1}>
                                                            <Grid key={index1} container className="participation">
                                                            <Grid.Column width={11} textAlign='left'><span>{x.plan_type} {x.rate_type?(<span>({x.rate_type})</span>):null}</span> {(x.valid_till || x.attendee_type) &&<Popup trigger={<Icon name="info circle" color="blue" />} content={<span>{x.valid_till?(<span><b>Valid Until</b> : {x.valid_till}</span>):null} {x.valid_till && x.attendee_type && <br/>} {x.attendee_type?(<span><b>Attendee Type</b>: {x.attendee_type}</span>):null}</span>} />}</Grid.Column>
                                                            <Grid.Column width={5} className='part-value' textAlign='right'>$ {nf.format(x.price)}</Grid.Column>
                                                          
                                                           </Grid><Divider/></Fragment>
                                                          )
                                                        })
                                                      }
                                                      
                                              </Accordion.Content>              
                                                 </Accordion>   
                                                     
                                                      <br/>
                                                   </Fragment>    } 
                                                    
                                                   
{/*
                           {obj.e_pricing && obj.e_pricing.length>0 && <Fragment> <Header as='h4'>Exhibit</Header>                   
                                                    
                                                      {
                                                        obj.e_pricing.map((x,index1) => {
                                                          return(
                                                            <Fragment>
                                                            <Grid key={index1} container className="participation">
                                                            <Grid.Column width={11} textAlign='left'><span>{x.plan_type} {x.rate_type?(<span>({x.rate_type})</span>):null}</span> {(x.valid_till || x.attendee_type) &&<Popup trigger={<Icon name="info circle" color="blue" />} content={<span>{x.valid_till?(<span><b>Valid Until</b> : {x.valid_till}</span>):null} {x.valid_till && x.attendee_type && <br/>} {x.attendee_type?(<span><b>Sponsor Type</b>: {x.attendee_type}</span>):null}</span>} />}</Grid.Column>
                                                            <Grid.Column width={5} className='part-value' textAlign='right'>$ {nf.format(x.price)}</Grid.Column>
                                                          
                                                           </Grid><Divider/></Fragment>
                                                          )
                                                        })
                                                      }
                                                      <br/>
                                                   </Fragment>     
                                                    
                                                   }
                          
                            {obj.a_pricing && obj.a_pricing.length>0 && <Fragment> <Header as='h4'>Attend</Header>                   
                                                    
                                                      {
                                                        obj.a_pricing.map((x,index1) => {
                                                          return(
                                                            <Fragment>
                                                            <Grid key={index1} container className="participation">
                                                            <Grid.Column width={11} textAlign='left'><span>{x.plan_type} {x.rate_type?(<span>({x.rate_type})</span>):null}</span> {(x.valid_till || x.attendee_type) &&<Popup trigger={<Icon name="info circle" color="blue" />} content={<span>{x.valid_till?(<span><b>Valid Until</b> : {x.valid_till}</span>):null} {x.valid_till && x.attendee_type && <br/>} {x.attendee_type?(<span><b>Sponsor Type</b>: {x.attendee_type}</span>):null}</span>} />}</Grid.Column>
                                                            <Grid.Column width={5} className='part-value' textAlign='right'>$ {nf.format(x.price)}</Grid.Column>
                                                          
                                                           </Grid><Divider/></Fragment>
                                                          )
                                                        })
                                                      }
                                                   </Fragment>     
                                                    
                                                   }*/}
                </Segment>
                </Grid.Column>
              )})}
</Grid>
            {/*  </Grid>*/}


 {this.state.events.length==0 && <p style={{fontSize: '1.2rem',fontWeight:700,textAlign:'initial',marginTop: '20px'}}>Return to <Link to="/search-events">Search Events</Link>, and click the star icon to begin saving a list of events of interest.</p>}
          </Container>}


{/**********Calendar**********/}
          {this.state.calendarVis && <Container fluid className='calendar-container'>
            <Segment className='calendar-segment'>
            
            <FullCalendar 
              defaultView="dayGridMonth" 
              header= {headers}
              plugins={[ dayGridPlugin ]} 
              events={this.state.cal_events}
              eventClick={(info) =>this.handleDateClick(info)}
              allDayDefault={true}
              defaultDate={this.state.events.length>0?new Date(this.state.events[0].start_date):new Date()}
              height='parent' />

               </Segment>

  
            

            <Modal open={this.state.downloadModal} size="small" closeIcon onClose={() =>this.handleclose()} className='EventModal'>
                 {this.state.calendarInfo.event && <Modal.Header>
                    {this.state.calendarInfo.event.title}
                  </Modal.Header>}
                  {this.state.calendarInfo.event && <Modal.Content>
                     <p> {this.state.calendarInfo.event.extendedProps.description}</p>
                    <Header as="h4">{this.state.calendarInfo.event.extendedProps.start_date} {this.state.calendarInfo.event.extendedProps.end_date?('- '+this.state.calendarInfo.event.extendedProps.end_date):null}</Header>
                    {/*<AddToCalendar as={Button} event={this.state.Myevent} displayItemIcons />*/}
                    <Dropdown button icon="calendar" onClick={this.downloadModeChange} className="add-to-calendar-button" text="Add to calendar ">
                                       
                                   </Dropdown>
                  </Modal.Content>}
            </Modal>

          </Container>}
      </Fragment>
        )
    }
}
export default withRouter(MyEventCalendar);
